// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$acordio-desplega-name: 'acordio-desplega';
$acordio-desplega-x: 130px;
$acordio-desplega-y: 117px;
$acordio-desplega-offset-x: -130px;
$acordio-desplega-offset-y: -117px;
$acordio-desplega-width: 26px;
$acordio-desplega-height: 26px;
$acordio-desplega-total-width: 277px;
$acordio-desplega-total-height: 245px;
$acordio-desplega-image: 'img/spritesheet.png?1720790066986';
$acordio-desplega: (130px, 117px, -130px, -117px, 26px, 26px, 277px, 245px, 'img/spritesheet.png?1720790066986', 'acordio-desplega', );
$acordio-plegar-name: 'acordio-plegar';
$acordio-plegar-x: 156px;
$acordio-plegar-y: 117px;
$acordio-plegar-offset-x: -156px;
$acordio-plegar-offset-y: -117px;
$acordio-plegar-width: 26px;
$acordio-plegar-height: 26px;
$acordio-plegar-total-width: 277px;
$acordio-plegar-total-height: 245px;
$acordio-plegar-image: 'img/spritesheet.png?1720790066986';
$acordio-plegar: (156px, 117px, -156px, -117px, 26px, 26px, 277px, 245px, 'img/spritesheet.png?1720790066986', 'acordio-plegar', );
$candau-bl-name: 'candau-bl';
$candau-bl-x: 260px;
$candau-bl-y: 112px;
$candau-bl-offset-x: -260px;
$candau-bl-offset-y: -112px;
$candau-bl-width: 11px;
$candau-bl-height: 13px;
$candau-bl-total-width: 277px;
$candau-bl-total-height: 245px;
$candau-bl-image: 'img/spritesheet.png?1720790066986';
$candau-bl: (260px, 112px, -260px, -112px, 11px, 13px, 277px, 245px, 'img/spritesheet.png?1720790066986', 'candau-bl', );
$candau-name: 'candau';
$candau-x: 260px;
$candau-y: 99px;
$candau-offset-x: -260px;
$candau-offset-y: -99px;
$candau-width: 11px;
$candau-height: 13px;
$candau-total-width: 277px;
$candau-total-height: 245px;
$candau-image: 'img/spritesheet.png?1720790066986';
$candau: (260px, 99px, -260px, -99px, 11px, 13px, 277px, 245px, 'img/spritesheet.png?1720790066986', 'candau', );
$cercar-menu-tablet-name: 'cercar-menu-tablet';
$cercar-menu-tablet-x: 231px;
$cercar-menu-tablet-y: 194px;
$cercar-menu-tablet-offset-x: -231px;
$cercar-menu-tablet-offset-y: -194px;
$cercar-menu-tablet-width: 22px;
$cercar-menu-tablet-height: 22px;
$cercar-menu-tablet-total-width: 277px;
$cercar-menu-tablet-total-height: 245px;
$cercar-menu-tablet-image: 'img/spritesheet.png?1720790066986';
$cercar-menu-tablet: (231px, 194px, -231px, -194px, 22px, 22px, 277px, 245px, 'img/spritesheet.png?1720790066986', 'cercar-menu-tablet', );
$cercar-menu-name: 'cercar-menu';
$cercar-menu-x: 209px;
$cercar-menu-y: 194px;
$cercar-menu-offset-x: -209px;
$cercar-menu-offset-y: -194px;
$cercar-menu-width: 22px;
$cercar-menu-height: 22px;
$cercar-menu-total-width: 277px;
$cercar-menu-total-height: 245px;
$cercar-menu-image: 'img/spritesheet.png?1720790066986';
$cercar-menu: (209px, 194px, -209px, -194px, 22px, 22px, 277px, 245px, 'img/spritesheet.png?1720790066986', 'cercar-menu', );
$destacat-puntets-name: 'destacat-puntets';
$destacat-puntets-x: 47px;
$destacat-puntets-y: 72px;
$destacat-puntets-offset-x: -47px;
$destacat-puntets-offset-y: -72px;
$destacat-puntets-width: 89px;
$destacat-puntets-height: 23px;
$destacat-puntets-total-width: 277px;
$destacat-puntets-total-height: 245px;
$destacat-puntets-image: 'img/spritesheet.png?1720790066986';
$destacat-puntets: (47px, 72px, -47px, -72px, 89px, 23px, 277px, 245px, 'img/spritesheet.png?1720790066986', 'destacat-puntets', );
$ico-acrobatpdf-name: 'ico-acrobatpdf';
$ico-acrobatpdf-x: 199px;
$ico-acrobatpdf-y: 52px;
$ico-acrobatpdf-offset-x: -199px;
$ico-acrobatpdf-offset-y: -52px;
$ico-acrobatpdf-width: 33px;
$ico-acrobatpdf-height: 42px;
$ico-acrobatpdf-total-width: 277px;
$ico-acrobatpdf-total-height: 245px;
$ico-acrobatpdf-image: 'img/spritesheet.png?1720790066986';
$ico-acrobatpdf: (199px, 52px, -199px, -52px, 33px, 42px, 277px, 245px, 'img/spritesheet.png?1720790066986', 'ico-acrobatpdf', );
$ico-compartir-correu-name: 'ico-compartir-correu';
$ico-compartir-correu-x: 123px;
$ico-compartir-correu-y: 95px;
$ico-compartir-correu-offset-x: -123px;
$ico-compartir-correu-offset-y: -95px;
$ico-compartir-correu-width: 25px;
$ico-compartir-correu-height: 16px;
$ico-compartir-correu-total-width: 277px;
$ico-compartir-correu-total-height: 245px;
$ico-compartir-correu-image: 'img/spritesheet.png?1720790066986';
$ico-compartir-correu: (123px, 95px, -123px, -95px, 25px, 16px, 277px, 245px, 'img/spritesheet.png?1720790066986', 'ico-compartir-correu', );
$ico-compartir-telegram-name: 'ico-compartir-telegram';
$ico-compartir-telegram-x: 101px;
$ico-compartir-telegram-y: 95px;
$ico-compartir-telegram-offset-x: -101px;
$ico-compartir-telegram-offset-y: -95px;
$ico-compartir-telegram-width: 22px;
$ico-compartir-telegram-height: 19px;
$ico-compartir-telegram-total-width: 277px;
$ico-compartir-telegram-total-height: 245px;
$ico-compartir-telegram-image: 'img/spritesheet.png?1720790066986';
$ico-compartir-telegram: (101px, 95px, -101px, -95px, 22px, 19px, 277px, 245px, 'img/spritesheet.png?1720790066986', 'ico-compartir-telegram', );
$ico-compartir-whatsapp-name: 'ico-compartir-whatsapp';
$ico-compartir-whatsapp-x: 235px;
$ico-compartir-whatsapp-y: 160px;
$ico-compartir-whatsapp-offset-x: -235px;
$ico-compartir-whatsapp-offset-y: -160px;
$ico-compartir-whatsapp-width: 25px;
$ico-compartir-whatsapp-height: 25px;
$ico-compartir-whatsapp-total-width: 277px;
$ico-compartir-whatsapp-total-height: 245px;
$ico-compartir-whatsapp-image: 'img/spritesheet.png?1720790066986';
$ico-compartir-whatsapp: (235px, 160px, -235px, -160px, 25px, 25px, 277px, 245px, 'img/spritesheet.png?1720790066986', 'ico-compartir-whatsapp', );
$ico-megamenu-dropdown-hover-name: 'ico-megamenu-dropdown-hover';
$ico-megamenu-dropdown-hover-x: 260px;
$ico-megamenu-dropdown-hover-y: 125px;
$ico-megamenu-dropdown-hover-offset-x: -260px;
$ico-megamenu-dropdown-hover-offset-y: -125px;
$ico-megamenu-dropdown-hover-width: 9px;
$ico-megamenu-dropdown-hover-height: 14px;
$ico-megamenu-dropdown-hover-total-width: 277px;
$ico-megamenu-dropdown-hover-total-height: 245px;
$ico-megamenu-dropdown-hover-image: 'img/spritesheet.png?1720790066986';
$ico-megamenu-dropdown-hover: (260px, 125px, -260px, -125px, 9px, 14px, 277px, 245px, 'img/spritesheet.png?1720790066986', 'ico-megamenu-dropdown-hover', );
$ico-megamenu-dropdown-name: 'ico-megamenu-dropdown';
$ico-megamenu-dropdown-x: 260px;
$ico-megamenu-dropdown-y: 139px;
$ico-megamenu-dropdown-offset-x: -260px;
$ico-megamenu-dropdown-offset-y: -139px;
$ico-megamenu-dropdown-width: 9px;
$ico-megamenu-dropdown-height: 14px;
$ico-megamenu-dropdown-total-width: 277px;
$ico-megamenu-dropdown-total-height: 245px;
$ico-megamenu-dropdown-image: 'img/spritesheet.png?1720790066986';
$ico-megamenu-dropdown: (260px, 139px, -260px, -139px, 9px, 14px, 277px, 245px, 'img/spritesheet.png?1720790066986', 'ico-megamenu-dropdown', );
$ico-megarmenu-tancar-name: 'ico-megarmenu-tancar';
$ico-megarmenu-tancar-x: 98px;
$ico-megarmenu-tancar-y: 194px;
$ico-megarmenu-tancar-offset-x: -98px;
$ico-megarmenu-tancar-offset-y: -194px;
$ico-megarmenu-tancar-width: 23px;
$ico-megarmenu-tancar-height: 23px;
$ico-megarmenu-tancar-total-width: 277px;
$ico-megarmenu-tancar-total-height: 245px;
$ico-megarmenu-tancar-image: 'img/spritesheet.png?1720790066986';
$ico-megarmenu-tancar: (98px, 194px, -98px, -194px, 23px, 23px, 277px, 245px, 'img/spritesheet.png?1720790066986', 'ico-megarmenu-tancar', );
$ico-entrevistes-name: 'ico_entrevistes';
$ico-entrevistes-x: 134px;
$ico-entrevistes-y: 219px;
$ico-entrevistes-offset-x: -134px;
$ico-entrevistes-offset-y: -219px;
$ico-entrevistes-width: 22px;
$ico-entrevistes-height: 15px;
$ico-entrevistes-total-width: 277px;
$ico-entrevistes-total-height: 245px;
$ico-entrevistes-image: 'img/spritesheet.png?1720790066986';
$ico-entrevistes: (134px, 219px, -134px, -219px, 22px, 15px, 277px, 245px, 'img/spritesheet.png?1720790066986', 'ico_entrevistes', );
$ico-opinions-name: 'ico_opinions';
$ico-opinions-x: 194px;
$ico-opinions-y: 219px;
$ico-opinions-offset-x: -194px;
$ico-opinions-offset-y: -219px;
$ico-opinions-width: 18px;
$ico-opinions-height: 14px;
$ico-opinions-total-width: 277px;
$ico-opinions-total-height: 245px;
$ico-opinions-image: 'img/spritesheet.png?1720790066986';
$ico-opinions: (194px, 219px, -194px, -219px, 18px, 14px, 277px, 245px, 'img/spritesheet.png?1720790066986', 'ico_opinions', );
$icon-alerta-name: 'icon-alerta';
$icon-alerta-x: 0px;
$icon-alerta-y: 72px;
$icon-alerta-offset-x: 0px;
$icon-alerta-offset-y: -72px;
$icon-alerta-width: 47px;
$icon-alerta-height: 45px;
$icon-alerta-total-width: 277px;
$icon-alerta-total-height: 245px;
$icon-alerta-image: 'img/spritesheet.png?1720790066986';
$icon-alerta: (0px, 72px, 0px, -72px, 47px, 45px, 277px, 245px, 'img/spritesheet.png?1720790066986', 'icon-alerta', );
$icon-desplega-menu-sup-name: 'icon-desplega-menu-sup';
$icon-desplega-menu-sup-x: 260px;
$icon-desplega-menu-sup-y: 175px;
$icon-desplega-menu-sup-offset-x: -260px;
$icon-desplega-menu-sup-offset-y: -175px;
$icon-desplega-menu-sup-width: 13px;
$icon-desplega-menu-sup-height: 8px;
$icon-desplega-menu-sup-total-width: 277px;
$icon-desplega-menu-sup-total-height: 245px;
$icon-desplega-menu-sup-image: 'img/spritesheet.png?1720790066986';
$icon-desplega-menu-sup: (260px, 175px, -260px, -175px, 13px, 8px, 277px, 245px, 'img/spritesheet.png?1720790066986', 'icon-desplega-menu-sup', );
$icon-download-name: 'icon-download';
$icon-download-x: 155px;
$icon-download-y: 96px;
$icon-download-offset-x: -155px;
$icon-download-offset-y: -96px;
$icon-download-width: 30px;
$icon-download-height: 20px;
$icon-download-total-width: 277px;
$icon-download-total-height: 245px;
$icon-download-image: 'img/spritesheet.png?1720790066986';
$icon-download: (155px, 96px, -155px, -96px, 30px, 20px, 277px, 245px, 'img/spritesheet.png?1720790066986', 'icon-download', );
$icon-enllacos-pdf-name: 'icon-enllacos-pdf';
$icon-enllacos-pdf-x: 121px;
$icon-enllacos-pdf-y: 194px;
$icon-enllacos-pdf-offset-x: -121px;
$icon-enllacos-pdf-offset-y: -194px;
$icon-enllacos-pdf-width: 22px;
$icon-enllacos-pdf-height: 23px;
$icon-enllacos-pdf-total-width: 277px;
$icon-enllacos-pdf-total-height: 245px;
$icon-enllacos-pdf-image: 'img/spritesheet.png?1720790066986';
$icon-enllacos-pdf: (121px, 194px, -121px, -194px, 22px, 23px, 277px, 245px, 'img/spritesheet.png?1720790066986', 'icon-enllacos-pdf', );
$icon-enllacos-name: 'icon-enllacos';
$icon-enllacos-x: 260px;
$icon-enllacos-y: 35px;
$icon-enllacos-offset-x: -260px;
$icon-enllacos-offset-y: -35px;
$icon-enllacos-width: 11px;
$icon-enllacos-height: 18px;
$icon-enllacos-total-width: 277px;
$icon-enllacos-total-height: 245px;
$icon-enllacos-image: 'img/spritesheet.png?1720790066986';
$icon-enllacos: (260px, 35px, -260px, -35px, 11px, 18px, 277px, 245px, 'img/spritesheet.png?1720790066986', 'icon-enllacos', );
$icon-fitxa-adressa-name: 'icon-fitxa-adressa';
$icon-fitxa-adressa-x: 113px;
$icon-fitxa-adressa-y: 219px;
$icon-fitxa-adressa-offset-x: -113px;
$icon-fitxa-adressa-offset-y: -219px;
$icon-fitxa-adressa-width: 21px;
$icon-fitxa-adressa-height: 17px;
$icon-fitxa-adressa-total-width: 277px;
$icon-fitxa-adressa-total-height: 245px;
$icon-fitxa-adressa-image: 'img/spritesheet.png?1720790066986';
$icon-fitxa-adressa: (113px, 219px, -113px, -219px, 21px, 17px, 277px, 245px, 'img/spritesheet.png?1720790066986', 'icon-fitxa-adressa', );
$icon-fitxa-fax-name: 'icon-fitxa-fax';
$icon-fitxa-fax-x: 93px;
$icon-fitxa-fax-y: 219px;
$icon-fitxa-fax-offset-x: -93px;
$icon-fitxa-fax-offset-y: -219px;
$icon-fitxa-fax-width: 20px;
$icon-fitxa-fax-height: 20px;
$icon-fitxa-fax-total-width: 277px;
$icon-fitxa-fax-total-height: 245px;
$icon-fitxa-fax-image: 'img/spritesheet.png?1720790066986';
$icon-fitxa-fax: (93px, 219px, -93px, -219px, 20px, 20px, 277px, 245px, 'img/spritesheet.png?1720790066986', 'icon-fitxa-fax', );
$icon-fitxa-mail-name: 'icon-fitxa-mail';
$icon-fitxa-mail-x: 156px;
$icon-fitxa-mail-y: 219px;
$icon-fitxa-mail-offset-x: -156px;
$icon-fitxa-mail-offset-y: -219px;
$icon-fitxa-mail-width: 19px;
$icon-fitxa-mail-height: 14px;
$icon-fitxa-mail-total-width: 277px;
$icon-fitxa-mail-total-height: 245px;
$icon-fitxa-mail-image: 'img/spritesheet.png?1720790066986';
$icon-fitxa-mail: (156px, 219px, -156px, -219px, 19px, 14px, 277px, 245px, 'img/spritesheet.png?1720790066986', 'icon-fitxa-mail', );
$icon-fitxa-telefon-name: 'icon-fitxa-telefon';
$icon-fitxa-telefon-x: 260px;
$icon-fitxa-telefon-y: 16px;
$icon-fitxa-telefon-offset-x: -260px;
$icon-fitxa-telefon-offset-y: -16px;
$icon-fitxa-telefon-width: 13px;
$icon-fitxa-telefon-height: 19px;
$icon-fitxa-telefon-total-width: 277px;
$icon-fitxa-telefon-total-height: 245px;
$icon-fitxa-telefon-image: 'img/spritesheet.png?1720790066986';
$icon-fitxa-telefon: (260px, 16px, -260px, -16px, 13px, 19px, 277px, 245px, 'img/spritesheet.png?1720790066986', 'icon-fitxa-telefon', );
$icon-fitxa-usuari-name: 'icon-fitxa-usuari';
$icon-fitxa-usuari-x: 182px;
$icon-fitxa-usuari-y: 117px;
$icon-fitxa-usuari-offset-x: -182px;
$icon-fitxa-usuari-offset-y: -117px;
$icon-fitxa-usuari-width: 16px;
$icon-fitxa-usuari-height: 18px;
$icon-fitxa-usuari-total-width: 277px;
$icon-fitxa-usuari-total-height: 245px;
$icon-fitxa-usuari-image: 'img/spritesheet.png?1720790066986';
$icon-fitxa-usuari: (182px, 117px, -182px, -117px, 16px, 18px, 277px, 245px, 'img/spritesheet.png?1720790066986', 'icon-fitxa-usuari', );
$icon-fitxa-video-name: 'icon-fitxa-video';
$icon-fitxa-video-x: 260px;
$icon-fitxa-video-y: 0px;
$icon-fitxa-video-offset-x: -260px;
$icon-fitxa-video-offset-y: 0px;
$icon-fitxa-video-width: 17px;
$icon-fitxa-video-height: 16px;
$icon-fitxa-video-total-width: 277px;
$icon-fitxa-video-total-height: 245px;
$icon-fitxa-video-image: 'img/spritesheet.png?1720790066986';
$icon-fitxa-video: (260px, 0px, -260px, 0px, 17px, 16px, 277px, 245px, 'img/spritesheet.png?1720790066986', 'icon-fitxa-video', );
$icon-link-name: 'icon-link';
$icon-link-x: 165px;
$icon-link-y: 194px;
$icon-link-offset-x: -165px;
$icon-link-offset-y: -194px;
$icon-link-width: 22px;
$icon-link-height: 23px;
$icon-link-total-width: 277px;
$icon-link-total-height: 245px;
$icon-link-image: 'img/spritesheet.png?1720790066986';
$icon-link: (165px, 194px, -165px, -194px, 22px, 23px, 277px, 245px, 'img/spritesheet.png?1720790066986', 'icon-link', );
$icon-menu-small-name: 'icon-menu-small';
$icon-menu-small-x: 0px;
$icon-menu-small-y: 154px;
$icon-menu-small-offset-x: 0px;
$icon-menu-small-offset-y: -154px;
$icon-menu-small-width: 40px;
$icon-menu-small-height: 40px;
$icon-menu-small-total-width: 277px;
$icon-menu-small-total-height: 245px;
$icon-menu-small-image: 'img/spritesheet.png?1720790066986';
$icon-menu-small: (0px, 154px, 0px, -154px, 40px, 40px, 277px, 245px, 'img/spritesheet.png?1720790066986', 'icon-menu-small', );
$icon-mes-name: 'icon-mes';
$icon-mes-x: 187px;
$icon-mes-y: 194px;
$icon-mes-offset-x: -187px;
$icon-mes-offset-y: -194px;
$icon-mes-width: 22px;
$icon-mes-height: 22px;
$icon-mes-total-width: 277px;
$icon-mes-total-height: 245px;
$icon-mes-image: 'img/spritesheet.png?1720790066986';
$icon-mes: (187px, 194px, -187px, -194px, 22px, 22px, 277px, 245px, 'img/spritesheet.png?1720790066986', 'icon-mes', );
$icon-professorat-name: 'icon-professorat';
$icon-professorat-x: 0px;
$icon-professorat-y: 117px;
$icon-professorat-offset-x: 0px;
$icon-professorat-offset-y: -117px;
$icon-professorat-width: 52px;
$icon-professorat-height: 37px;
$icon-professorat-total-width: 277px;
$icon-professorat-total-height: 245px;
$icon-professorat-image: 'img/spritesheet.png?1720790066986';
$icon-professorat: (0px, 117px, 0px, -117px, 52px, 37px, 277px, 245px, 'img/spritesheet.png?1720790066986', 'icon-professorat', );
$icon-programa-pdf-name: 'icon-programa-pdf';
$icon-programa-pdf-x: 155px;
$icon-programa-pdf-y: 0px;
$icon-programa-pdf-offset-x: -155px;
$icon-programa-pdf-offset-y: 0px;
$icon-programa-pdf-width: 44px;
$icon-programa-pdf-height: 44px;
$icon-programa-pdf-total-width: 277px;
$icon-programa-pdf-total-height: 245px;
$icon-programa-pdf-image: 'img/spritesheet.png?1720790066986';
$icon-programa-pdf: (155px, 0px, -155px, 0px, 44px, 44px, 277px, 245px, 'img/spritesheet.png?1720790066986', 'icon-programa-pdf', );
$icon-qualitat-name: 'icon-qualitat';
$icon-qualitat-x: 102px;
$icon-qualitat-y: 0px;
$icon-qualitat-offset-x: -102px;
$icon-qualitat-offset-y: 0px;
$icon-qualitat-width: 53px;
$icon-qualitat-height: 47px;
$icon-qualitat-total-width: 277px;
$icon-qualitat-total-height: 245px;
$icon-qualitat-image: 'img/spritesheet.png?1720790066986';
$icon-qualitat: (102px, 0px, -102px, 0px, 53px, 47px, 277px, 245px, 'img/spritesheet.png?1720790066986', 'icon-qualitat', );
$icona-filtre-cercar-name: 'icona-filtre-cercar';
$icona-filtre-cercar-x: 143px;
$icona-filtre-cercar-y: 194px;
$icona-filtre-cercar-offset-x: -143px;
$icona-filtre-cercar-offset-y: -194px;
$icona-filtre-cercar-width: 22px;
$icona-filtre-cercar-height: 23px;
$icona-filtre-cercar-total-width: 277px;
$icona-filtre-cercar-total-height: 245px;
$icona-filtre-cercar-image: 'img/spritesheet.png?1720790066986';
$icona-filtre-cercar: (143px, 194px, -143px, -194px, 22px, 23px, 277px, 245px, 'img/spritesheet.png?1720790066986', 'icona-filtre-cercar', );
$kland-llistat-nivell-1-name: 'kland-llistat-nivell-1';
$kland-llistat-nivell-1-x: 260px;
$kland-llistat-nivell-1-y: 164px;
$kland-llistat-nivell-1-offset-x: -260px;
$kland-llistat-nivell-1-offset-y: -164px;
$kland-llistat-nivell-1-width: 11px;
$kland-llistat-nivell-1-height: 11px;
$kland-llistat-nivell-1-total-width: 277px;
$kland-llistat-nivell-1-total-height: 245px;
$kland-llistat-nivell-1-image: 'img/spritesheet.png?1720790066986';
$kland-llistat-nivell-1: (260px, 164px, -260px, -164px, 11px, 11px, 277px, 245px, 'img/spritesheet.png?1720790066986', 'kland-llistat-nivell-1', );
$kland-llistat-nivell-2-name: 'kland-llistat-nivell-2';
$kland-llistat-nivell-2-x: 260px;
$kland-llistat-nivell-2-y: 153px;
$kland-llistat-nivell-2-offset-x: -260px;
$kland-llistat-nivell-2-offset-y: -153px;
$kland-llistat-nivell-2-width: 11px;
$kland-llistat-nivell-2-height: 11px;
$kland-llistat-nivell-2-total-width: 277px;
$kland-llistat-nivell-2-total-height: 245px;
$kland-llistat-nivell-2-image: 'img/spritesheet.png?1720790066986';
$kland-llistat-nivell-2: (260px, 153px, -260px, -153px, 11px, 11px, 277px, 245px, 'img/spritesheet.png?1720790066986', 'kland-llistat-nivell-2', );
$kland-llistat-nivell-3-name: 'kland-llistat-nivell-3';
$kland-llistat-nivell-3-x: 271px;
$kland-llistat-nivell-3-y: 35px;
$kland-llistat-nivell-3-offset-x: -271px;
$kland-llistat-nivell-3-offset-y: -35px;
$kland-llistat-nivell-3-width: 5px;
$kland-llistat-nivell-3-height: 5px;
$kland-llistat-nivell-3-total-width: 277px;
$kland-llistat-nivell-3-total-height: 245px;
$kland-llistat-nivell-3-image: 'img/spritesheet.png?1720790066986';
$kland-llistat-nivell-3: (271px, 35px, -271px, -35px, 5px, 5px, 277px, 245px, 'img/spritesheet.png?1720790066986', 'kland-llistat-nivell-3', );
$kland-peu-name: 'kland-peu';
$kland-peu-x: 136px;
$kland-peu-y: 72px;
$kland-peu-offset-x: -136px;
$kland-peu-offset-y: -72px;
$kland-peu-width: 18px;
$kland-peu-height: 18px;
$kland-peu-total-width: 277px;
$kland-peu-total-height: 245px;
$kland-peu-image: 'img/spritesheet.png?1720790066986';
$kland-peu: (136px, 72px, -136px, -72px, 18px, 18px, 277px, 245px, 'img/spritesheet.png?1720790066986', 'kland-peu', );
$logo-linkedin-name: 'logo-linkedin';
$logo-linkedin-x: 72px;
$logo-linkedin-y: 154px;
$logo-linkedin-offset-x: -72px;
$logo-linkedin-offset-y: -154px;
$logo-linkedin-width: 32px;
$logo-linkedin-height: 32px;
$logo-linkedin-total-width: 277px;
$logo-linkedin-total-height: 245px;
$logo-linkedin-image: 'img/spritesheet.png?1720790066986';
$logo-linkedin: (72px, 154px, -72px, -154px, 32px, 32px, 277px, 245px, 'img/spritesheet.png?1720790066986', 'logo-linkedin', );
$logo-peu-int-facebook-name: 'logo-peu-int-facebook';
$logo-peu-int-facebook-x: 193px;
$logo-peu-int-facebook-y: 154px;
$logo-peu-int-facebook-offset-x: -193px;
$logo-peu-int-facebook-offset-y: -154px;
$logo-peu-int-facebook-width: 26px;
$logo-peu-int-facebook-height: 25px;
$logo-peu-int-facebook-total-width: 277px;
$logo-peu-int-facebook-total-height: 245px;
$logo-peu-int-facebook-image: 'img/spritesheet.png?1720790066986';
$logo-peu-int-facebook: (193px, 154px, -193px, -154px, 26px, 25px, 277px, 245px, 'img/spritesheet.png?1720790066986', 'logo-peu-int-facebook', );
$logo-peu-int-instagram-name: 'logo-peu-int-instagram';
$logo-peu-int-instagram-x: 199px;
$logo-peu-int-instagram-y: 129px;
$logo-peu-int-instagram-offset-x: -199px;
$logo-peu-int-instagram-offset-y: -129px;
$logo-peu-int-instagram-width: 26px;
$logo-peu-int-instagram-height: 25px;
$logo-peu-int-instagram-total-width: 277px;
$logo-peu-int-instagram-total-height: 245px;
$logo-peu-int-instagram-image: 'img/spritesheet.png?1720790066986';
$logo-peu-int-instagram: (199px, 129px, -199px, -129px, 26px, 25px, 277px, 245px, 'img/spritesheet.png?1720790066986', 'logo-peu-int-instagram', );
$logo-peu-int-linkedin-name: 'logo-peu-int-linkedin';
$logo-peu-int-linkedin-x: 0px;
$logo-peu-int-linkedin-y: 219px;
$logo-peu-int-linkedin-offset-x: 0px;
$logo-peu-int-linkedin-offset-y: -219px;
$logo-peu-int-linkedin-width: 24px;
$logo-peu-int-linkedin-height: 26px;
$logo-peu-int-linkedin-total-width: 277px;
$logo-peu-int-linkedin-total-height: 245px;
$logo-peu-int-linkedin-image: 'img/spritesheet.png?1720790066986';
$logo-peu-int-linkedin: (0px, 219px, 0px, -219px, 24px, 26px, 277px, 245px, 'img/spritesheet.png?1720790066986', 'logo-peu-int-linkedin', );
$logo-peu-int-twitter-name: 'logo-peu-int-twitter';
$logo-peu-int-twitter-x: 25px;
$logo-peu-int-twitter-y: 194px;
$logo-peu-int-twitter-offset-x: -25px;
$logo-peu-int-twitter-offset-y: -194px;
$logo-peu-int-twitter-width: 27px;
$logo-peu-int-twitter-height: 23px;
$logo-peu-int-twitter-total-width: 277px;
$logo-peu-int-twitter-total-height: 245px;
$logo-peu-int-twitter-image: 'img/spritesheet.png?1720790066986';
$logo-peu-int-twitter: (25px, 194px, -25px, -194px, 27px, 23px, 277px, 245px, 'img/spritesheet.png?1720790066986', 'logo-peu-int-twitter', );
$logo-peu-int-xarxes-name: 'logo-peu-int-xarxes';
$logo-peu-int-xarxes-x: 72px;
$logo-peu-int-xarxes-y: 219px;
$logo-peu-int-xarxes-offset-x: -72px;
$logo-peu-int-xarxes-offset-y: -219px;
$logo-peu-int-xarxes-width: 21px;
$logo-peu-int-xarxes-height: 22px;
$logo-peu-int-xarxes-total-width: 277px;
$logo-peu-int-xarxes-total-height: 245px;
$logo-peu-int-xarxes-image: 'img/spritesheet.png?1720790066986';
$logo-peu-int-xarxes: (72px, 219px, -72px, -219px, 21px, 22px, 277px, 245px, 'img/spritesheet.png?1720790066986', 'logo-peu-int-xarxes', );
$logo-peu-int-youtube-name: 'logo-peu-int-youtube';
$logo-peu-int-youtube-x: 74px;
$logo-peu-int-youtube-y: 95px;
$logo-peu-int-youtube-offset-x: -74px;
$logo-peu-int-youtube-offset-y: -95px;
$logo-peu-int-youtube-width: 27px;
$logo-peu-int-youtube-height: 19px;
$logo-peu-int-youtube-total-width: 277px;
$logo-peu-int-youtube-total-height: 245px;
$logo-peu-int-youtube-image: 'img/spritesheet.png?1720790066986';
$logo-peu-int-youtube: (74px, 95px, -74px, -95px, 27px, 19px, 277px, 245px, 'img/spritesheet.png?1720790066986', 'logo-peu-int-youtube', );
$logo-twitter-name: 'logo-twitter';
$logo-twitter-x: 40px;
$logo-twitter-y: 154px;
$logo-twitter-offset-x: -40px;
$logo-twitter-offset-y: -154px;
$logo-twitter-width: 32px;
$logo-twitter-height: 32px;
$logo-twitter-total-width: 277px;
$logo-twitter-total-height: 245px;
$logo-twitter-image: 'img/spritesheet.png?1720790066986';
$logo-twitter: (40px, 154px, -40px, -154px, 32px, 32px, 277px, 245px, 'img/spritesheet.png?1720790066986', 'logo-twitter', );
$megamenu-tancar-name: 'megamenu-tancar';
$megamenu-tancar-x: 75px;
$megamenu-tancar-y: 194px;
$megamenu-tancar-offset-x: -75px;
$megamenu-tancar-offset-y: -194px;
$megamenu-tancar-width: 23px;
$megamenu-tancar-height: 23px;
$megamenu-tancar-total-width: 277px;
$megamenu-tancar-total-height: 245px;
$megamenu-tancar-image: 'img/spritesheet.png?1720790066986';
$megamenu-tancar: (75px, 194px, -75px, -194px, 23px, 23px, 277px, 245px, 'img/spritesheet.png?1720790066986', 'megamenu-tancar', );
$menu-mobile-b-nivell1-name: 'menu-mobile-b-nivell1';
$menu-mobile-b-nivell1-x: 175px;
$menu-mobile-b-nivell1-y: 219px;
$menu-mobile-b-nivell1-offset-x: -175px;
$menu-mobile-b-nivell1-offset-y: -219px;
$menu-mobile-b-nivell1-width: 19px;
$menu-mobile-b-nivell1-height: 14px;
$menu-mobile-b-nivell1-total-width: 277px;
$menu-mobile-b-nivell1-total-height: 245px;
$menu-mobile-b-nivell1-image: 'img/spritesheet.png?1720790066986';
$menu-mobile-b-nivell1: (175px, 219px, -175px, -219px, 19px, 14px, 277px, 245px, 'img/spritesheet.png?1720790066986', 'menu-mobile-b-nivell1', );
$menu-mobile-b-nivell2-name: 'menu-mobile-b-nivell2';
$menu-mobile-b-nivell2-x: 212px;
$menu-mobile-b-nivell2-y: 219px;
$menu-mobile-b-nivell2-offset-x: -212px;
$menu-mobile-b-nivell2-offset-y: -219px;
$menu-mobile-b-nivell2-width: 18px;
$menu-mobile-b-nivell2-height: 10px;
$menu-mobile-b-nivell2-total-width: 277px;
$menu-mobile-b-nivell2-total-height: 245px;
$menu-mobile-b-nivell2-image: 'img/spritesheet.png?1720790066986';
$menu-mobile-b-nivell2: (212px, 219px, -212px, -219px, 18px, 10px, 277px, 245px, 'img/spritesheet.png?1720790066986', 'menu-mobile-b-nivell2', );
$menu-mobile-b-nivell3-name: 'menu-mobile-b-nivell3';
$menu-mobile-b-nivell3-x: 260px;
$menu-mobile-b-nivell3-y: 81px;
$menu-mobile-b-nivell3-offset-x: -260px;
$menu-mobile-b-nivell3-offset-y: -81px;
$menu-mobile-b-nivell3-width: 10px;
$menu-mobile-b-nivell3-height: 18px;
$menu-mobile-b-nivell3-total-width: 277px;
$menu-mobile-b-nivell3-total-height: 245px;
$menu-mobile-b-nivell3-image: 'img/spritesheet.png?1720790066986';
$menu-mobile-b-nivell3: (260px, 81px, -260px, -81px, 10px, 18px, 277px, 245px, 'img/spritesheet.png?1720790066986', 'menu-mobile-b-nivell3', );
$share-facebook-name: 'share-facebook';
$share-facebook-x: 0px;
$share-facebook-y: 194px;
$share-facebook-offset-x: 0px;
$share-facebook-offset-y: -194px;
$share-facebook-width: 25px;
$share-facebook-height: 25px;
$share-facebook-total-width: 277px;
$share-facebook-total-height: 245px;
$share-facebook-image: 'img/spritesheet.png?1720790066986';
$share-facebook: (0px, 194px, 0px, -194px, 25px, 25px, 277px, 245px, 'img/spritesheet.png?1720790066986', 'share-facebook', );
$share-instagram-name: 'share-instagram';
$share-instagram-x: 48px;
$share-instagram-y: 219px;
$share-instagram-offset-x: -48px;
$share-instagram-offset-y: -219px;
$share-instagram-width: 24px;
$share-instagram-height: 24px;
$share-instagram-total-width: 277px;
$share-instagram-total-height: 245px;
$share-instagram-image: 'img/spritesheet.png?1720790066986';
$share-instagram: (48px, 219px, -48px, -219px, 24px, 24px, 277px, 245px, 'img/spritesheet.png?1720790066986', 'share-instagram', );
$share-linkedin-name: 'share-linkedin';
$share-linkedin-x: 24px;
$share-linkedin-y: 219px;
$share-linkedin-offset-x: -24px;
$share-linkedin-offset-y: -219px;
$share-linkedin-width: 24px;
$share-linkedin-height: 25px;
$share-linkedin-total-width: 277px;
$share-linkedin-total-height: 245px;
$share-linkedin-image: 'img/spritesheet.png?1720790066986';
$share-linkedin: (24px, 219px, -24px, -219px, 24px, 25px, 277px, 245px, 'img/spritesheet.png?1720790066986', 'share-linkedin', );
$share-twitter-name: 'share-twitter';
$share-twitter-x: 47px;
$share-twitter-y: 95px;
$share-twitter-offset-x: -47px;
$share-twitter-offset-y: -95px;
$share-twitter-width: 27px;
$share-twitter-height: 22px;
$share-twitter-total-width: 277px;
$share-twitter-total-height: 245px;
$share-twitter-image: 'img/spritesheet.png?1720790066986';
$share-twitter: (47px, 95px, -47px, -95px, 27px, 22px, 277px, 245px, 'img/spritesheet.png?1720790066986', 'share-twitter', );
$slide-endavant-320-name: 'slide-endavant-320';
$slide-endavant-320-x: 235px;
$slide-endavant-320-y: 124px;
$slide-endavant-320-offset-x: -235px;
$slide-endavant-320-offset-y: -124px;
$slide-endavant-320-width: 25px;
$slide-endavant-320-height: 36px;
$slide-endavant-320-total-width: 277px;
$slide-endavant-320-total-height: 245px;
$slide-endavant-320-image: 'img/spritesheet.png?1720790066986';
$slide-endavant-320: (235px, 124px, -235px, -124px, 25px, 36px, 277px, 245px, 'img/spritesheet.png?1720790066986', 'slide-endavant-320', );
$slide-endavant-768-name: 'slide-endavant-768';
$slide-endavant-768-x: 199px;
$slide-endavant-768-y: 0px;
$slide-endavant-768-offset-x: -199px;
$slide-endavant-768-offset-y: 0px;
$slide-endavant-768-width: 36px;
$slide-endavant-768-height: 52px;
$slide-endavant-768-total-width: 277px;
$slide-endavant-768-total-height: 245px;
$slide-endavant-768-image: 'img/spritesheet.png?1720790066986';
$slide-endavant-768: (199px, 0px, -199px, 0px, 36px, 52px, 277px, 245px, 'img/spritesheet.png?1720790066986', 'slide-endavant-768', );
$slide-endavant-name: 'slide-endavant';
$slide-endavant-x: 0px;
$slide-endavant-y: 0px;
$slide-endavant-offset-x: 0px;
$slide-endavant-offset-y: 0px;
$slide-endavant-width: 51px;
$slide-endavant-height: 72px;
$slide-endavant-total-width: 277px;
$slide-endavant-total-height: 245px;
$slide-endavant-image: 'img/spritesheet.png?1720790066986';
$slide-endavant: (0px, 0px, 0px, 0px, 51px, 72px, 277px, 245px, 'img/spritesheet.png?1720790066986', 'slide-endavant', );
$slide-enrere-320-name: 'slide-enrere-320';
$slide-enrere-320-x: 235px;
$slide-enrere-320-y: 88px;
$slide-enrere-320-offset-x: -235px;
$slide-enrere-320-offset-y: -88px;
$slide-enrere-320-width: 25px;
$slide-enrere-320-height: 36px;
$slide-enrere-320-total-width: 277px;
$slide-enrere-320-total-height: 245px;
$slide-enrere-320-image: 'img/spritesheet.png?1720790066986';
$slide-enrere-320: (235px, 88px, -235px, -88px, 25px, 36px, 277px, 245px, 'img/spritesheet.png?1720790066986', 'slide-enrere-320', );
$slide-enrere-768-name: 'slide-enrere-768';
$slide-enrere-768-x: 155px;
$slide-enrere-768-y: 44px;
$slide-enrere-768-offset-x: -155px;
$slide-enrere-768-offset-y: -44px;
$slide-enrere-768-width: 36px;
$slide-enrere-768-height: 52px;
$slide-enrere-768-total-width: 277px;
$slide-enrere-768-total-height: 245px;
$slide-enrere-768-image: 'img/spritesheet.png?1720790066986';
$slide-enrere-768: (155px, 44px, -155px, -44px, 36px, 52px, 277px, 245px, 'img/spritesheet.png?1720790066986', 'slide-enrere-768', );
$slide-enrere-name: 'slide-enrere';
$slide-enrere-x: 51px;
$slide-enrere-y: 0px;
$slide-enrere-offset-x: -51px;
$slide-enrere-offset-y: 0px;
$slide-enrere-width: 51px;
$slide-enrere-height: 72px;
$slide-enrere-total-width: 277px;
$slide-enrere-total-height: 245px;
$slide-enrere-image: 'img/spritesheet.png?1720790066986';
$slide-enrere: (51px, 0px, -51px, 0px, 51px, 72px, 277px, 245px, 'img/spritesheet.png?1720790066986', 'slide-enrere', );
$slide-noticia-endavant-name: 'slide-noticia-endavant';
$slide-noticia-endavant-x: 235px;
$slide-noticia-endavant-y: 0px;
$slide-noticia-endavant-offset-x: -235px;
$slide-noticia-endavant-offset-y: 0px;
$slide-noticia-endavant-width: 25px;
$slide-noticia-endavant-height: 44px;
$slide-noticia-endavant-total-width: 277px;
$slide-noticia-endavant-total-height: 245px;
$slide-noticia-endavant-image: 'img/spritesheet.png?1720790066986';
$slide-noticia-endavant: (235px, 0px, -235px, 0px, 25px, 44px, 277px, 245px, 'img/spritesheet.png?1720790066986', 'slide-noticia-endavant', );
$slide-noticia-enrera-name: 'slide-noticia-enrera';
$slide-noticia-enrera-x: 235px;
$slide-noticia-enrera-y: 44px;
$slide-noticia-enrera-offset-x: -235px;
$slide-noticia-enrera-offset-y: -44px;
$slide-noticia-enrera-width: 25px;
$slide-noticia-enrera-height: 44px;
$slide-noticia-enrera-total-width: 277px;
$slide-noticia-enrera-total-height: 245px;
$slide-noticia-enrera-image: 'img/spritesheet.png?1720790066986';
$slide-noticia-enrera: (235px, 44px, -235px, -44px, 25px, 44px, 277px, 245px, 'img/spritesheet.png?1720790066986', 'slide-noticia-enrera', );
$tancar-alerta-name: 'tancar-alerta';
$tancar-alerta-x: 52px;
$tancar-alerta-y: 194px;
$tancar-alerta-offset-x: -52px;
$tancar-alerta-offset-y: -194px;
$tancar-alerta-width: 23px;
$tancar-alerta-height: 23px;
$tancar-alerta-total-width: 277px;
$tancar-alerta-total-height: 245px;
$tancar-alerta-image: 'img/spritesheet.png?1720790066986';
$tancar-alerta: (52px, 194px, -52px, -194px, 23px, 23px, 277px, 245px, 'img/spritesheet.png?1720790066986', 'tancar-alerta', );
$top-name: 'top';
$top-x: 102px;
$top-y: 47px;
$top-offset-x: -102px;
$top-offset-y: -47px;
$top-width: 43px;
$top-height: 24px;
$top-total-width: 277px;
$top-total-height: 245px;
$top-image: 'img/spritesheet.png?1720790066986';
$top: (102px, 47px, -102px, -47px, 43px, 24px, 277px, 245px, 'img/spritesheet.png?1720790066986', 'top', );
$vincle-extern-bl-name: 'vincle-extern-bl';
$vincle-extern-bl-x: 260px;
$vincle-extern-bl-y: 67px;
$vincle-extern-bl-offset-x: -260px;
$vincle-extern-bl-offset-y: -67px;
$vincle-extern-bl-width: 13px;
$vincle-extern-bl-height: 14px;
$vincle-extern-bl-total-width: 277px;
$vincle-extern-bl-total-height: 245px;
$vincle-extern-bl-image: 'img/spritesheet.png?1720790066986';
$vincle-extern-bl: (260px, 67px, -260px, -67px, 13px, 14px, 277px, 245px, 'img/spritesheet.png?1720790066986', 'vincle-extern-bl', );
$vincle-extern-name: 'vincle-extern';
$vincle-extern-x: 260px;
$vincle-extern-y: 53px;
$vincle-extern-offset-x: -260px;
$vincle-extern-offset-y: -53px;
$vincle-extern-width: 13px;
$vincle-extern-height: 14px;
$vincle-extern-total-width: 277px;
$vincle-extern-total-height: 245px;
$vincle-extern-image: 'img/spritesheet.png?1720790066986';
$vincle-extern: (260px, 53px, -260px, -53px, 13px, 14px, 277px, 245px, 'img/spritesheet.png?1720790066986', 'vincle-extern', );
$xarxes-portada-facebook-name: 'xarxes-portada-facebook';
$xarxes-portada-facebook-x: 135px;
$xarxes-portada-facebook-y: 154px;
$xarxes-portada-facebook-offset-x: -135px;
$xarxes-portada-facebook-offset-y: -154px;
$xarxes-portada-facebook-width: 29px;
$xarxes-portada-facebook-height: 30px;
$xarxes-portada-facebook-total-width: 277px;
$xarxes-portada-facebook-total-height: 245px;
$xarxes-portada-facebook-image: 'img/spritesheet.png?1720790066986';
$xarxes-portada-facebook: (135px, 154px, -135px, -154px, 29px, 30px, 277px, 245px, 'img/spritesheet.png?1720790066986', 'xarxes-portada-facebook', );
$xarxes-portada-instagram-name: 'xarxes-portada-instagram';
$xarxes-portada-instagram-x: 199px;
$xarxes-portada-instagram-y: 94px;
$xarxes-portada-instagram-offset-x: -199px;
$xarxes-portada-instagram-offset-y: -94px;
$xarxes-portada-instagram-width: 33px;
$xarxes-portada-instagram-height: 35px;
$xarxes-portada-instagram-total-width: 277px;
$xarxes-portada-instagram-total-height: 245px;
$xarxes-portada-instagram-image: 'img/spritesheet.png?1720790066986';
$xarxes-portada-instagram: (199px, 94px, -199px, -94px, 33px, 35px, 277px, 245px, 'img/spritesheet.png?1720790066986', 'xarxes-portada-instagram', );
$xarxes-portada-linkedin-name: 'xarxes-portada-linkedin';
$xarxes-portada-linkedin-x: 164px;
$xarxes-portada-linkedin-y: 154px;
$xarxes-portada-linkedin-offset-x: -164px;
$xarxes-portada-linkedin-offset-y: -154px;
$xarxes-portada-linkedin-width: 29px;
$xarxes-portada-linkedin-height: 30px;
$xarxes-portada-linkedin-total-width: 277px;
$xarxes-portada-linkedin-total-height: 245px;
$xarxes-portada-linkedin-image: 'img/spritesheet.png?1720790066986';
$xarxes-portada-linkedin: (164px, 154px, -164px, -154px, 29px, 30px, 277px, 245px, 'img/spritesheet.png?1720790066986', 'xarxes-portada-linkedin', );
$xarxes-portada-mes-name: 'xarxes-portada-mes';
$xarxes-portada-mes-x: 104px;
$xarxes-portada-mes-y: 154px;
$xarxes-portada-mes-offset-x: -104px;
$xarxes-portada-mes-offset-y: -154px;
$xarxes-portada-mes-width: 31px;
$xarxes-portada-mes-height: 30px;
$xarxes-portada-mes-total-width: 277px;
$xarxes-portada-mes-total-height: 245px;
$xarxes-portada-mes-image: 'img/spritesheet.png?1720790066986';
$xarxes-portada-mes: (104px, 154px, -104px, -154px, 31px, 30px, 277px, 245px, 'img/spritesheet.png?1720790066986', 'xarxes-portada-mes', );
$xarxes-portada-twitter-name: 'xarxes-portada-twitter';
$xarxes-portada-twitter-x: 52px;
$xarxes-portada-twitter-y: 117px;
$xarxes-portada-twitter-offset-x: -52px;
$xarxes-portada-twitter-offset-y: -117px;
$xarxes-portada-twitter-width: 39px;
$xarxes-portada-twitter-height: 33px;
$xarxes-portada-twitter-total-width: 277px;
$xarxes-portada-twitter-total-height: 245px;
$xarxes-portada-twitter-image: 'img/spritesheet.png?1720790066986';
$xarxes-portada-twitter: (52px, 117px, -52px, -117px, 39px, 33px, 277px, 245px, 'img/spritesheet.png?1720790066986', 'xarxes-portada-twitter', );
$xarxes-portada-youtube-name: 'xarxes-portada-youtube';
$xarxes-portada-youtube-x: 91px;
$xarxes-portada-youtube-y: 117px;
$xarxes-portada-youtube-offset-x: -91px;
$xarxes-portada-youtube-offset-y: -117px;
$xarxes-portada-youtube-width: 39px;
$xarxes-portada-youtube-height: 28px;
$xarxes-portada-youtube-total-width: 277px;
$xarxes-portada-youtube-total-height: 245px;
$xarxes-portada-youtube-image: 'img/spritesheet.png?1720790066986';
$xarxes-portada-youtube: (91px, 117px, -91px, -117px, 39px, 28px, 277px, 245px, 'img/spritesheet.png?1720790066986', 'xarxes-portada-youtube', );
$acordio-desplega-2x-name: 'acordio-desplega@2x';
$acordio-desplega-2x-x: 260px;
$acordio-desplega-2x-y: 234px;
$acordio-desplega-2x-offset-x: -260px;
$acordio-desplega-2x-offset-y: -234px;
$acordio-desplega-2x-width: 52px;
$acordio-desplega-2x-height: 52px;
$acordio-desplega-2x-total-width: 554px;
$acordio-desplega-2x-total-height: 490px;
$acordio-desplega-2x-image: 'img/spritesheet@2x.png?1720790066986';
$acordio-desplega-2x: (260px, 234px, -260px, -234px, 52px, 52px, 554px, 490px, 'img/spritesheet@2x.png?1720790066986', 'acordio-desplega@2x', );
$acordio-plegar-2x-name: 'acordio-plegar@2x';
$acordio-plegar-2x-x: 312px;
$acordio-plegar-2x-y: 234px;
$acordio-plegar-2x-offset-x: -312px;
$acordio-plegar-2x-offset-y: -234px;
$acordio-plegar-2x-width: 52px;
$acordio-plegar-2x-height: 52px;
$acordio-plegar-2x-total-width: 554px;
$acordio-plegar-2x-total-height: 490px;
$acordio-plegar-2x-image: 'img/spritesheet@2x.png?1720790066986';
$acordio-plegar-2x: (312px, 234px, -312px, -234px, 52px, 52px, 554px, 490px, 'img/spritesheet@2x.png?1720790066986', 'acordio-plegar@2x', );
$candau-bl-2x-name: 'candau-bl@2x';
$candau-bl-2x-x: 520px;
$candau-bl-2x-y: 224px;
$candau-bl-2x-offset-x: -520px;
$candau-bl-2x-offset-y: -224px;
$candau-bl-2x-width: 22px;
$candau-bl-2x-height: 26px;
$candau-bl-2x-total-width: 554px;
$candau-bl-2x-total-height: 490px;
$candau-bl-2x-image: 'img/spritesheet@2x.png?1720790066986';
$candau-bl-2x: (520px, 224px, -520px, -224px, 22px, 26px, 554px, 490px, 'img/spritesheet@2x.png?1720790066986', 'candau-bl@2x', );
$candau-2x-name: 'candau@2x';
$candau-2x-x: 520px;
$candau-2x-y: 198px;
$candau-2x-offset-x: -520px;
$candau-2x-offset-y: -198px;
$candau-2x-width: 22px;
$candau-2x-height: 26px;
$candau-2x-total-width: 554px;
$candau-2x-total-height: 490px;
$candau-2x-image: 'img/spritesheet@2x.png?1720790066986';
$candau-2x: (520px, 198px, -520px, -198px, 22px, 26px, 554px, 490px, 'img/spritesheet@2x.png?1720790066986', 'candau@2x', );
$cercar-menu-tablet-2x-name: 'cercar-menu-tablet@2x';
$cercar-menu-tablet-2x-x: 462px;
$cercar-menu-tablet-2x-y: 388px;
$cercar-menu-tablet-2x-offset-x: -462px;
$cercar-menu-tablet-2x-offset-y: -388px;
$cercar-menu-tablet-2x-width: 44px;
$cercar-menu-tablet-2x-height: 44px;
$cercar-menu-tablet-2x-total-width: 554px;
$cercar-menu-tablet-2x-total-height: 490px;
$cercar-menu-tablet-2x-image: 'img/spritesheet@2x.png?1720790066986';
$cercar-menu-tablet-2x: (462px, 388px, -462px, -388px, 44px, 44px, 554px, 490px, 'img/spritesheet@2x.png?1720790066986', 'cercar-menu-tablet@2x', );
$cercar-menu-2x-name: 'cercar-menu@2x';
$cercar-menu-2x-x: 418px;
$cercar-menu-2x-y: 388px;
$cercar-menu-2x-offset-x: -418px;
$cercar-menu-2x-offset-y: -388px;
$cercar-menu-2x-width: 44px;
$cercar-menu-2x-height: 44px;
$cercar-menu-2x-total-width: 554px;
$cercar-menu-2x-total-height: 490px;
$cercar-menu-2x-image: 'img/spritesheet@2x.png?1720790066986';
$cercar-menu-2x: (418px, 388px, -418px, -388px, 44px, 44px, 554px, 490px, 'img/spritesheet@2x.png?1720790066986', 'cercar-menu@2x', );
$destacat-puntets-2x-name: 'destacat-puntets@2x';
$destacat-puntets-2x-x: 94px;
$destacat-puntets-2x-y: 144px;
$destacat-puntets-2x-offset-x: -94px;
$destacat-puntets-2x-offset-y: -144px;
$destacat-puntets-2x-width: 178px;
$destacat-puntets-2x-height: 46px;
$destacat-puntets-2x-total-width: 554px;
$destacat-puntets-2x-total-height: 490px;
$destacat-puntets-2x-image: 'img/spritesheet@2x.png?1720790066986';
$destacat-puntets-2x: (94px, 144px, -94px, -144px, 178px, 46px, 554px, 490px, 'img/spritesheet@2x.png?1720790066986', 'destacat-puntets@2x', );
$ico-acrobatpdf-2x-name: 'ico-acrobatpdf@2x';
$ico-acrobatpdf-2x-x: 398px;
$ico-acrobatpdf-2x-y: 104px;
$ico-acrobatpdf-2x-offset-x: -398px;
$ico-acrobatpdf-2x-offset-y: -104px;
$ico-acrobatpdf-2x-width: 66px;
$ico-acrobatpdf-2x-height: 84px;
$ico-acrobatpdf-2x-total-width: 554px;
$ico-acrobatpdf-2x-total-height: 490px;
$ico-acrobatpdf-2x-image: 'img/spritesheet@2x.png?1720790066986';
$ico-acrobatpdf-2x: (398px, 104px, -398px, -104px, 66px, 84px, 554px, 490px, 'img/spritesheet@2x.png?1720790066986', 'ico-acrobatpdf@2x', );
$ico-compartir-correu-2x-name: 'ico-compartir-correu@2x';
$ico-compartir-correu-2x-x: 246px;
$ico-compartir-correu-2x-y: 190px;
$ico-compartir-correu-2x-offset-x: -246px;
$ico-compartir-correu-2x-offset-y: -190px;
$ico-compartir-correu-2x-width: 50px;
$ico-compartir-correu-2x-height: 32px;
$ico-compartir-correu-2x-total-width: 554px;
$ico-compartir-correu-2x-total-height: 490px;
$ico-compartir-correu-2x-image: 'img/spritesheet@2x.png?1720790066986';
$ico-compartir-correu-2x: (246px, 190px, -246px, -190px, 50px, 32px, 554px, 490px, 'img/spritesheet@2x.png?1720790066986', 'ico-compartir-correu@2x', );
$ico-compartir-telegram-2x-name: 'ico-compartir-telegram@2x';
$ico-compartir-telegram-2x-x: 202px;
$ico-compartir-telegram-2x-y: 190px;
$ico-compartir-telegram-2x-offset-x: -202px;
$ico-compartir-telegram-2x-offset-y: -190px;
$ico-compartir-telegram-2x-width: 44px;
$ico-compartir-telegram-2x-height: 38px;
$ico-compartir-telegram-2x-total-width: 554px;
$ico-compartir-telegram-2x-total-height: 490px;
$ico-compartir-telegram-2x-image: 'img/spritesheet@2x.png?1720790066986';
$ico-compartir-telegram-2x: (202px, 190px, -202px, -190px, 44px, 38px, 554px, 490px, 'img/spritesheet@2x.png?1720790066986', 'ico-compartir-telegram@2x', );
$ico-compartir-whatsapp-2x-name: 'ico-compartir-whatsapp@2x';
$ico-compartir-whatsapp-2x-x: 470px;
$ico-compartir-whatsapp-2x-y: 320px;
$ico-compartir-whatsapp-2x-offset-x: -470px;
$ico-compartir-whatsapp-2x-offset-y: -320px;
$ico-compartir-whatsapp-2x-width: 50px;
$ico-compartir-whatsapp-2x-height: 50px;
$ico-compartir-whatsapp-2x-total-width: 554px;
$ico-compartir-whatsapp-2x-total-height: 490px;
$ico-compartir-whatsapp-2x-image: 'img/spritesheet@2x.png?1720790066986';
$ico-compartir-whatsapp-2x: (470px, 320px, -470px, -320px, 50px, 50px, 554px, 490px, 'img/spritesheet@2x.png?1720790066986', 'ico-compartir-whatsapp@2x', );
$ico-megamenu-dropdown-hover-2x-name: 'ico-megamenu-dropdown-hover@2x';
$ico-megamenu-dropdown-hover-2x-x: 520px;
$ico-megamenu-dropdown-hover-2x-y: 250px;
$ico-megamenu-dropdown-hover-2x-offset-x: -520px;
$ico-megamenu-dropdown-hover-2x-offset-y: -250px;
$ico-megamenu-dropdown-hover-2x-width: 18px;
$ico-megamenu-dropdown-hover-2x-height: 28px;
$ico-megamenu-dropdown-hover-2x-total-width: 554px;
$ico-megamenu-dropdown-hover-2x-total-height: 490px;
$ico-megamenu-dropdown-hover-2x-image: 'img/spritesheet@2x.png?1720790066986';
$ico-megamenu-dropdown-hover-2x: (520px, 250px, -520px, -250px, 18px, 28px, 554px, 490px, 'img/spritesheet@2x.png?1720790066986', 'ico-megamenu-dropdown-hover@2x', );
$ico-megamenu-dropdown-2x-name: 'ico-megamenu-dropdown@2x';
$ico-megamenu-dropdown-2x-x: 520px;
$ico-megamenu-dropdown-2x-y: 278px;
$ico-megamenu-dropdown-2x-offset-x: -520px;
$ico-megamenu-dropdown-2x-offset-y: -278px;
$ico-megamenu-dropdown-2x-width: 18px;
$ico-megamenu-dropdown-2x-height: 28px;
$ico-megamenu-dropdown-2x-total-width: 554px;
$ico-megamenu-dropdown-2x-total-height: 490px;
$ico-megamenu-dropdown-2x-image: 'img/spritesheet@2x.png?1720790066986';
$ico-megamenu-dropdown-2x: (520px, 278px, -520px, -278px, 18px, 28px, 554px, 490px, 'img/spritesheet@2x.png?1720790066986', 'ico-megamenu-dropdown@2x', );
$ico-megarmenu-tancar-2x-name: 'ico-megarmenu-tancar@2x';
$ico-megarmenu-tancar-2x-x: 196px;
$ico-megarmenu-tancar-2x-y: 388px;
$ico-megarmenu-tancar-2x-offset-x: -196px;
$ico-megarmenu-tancar-2x-offset-y: -388px;
$ico-megarmenu-tancar-2x-width: 46px;
$ico-megarmenu-tancar-2x-height: 46px;
$ico-megarmenu-tancar-2x-total-width: 554px;
$ico-megarmenu-tancar-2x-total-height: 490px;
$ico-megarmenu-tancar-2x-image: 'img/spritesheet@2x.png?1720790066986';
$ico-megarmenu-tancar-2x: (196px, 388px, -196px, -388px, 46px, 46px, 554px, 490px, 'img/spritesheet@2x.png?1720790066986', 'ico-megarmenu-tancar@2x', );
$ico-entrevistes-2x-name: 'ico_entrevistes@2x';
$ico-entrevistes-2x-x: 268px;
$ico-entrevistes-2x-y: 438px;
$ico-entrevistes-2x-offset-x: -268px;
$ico-entrevistes-2x-offset-y: -438px;
$ico-entrevistes-2x-width: 44px;
$ico-entrevistes-2x-height: 30px;
$ico-entrevistes-2x-total-width: 554px;
$ico-entrevistes-2x-total-height: 490px;
$ico-entrevistes-2x-image: 'img/spritesheet@2x.png?1720790066986';
$ico-entrevistes-2x: (268px, 438px, -268px, -438px, 44px, 30px, 554px, 490px, 'img/spritesheet@2x.png?1720790066986', 'ico_entrevistes@2x', );
$ico-opinions-2x-name: 'ico_opinions@2x';
$ico-opinions-2x-x: 388px;
$ico-opinions-2x-y: 438px;
$ico-opinions-2x-offset-x: -388px;
$ico-opinions-2x-offset-y: -438px;
$ico-opinions-2x-width: 36px;
$ico-opinions-2x-height: 28px;
$ico-opinions-2x-total-width: 554px;
$ico-opinions-2x-total-height: 490px;
$ico-opinions-2x-image: 'img/spritesheet@2x.png?1720790066986';
$ico-opinions-2x: (388px, 438px, -388px, -438px, 36px, 28px, 554px, 490px, 'img/spritesheet@2x.png?1720790066986', 'ico_opinions@2x', );
$icon-alerta-2x-name: 'icon-alerta@2x';
$icon-alerta-2x-x: 0px;
$icon-alerta-2x-y: 144px;
$icon-alerta-2x-offset-x: 0px;
$icon-alerta-2x-offset-y: -144px;
$icon-alerta-2x-width: 94px;
$icon-alerta-2x-height: 90px;
$icon-alerta-2x-total-width: 554px;
$icon-alerta-2x-total-height: 490px;
$icon-alerta-2x-image: 'img/spritesheet@2x.png?1720790066986';
$icon-alerta-2x: (0px, 144px, 0px, -144px, 94px, 90px, 554px, 490px, 'img/spritesheet@2x.png?1720790066986', 'icon-alerta@2x', );
$icon-desplega-menu-sup-2x-name: 'icon-desplega-menu-sup@2x';
$icon-desplega-menu-sup-2x-x: 520px;
$icon-desplega-menu-sup-2x-y: 350px;
$icon-desplega-menu-sup-2x-offset-x: -520px;
$icon-desplega-menu-sup-2x-offset-y: -350px;
$icon-desplega-menu-sup-2x-width: 26px;
$icon-desplega-menu-sup-2x-height: 16px;
$icon-desplega-menu-sup-2x-total-width: 554px;
$icon-desplega-menu-sup-2x-total-height: 490px;
$icon-desplega-menu-sup-2x-image: 'img/spritesheet@2x.png?1720790066986';
$icon-desplega-menu-sup-2x: (520px, 350px, -520px, -350px, 26px, 16px, 554px, 490px, 'img/spritesheet@2x.png?1720790066986', 'icon-desplega-menu-sup@2x', );
$icon-download-2x-name: 'icon-download@2x';
$icon-download-2x-x: 310px;
$icon-download-2x-y: 192px;
$icon-download-2x-offset-x: -310px;
$icon-download-2x-offset-y: -192px;
$icon-download-2x-width: 60px;
$icon-download-2x-height: 40px;
$icon-download-2x-total-width: 554px;
$icon-download-2x-total-height: 490px;
$icon-download-2x-image: 'img/spritesheet@2x.png?1720790066986';
$icon-download-2x: (310px, 192px, -310px, -192px, 60px, 40px, 554px, 490px, 'img/spritesheet@2x.png?1720790066986', 'icon-download@2x', );
$icon-enllacos-pdf-2x-name: 'icon-enllacos-pdf@2x';
$icon-enllacos-pdf-2x-x: 242px;
$icon-enllacos-pdf-2x-y: 388px;
$icon-enllacos-pdf-2x-offset-x: -242px;
$icon-enllacos-pdf-2x-offset-y: -388px;
$icon-enllacos-pdf-2x-width: 44px;
$icon-enllacos-pdf-2x-height: 46px;
$icon-enllacos-pdf-2x-total-width: 554px;
$icon-enllacos-pdf-2x-total-height: 490px;
$icon-enllacos-pdf-2x-image: 'img/spritesheet@2x.png?1720790066986';
$icon-enllacos-pdf-2x: (242px, 388px, -242px, -388px, 44px, 46px, 554px, 490px, 'img/spritesheet@2x.png?1720790066986', 'icon-enllacos-pdf@2x', );
$icon-enllacos-2x-name: 'icon-enllacos@2x';
$icon-enllacos-2x-x: 520px;
$icon-enllacos-2x-y: 70px;
$icon-enllacos-2x-offset-x: -520px;
$icon-enllacos-2x-offset-y: -70px;
$icon-enllacos-2x-width: 22px;
$icon-enllacos-2x-height: 36px;
$icon-enllacos-2x-total-width: 554px;
$icon-enllacos-2x-total-height: 490px;
$icon-enllacos-2x-image: 'img/spritesheet@2x.png?1720790066986';
$icon-enllacos-2x: (520px, 70px, -520px, -70px, 22px, 36px, 554px, 490px, 'img/spritesheet@2x.png?1720790066986', 'icon-enllacos@2x', );
$icon-fitxa-adressa-2x-name: 'icon-fitxa-adressa@2x';
$icon-fitxa-adressa-2x-x: 226px;
$icon-fitxa-adressa-2x-y: 438px;
$icon-fitxa-adressa-2x-offset-x: -226px;
$icon-fitxa-adressa-2x-offset-y: -438px;
$icon-fitxa-adressa-2x-width: 42px;
$icon-fitxa-adressa-2x-height: 34px;
$icon-fitxa-adressa-2x-total-width: 554px;
$icon-fitxa-adressa-2x-total-height: 490px;
$icon-fitxa-adressa-2x-image: 'img/spritesheet@2x.png?1720790066986';
$icon-fitxa-adressa-2x: (226px, 438px, -226px, -438px, 42px, 34px, 554px, 490px, 'img/spritesheet@2x.png?1720790066986', 'icon-fitxa-adressa@2x', );
$icon-fitxa-fax-2x-name: 'icon-fitxa-fax@2x';
$icon-fitxa-fax-2x-x: 186px;
$icon-fitxa-fax-2x-y: 438px;
$icon-fitxa-fax-2x-offset-x: -186px;
$icon-fitxa-fax-2x-offset-y: -438px;
$icon-fitxa-fax-2x-width: 40px;
$icon-fitxa-fax-2x-height: 40px;
$icon-fitxa-fax-2x-total-width: 554px;
$icon-fitxa-fax-2x-total-height: 490px;
$icon-fitxa-fax-2x-image: 'img/spritesheet@2x.png?1720790066986';
$icon-fitxa-fax-2x: (186px, 438px, -186px, -438px, 40px, 40px, 554px, 490px, 'img/spritesheet@2x.png?1720790066986', 'icon-fitxa-fax@2x', );
$icon-fitxa-mail-2x-name: 'icon-fitxa-mail@2x';
$icon-fitxa-mail-2x-x: 312px;
$icon-fitxa-mail-2x-y: 438px;
$icon-fitxa-mail-2x-offset-x: -312px;
$icon-fitxa-mail-2x-offset-y: -438px;
$icon-fitxa-mail-2x-width: 38px;
$icon-fitxa-mail-2x-height: 28px;
$icon-fitxa-mail-2x-total-width: 554px;
$icon-fitxa-mail-2x-total-height: 490px;
$icon-fitxa-mail-2x-image: 'img/spritesheet@2x.png?1720790066986';
$icon-fitxa-mail-2x: (312px, 438px, -312px, -438px, 38px, 28px, 554px, 490px, 'img/spritesheet@2x.png?1720790066986', 'icon-fitxa-mail@2x', );
$icon-fitxa-telefon-2x-name: 'icon-fitxa-telefon@2x';
$icon-fitxa-telefon-2x-x: 520px;
$icon-fitxa-telefon-2x-y: 32px;
$icon-fitxa-telefon-2x-offset-x: -520px;
$icon-fitxa-telefon-2x-offset-y: -32px;
$icon-fitxa-telefon-2x-width: 26px;
$icon-fitxa-telefon-2x-height: 38px;
$icon-fitxa-telefon-2x-total-width: 554px;
$icon-fitxa-telefon-2x-total-height: 490px;
$icon-fitxa-telefon-2x-image: 'img/spritesheet@2x.png?1720790066986';
$icon-fitxa-telefon-2x: (520px, 32px, -520px, -32px, 26px, 38px, 554px, 490px, 'img/spritesheet@2x.png?1720790066986', 'icon-fitxa-telefon@2x', );
$icon-fitxa-usuari-2x-name: 'icon-fitxa-usuari@2x';
$icon-fitxa-usuari-2x-x: 364px;
$icon-fitxa-usuari-2x-y: 234px;
$icon-fitxa-usuari-2x-offset-x: -364px;
$icon-fitxa-usuari-2x-offset-y: -234px;
$icon-fitxa-usuari-2x-width: 32px;
$icon-fitxa-usuari-2x-height: 36px;
$icon-fitxa-usuari-2x-total-width: 554px;
$icon-fitxa-usuari-2x-total-height: 490px;
$icon-fitxa-usuari-2x-image: 'img/spritesheet@2x.png?1720790066986';
$icon-fitxa-usuari-2x: (364px, 234px, -364px, -234px, 32px, 36px, 554px, 490px, 'img/spritesheet@2x.png?1720790066986', 'icon-fitxa-usuari@2x', );
$icon-fitxa-video-2x-name: 'icon-fitxa-video@2x';
$icon-fitxa-video-2x-x: 520px;
$icon-fitxa-video-2x-y: 0px;
$icon-fitxa-video-2x-offset-x: -520px;
$icon-fitxa-video-2x-offset-y: 0px;
$icon-fitxa-video-2x-width: 34px;
$icon-fitxa-video-2x-height: 32px;
$icon-fitxa-video-2x-total-width: 554px;
$icon-fitxa-video-2x-total-height: 490px;
$icon-fitxa-video-2x-image: 'img/spritesheet@2x.png?1720790066986';
$icon-fitxa-video-2x: (520px, 0px, -520px, 0px, 34px, 32px, 554px, 490px, 'img/spritesheet@2x.png?1720790066986', 'icon-fitxa-video@2x', );
$icon-link-2x-name: 'icon-link@2x';
$icon-link-2x-x: 330px;
$icon-link-2x-y: 388px;
$icon-link-2x-offset-x: -330px;
$icon-link-2x-offset-y: -388px;
$icon-link-2x-width: 44px;
$icon-link-2x-height: 46px;
$icon-link-2x-total-width: 554px;
$icon-link-2x-total-height: 490px;
$icon-link-2x-image: 'img/spritesheet@2x.png?1720790066986';
$icon-link-2x: (330px, 388px, -330px, -388px, 44px, 46px, 554px, 490px, 'img/spritesheet@2x.png?1720790066986', 'icon-link@2x', );
$icon-menu-small-2x-name: 'icon-menu-small@2x';
$icon-menu-small-2x-x: 0px;
$icon-menu-small-2x-y: 308px;
$icon-menu-small-2x-offset-x: 0px;
$icon-menu-small-2x-offset-y: -308px;
$icon-menu-small-2x-width: 80px;
$icon-menu-small-2x-height: 80px;
$icon-menu-small-2x-total-width: 554px;
$icon-menu-small-2x-total-height: 490px;
$icon-menu-small-2x-image: 'img/spritesheet@2x.png?1720790066986';
$icon-menu-small-2x: (0px, 308px, 0px, -308px, 80px, 80px, 554px, 490px, 'img/spritesheet@2x.png?1720790066986', 'icon-menu-small@2x', );
$icon-mes-2x-name: 'icon-mes@2x';
$icon-mes-2x-x: 374px;
$icon-mes-2x-y: 388px;
$icon-mes-2x-offset-x: -374px;
$icon-mes-2x-offset-y: -388px;
$icon-mes-2x-width: 44px;
$icon-mes-2x-height: 44px;
$icon-mes-2x-total-width: 554px;
$icon-mes-2x-total-height: 490px;
$icon-mes-2x-image: 'img/spritesheet@2x.png?1720790066986';
$icon-mes-2x: (374px, 388px, -374px, -388px, 44px, 44px, 554px, 490px, 'img/spritesheet@2x.png?1720790066986', 'icon-mes@2x', );
$icon-professorat-2x-name: 'icon-professorat@2x';
$icon-professorat-2x-x: 0px;
$icon-professorat-2x-y: 234px;
$icon-professorat-2x-offset-x: 0px;
$icon-professorat-2x-offset-y: -234px;
$icon-professorat-2x-width: 104px;
$icon-professorat-2x-height: 74px;
$icon-professorat-2x-total-width: 554px;
$icon-professorat-2x-total-height: 490px;
$icon-professorat-2x-image: 'img/spritesheet@2x.png?1720790066986';
$icon-professorat-2x: (0px, 234px, 0px, -234px, 104px, 74px, 554px, 490px, 'img/spritesheet@2x.png?1720790066986', 'icon-professorat@2x', );
$icon-programa-pdf-2x-name: 'icon-programa-pdf@2x';
$icon-programa-pdf-2x-x: 310px;
$icon-programa-pdf-2x-y: 0px;
$icon-programa-pdf-2x-offset-x: -310px;
$icon-programa-pdf-2x-offset-y: 0px;
$icon-programa-pdf-2x-width: 88px;
$icon-programa-pdf-2x-height: 88px;
$icon-programa-pdf-2x-total-width: 554px;
$icon-programa-pdf-2x-total-height: 490px;
$icon-programa-pdf-2x-image: 'img/spritesheet@2x.png?1720790066986';
$icon-programa-pdf-2x: (310px, 0px, -310px, 0px, 88px, 88px, 554px, 490px, 'img/spritesheet@2x.png?1720790066986', 'icon-programa-pdf@2x', );
$icon-qualitat-2x-name: 'icon-qualitat@2x';
$icon-qualitat-2x-x: 204px;
$icon-qualitat-2x-y: 0px;
$icon-qualitat-2x-offset-x: -204px;
$icon-qualitat-2x-offset-y: 0px;
$icon-qualitat-2x-width: 106px;
$icon-qualitat-2x-height: 94px;
$icon-qualitat-2x-total-width: 554px;
$icon-qualitat-2x-total-height: 490px;
$icon-qualitat-2x-image: 'img/spritesheet@2x.png?1720790066986';
$icon-qualitat-2x: (204px, 0px, -204px, 0px, 106px, 94px, 554px, 490px, 'img/spritesheet@2x.png?1720790066986', 'icon-qualitat@2x', );
$icona-filtre-cercar-2x-name: 'icona-filtre-cercar@2x';
$icona-filtre-cercar-2x-x: 286px;
$icona-filtre-cercar-2x-y: 388px;
$icona-filtre-cercar-2x-offset-x: -286px;
$icona-filtre-cercar-2x-offset-y: -388px;
$icona-filtre-cercar-2x-width: 44px;
$icona-filtre-cercar-2x-height: 46px;
$icona-filtre-cercar-2x-total-width: 554px;
$icona-filtre-cercar-2x-total-height: 490px;
$icona-filtre-cercar-2x-image: 'img/spritesheet@2x.png?1720790066986';
$icona-filtre-cercar-2x: (286px, 388px, -286px, -388px, 44px, 46px, 554px, 490px, 'img/spritesheet@2x.png?1720790066986', 'icona-filtre-cercar@2x', );
$kland-llistat-nivell-1-2x-name: 'kland-llistat-nivell-1@2x';
$kland-llistat-nivell-1-2x-x: 520px;
$kland-llistat-nivell-1-2x-y: 328px;
$kland-llistat-nivell-1-2x-offset-x: -520px;
$kland-llistat-nivell-1-2x-offset-y: -328px;
$kland-llistat-nivell-1-2x-width: 22px;
$kland-llistat-nivell-1-2x-height: 22px;
$kland-llistat-nivell-1-2x-total-width: 554px;
$kland-llistat-nivell-1-2x-total-height: 490px;
$kland-llistat-nivell-1-2x-image: 'img/spritesheet@2x.png?1720790066986';
$kland-llistat-nivell-1-2x: (520px, 328px, -520px, -328px, 22px, 22px, 554px, 490px, 'img/spritesheet@2x.png?1720790066986', 'kland-llistat-nivell-1@2x', );
$kland-llistat-nivell-2-2x-name: 'kland-llistat-nivell-2@2x';
$kland-llistat-nivell-2-2x-x: 520px;
$kland-llistat-nivell-2-2x-y: 306px;
$kland-llistat-nivell-2-2x-offset-x: -520px;
$kland-llistat-nivell-2-2x-offset-y: -306px;
$kland-llistat-nivell-2-2x-width: 22px;
$kland-llistat-nivell-2-2x-height: 22px;
$kland-llistat-nivell-2-2x-total-width: 554px;
$kland-llistat-nivell-2-2x-total-height: 490px;
$kland-llistat-nivell-2-2x-image: 'img/spritesheet@2x.png?1720790066986';
$kland-llistat-nivell-2-2x: (520px, 306px, -520px, -306px, 22px, 22px, 554px, 490px, 'img/spritesheet@2x.png?1720790066986', 'kland-llistat-nivell-2@2x', );
$kland-llistat-nivell-3-2x-name: 'kland-llistat-nivell-3@2x';
$kland-llistat-nivell-3-2x-x: 542px;
$kland-llistat-nivell-3-2x-y: 70px;
$kland-llistat-nivell-3-2x-offset-x: -542px;
$kland-llistat-nivell-3-2x-offset-y: -70px;
$kland-llistat-nivell-3-2x-width: 10px;
$kland-llistat-nivell-3-2x-height: 10px;
$kland-llistat-nivell-3-2x-total-width: 554px;
$kland-llistat-nivell-3-2x-total-height: 490px;
$kland-llistat-nivell-3-2x-image: 'img/spritesheet@2x.png?1720790066986';
$kland-llistat-nivell-3-2x: (542px, 70px, -542px, -70px, 10px, 10px, 554px, 490px, 'img/spritesheet@2x.png?1720790066986', 'kland-llistat-nivell-3@2x', );
$kland-peu-2x-name: 'kland-peu@2x';
$kland-peu-2x-x: 272px;
$kland-peu-2x-y: 144px;
$kland-peu-2x-offset-x: -272px;
$kland-peu-2x-offset-y: -144px;
$kland-peu-2x-width: 36px;
$kland-peu-2x-height: 36px;
$kland-peu-2x-total-width: 554px;
$kland-peu-2x-total-height: 490px;
$kland-peu-2x-image: 'img/spritesheet@2x.png?1720790066986';
$kland-peu-2x: (272px, 144px, -272px, -144px, 36px, 36px, 554px, 490px, 'img/spritesheet@2x.png?1720790066986', 'kland-peu@2x', );
$logo-linkedin-2x-name: 'logo-linkedin@2x';
$logo-linkedin-2x-x: 144px;
$logo-linkedin-2x-y: 308px;
$logo-linkedin-2x-offset-x: -144px;
$logo-linkedin-2x-offset-y: -308px;
$logo-linkedin-2x-width: 64px;
$logo-linkedin-2x-height: 64px;
$logo-linkedin-2x-total-width: 554px;
$logo-linkedin-2x-total-height: 490px;
$logo-linkedin-2x-image: 'img/spritesheet@2x.png?1720790066986';
$logo-linkedin-2x: (144px, 308px, -144px, -308px, 64px, 64px, 554px, 490px, 'img/spritesheet@2x.png?1720790066986', 'logo-linkedin@2x', );
$logo-peu-int-facebook-2x-name: 'logo-peu-int-facebook@2x';
$logo-peu-int-facebook-2x-x: 386px;
$logo-peu-int-facebook-2x-y: 308px;
$logo-peu-int-facebook-2x-offset-x: -386px;
$logo-peu-int-facebook-2x-offset-y: -308px;
$logo-peu-int-facebook-2x-width: 52px;
$logo-peu-int-facebook-2x-height: 50px;
$logo-peu-int-facebook-2x-total-width: 554px;
$logo-peu-int-facebook-2x-total-height: 490px;
$logo-peu-int-facebook-2x-image: 'img/spritesheet@2x.png?1720790066986';
$logo-peu-int-facebook-2x: (386px, 308px, -386px, -308px, 52px, 50px, 554px, 490px, 'img/spritesheet@2x.png?1720790066986', 'logo-peu-int-facebook@2x', );
$logo-peu-int-instagram-2x-name: 'logo-peu-int-instagram@2x';
$logo-peu-int-instagram-2x-x: 398px;
$logo-peu-int-instagram-2x-y: 258px;
$logo-peu-int-instagram-2x-offset-x: -398px;
$logo-peu-int-instagram-2x-offset-y: -258px;
$logo-peu-int-instagram-2x-width: 52px;
$logo-peu-int-instagram-2x-height: 50px;
$logo-peu-int-instagram-2x-total-width: 554px;
$logo-peu-int-instagram-2x-total-height: 490px;
$logo-peu-int-instagram-2x-image: 'img/spritesheet@2x.png?1720790066986';
$logo-peu-int-instagram-2x: (398px, 258px, -398px, -258px, 52px, 50px, 554px, 490px, 'img/spritesheet@2x.png?1720790066986', 'logo-peu-int-instagram@2x', );
$logo-peu-int-linkedin-2x-name: 'logo-peu-int-linkedin@2x';
$logo-peu-int-linkedin-2x-x: 0px;
$logo-peu-int-linkedin-2x-y: 438px;
$logo-peu-int-linkedin-2x-offset-x: 0px;
$logo-peu-int-linkedin-2x-offset-y: -438px;
$logo-peu-int-linkedin-2x-width: 48px;
$logo-peu-int-linkedin-2x-height: 52px;
$logo-peu-int-linkedin-2x-total-width: 554px;
$logo-peu-int-linkedin-2x-total-height: 490px;
$logo-peu-int-linkedin-2x-image: 'img/spritesheet@2x.png?1720790066986';
$logo-peu-int-linkedin-2x: (0px, 438px, 0px, -438px, 48px, 52px, 554px, 490px, 'img/spritesheet@2x.png?1720790066986', 'logo-peu-int-linkedin@2x', );
$logo-peu-int-twitter-2x-name: 'logo-peu-int-twitter@2x';
$logo-peu-int-twitter-2x-x: 50px;
$logo-peu-int-twitter-2x-y: 388px;
$logo-peu-int-twitter-2x-offset-x: -50px;
$logo-peu-int-twitter-2x-offset-y: -388px;
$logo-peu-int-twitter-2x-width: 54px;
$logo-peu-int-twitter-2x-height: 46px;
$logo-peu-int-twitter-2x-total-width: 554px;
$logo-peu-int-twitter-2x-total-height: 490px;
$logo-peu-int-twitter-2x-image: 'img/spritesheet@2x.png?1720790066986';
$logo-peu-int-twitter-2x: (50px, 388px, -50px, -388px, 54px, 46px, 554px, 490px, 'img/spritesheet@2x.png?1720790066986', 'logo-peu-int-twitter@2x', );
$logo-peu-int-xarxes-2x-name: 'logo-peu-int-xarxes@2x';
$logo-peu-int-xarxes-2x-x: 144px;
$logo-peu-int-xarxes-2x-y: 438px;
$logo-peu-int-xarxes-2x-offset-x: -144px;
$logo-peu-int-xarxes-2x-offset-y: -438px;
$logo-peu-int-xarxes-2x-width: 42px;
$logo-peu-int-xarxes-2x-height: 44px;
$logo-peu-int-xarxes-2x-total-width: 554px;
$logo-peu-int-xarxes-2x-total-height: 490px;
$logo-peu-int-xarxes-2x-image: 'img/spritesheet@2x.png?1720790066986';
$logo-peu-int-xarxes-2x: (144px, 438px, -144px, -438px, 42px, 44px, 554px, 490px, 'img/spritesheet@2x.png?1720790066986', 'logo-peu-int-xarxes@2x', );
$logo-peu-int-youtube-2x-name: 'logo-peu-int-youtube@2x';
$logo-peu-int-youtube-2x-x: 148px;
$logo-peu-int-youtube-2x-y: 190px;
$logo-peu-int-youtube-2x-offset-x: -148px;
$logo-peu-int-youtube-2x-offset-y: -190px;
$logo-peu-int-youtube-2x-width: 54px;
$logo-peu-int-youtube-2x-height: 38px;
$logo-peu-int-youtube-2x-total-width: 554px;
$logo-peu-int-youtube-2x-total-height: 490px;
$logo-peu-int-youtube-2x-image: 'img/spritesheet@2x.png?1720790066986';
$logo-peu-int-youtube-2x: (148px, 190px, -148px, -190px, 54px, 38px, 554px, 490px, 'img/spritesheet@2x.png?1720790066986', 'logo-peu-int-youtube@2x', );
$logo-twitter-2x-name: 'logo-twitter@2x';
$logo-twitter-2x-x: 80px;
$logo-twitter-2x-y: 308px;
$logo-twitter-2x-offset-x: -80px;
$logo-twitter-2x-offset-y: -308px;
$logo-twitter-2x-width: 64px;
$logo-twitter-2x-height: 64px;
$logo-twitter-2x-total-width: 554px;
$logo-twitter-2x-total-height: 490px;
$logo-twitter-2x-image: 'img/spritesheet@2x.png?1720790066986';
$logo-twitter-2x: (80px, 308px, -80px, -308px, 64px, 64px, 554px, 490px, 'img/spritesheet@2x.png?1720790066986', 'logo-twitter@2x', );
$megamenu-tancar-2x-name: 'megamenu-tancar@2x';
$megamenu-tancar-2x-x: 150px;
$megamenu-tancar-2x-y: 388px;
$megamenu-tancar-2x-offset-x: -150px;
$megamenu-tancar-2x-offset-y: -388px;
$megamenu-tancar-2x-width: 46px;
$megamenu-tancar-2x-height: 46px;
$megamenu-tancar-2x-total-width: 554px;
$megamenu-tancar-2x-total-height: 490px;
$megamenu-tancar-2x-image: 'img/spritesheet@2x.png?1720790066986';
$megamenu-tancar-2x: (150px, 388px, -150px, -388px, 46px, 46px, 554px, 490px, 'img/spritesheet@2x.png?1720790066986', 'megamenu-tancar@2x', );
$menu-mobile-b-nivell1-2x-name: 'menu-mobile-b-nivell1@2x';
$menu-mobile-b-nivell1-2x-x: 350px;
$menu-mobile-b-nivell1-2x-y: 438px;
$menu-mobile-b-nivell1-2x-offset-x: -350px;
$menu-mobile-b-nivell1-2x-offset-y: -438px;
$menu-mobile-b-nivell1-2x-width: 38px;
$menu-mobile-b-nivell1-2x-height: 28px;
$menu-mobile-b-nivell1-2x-total-width: 554px;
$menu-mobile-b-nivell1-2x-total-height: 490px;
$menu-mobile-b-nivell1-2x-image: 'img/spritesheet@2x.png?1720790066986';
$menu-mobile-b-nivell1-2x: (350px, 438px, -350px, -438px, 38px, 28px, 554px, 490px, 'img/spritesheet@2x.png?1720790066986', 'menu-mobile-b-nivell1@2x', );
$menu-mobile-b-nivell2-2x-name: 'menu-mobile-b-nivell2@2x';
$menu-mobile-b-nivell2-2x-x: 424px;
$menu-mobile-b-nivell2-2x-y: 438px;
$menu-mobile-b-nivell2-2x-offset-x: -424px;
$menu-mobile-b-nivell2-2x-offset-y: -438px;
$menu-mobile-b-nivell2-2x-width: 36px;
$menu-mobile-b-nivell2-2x-height: 20px;
$menu-mobile-b-nivell2-2x-total-width: 554px;
$menu-mobile-b-nivell2-2x-total-height: 490px;
$menu-mobile-b-nivell2-2x-image: 'img/spritesheet@2x.png?1720790066986';
$menu-mobile-b-nivell2-2x: (424px, 438px, -424px, -438px, 36px, 20px, 554px, 490px, 'img/spritesheet@2x.png?1720790066986', 'menu-mobile-b-nivell2@2x', );
$menu-mobile-b-nivell3-2x-name: 'menu-mobile-b-nivell3@2x';
$menu-mobile-b-nivell3-2x-x: 520px;
$menu-mobile-b-nivell3-2x-y: 162px;
$menu-mobile-b-nivell3-2x-offset-x: -520px;
$menu-mobile-b-nivell3-2x-offset-y: -162px;
$menu-mobile-b-nivell3-2x-width: 20px;
$menu-mobile-b-nivell3-2x-height: 36px;
$menu-mobile-b-nivell3-2x-total-width: 554px;
$menu-mobile-b-nivell3-2x-total-height: 490px;
$menu-mobile-b-nivell3-2x-image: 'img/spritesheet@2x.png?1720790066986';
$menu-mobile-b-nivell3-2x: (520px, 162px, -520px, -162px, 20px, 36px, 554px, 490px, 'img/spritesheet@2x.png?1720790066986', 'menu-mobile-b-nivell3@2x', );
$share-facebook-2x-name: 'share-facebook@2x';
$share-facebook-2x-x: 0px;
$share-facebook-2x-y: 388px;
$share-facebook-2x-offset-x: 0px;
$share-facebook-2x-offset-y: -388px;
$share-facebook-2x-width: 50px;
$share-facebook-2x-height: 50px;
$share-facebook-2x-total-width: 554px;
$share-facebook-2x-total-height: 490px;
$share-facebook-2x-image: 'img/spritesheet@2x.png?1720790066986';
$share-facebook-2x: (0px, 388px, 0px, -388px, 50px, 50px, 554px, 490px, 'img/spritesheet@2x.png?1720790066986', 'share-facebook@2x', );
$share-instagram-2x-name: 'share-instagram@2x';
$share-instagram-2x-x: 96px;
$share-instagram-2x-y: 438px;
$share-instagram-2x-offset-x: -96px;
$share-instagram-2x-offset-y: -438px;
$share-instagram-2x-width: 48px;
$share-instagram-2x-height: 48px;
$share-instagram-2x-total-width: 554px;
$share-instagram-2x-total-height: 490px;
$share-instagram-2x-image: 'img/spritesheet@2x.png?1720790066986';
$share-instagram-2x: (96px, 438px, -96px, -438px, 48px, 48px, 554px, 490px, 'img/spritesheet@2x.png?1720790066986', 'share-instagram@2x', );
$share-linkedin-2x-name: 'share-linkedin@2x';
$share-linkedin-2x-x: 48px;
$share-linkedin-2x-y: 438px;
$share-linkedin-2x-offset-x: -48px;
$share-linkedin-2x-offset-y: -438px;
$share-linkedin-2x-width: 48px;
$share-linkedin-2x-height: 50px;
$share-linkedin-2x-total-width: 554px;
$share-linkedin-2x-total-height: 490px;
$share-linkedin-2x-image: 'img/spritesheet@2x.png?1720790066986';
$share-linkedin-2x: (48px, 438px, -48px, -438px, 48px, 50px, 554px, 490px, 'img/spritesheet@2x.png?1720790066986', 'share-linkedin@2x', );
$share-twitter-2x-name: 'share-twitter@2x';
$share-twitter-2x-x: 94px;
$share-twitter-2x-y: 190px;
$share-twitter-2x-offset-x: -94px;
$share-twitter-2x-offset-y: -190px;
$share-twitter-2x-width: 54px;
$share-twitter-2x-height: 44px;
$share-twitter-2x-total-width: 554px;
$share-twitter-2x-total-height: 490px;
$share-twitter-2x-image: 'img/spritesheet@2x.png?1720790066986';
$share-twitter-2x: (94px, 190px, -94px, -190px, 54px, 44px, 554px, 490px, 'img/spritesheet@2x.png?1720790066986', 'share-twitter@2x', );
$slide-endavant-320-2x-name: 'slide-endavant-320@2x';
$slide-endavant-320-2x-x: 470px;
$slide-endavant-320-2x-y: 248px;
$slide-endavant-320-2x-offset-x: -470px;
$slide-endavant-320-2x-offset-y: -248px;
$slide-endavant-320-2x-width: 50px;
$slide-endavant-320-2x-height: 72px;
$slide-endavant-320-2x-total-width: 554px;
$slide-endavant-320-2x-total-height: 490px;
$slide-endavant-320-2x-image: 'img/spritesheet@2x.png?1720790066986';
$slide-endavant-320-2x: (470px, 248px, -470px, -248px, 50px, 72px, 554px, 490px, 'img/spritesheet@2x.png?1720790066986', 'slide-endavant-320@2x', );
$slide-endavant-768-2x-name: 'slide-endavant-768@2x';
$slide-endavant-768-2x-x: 398px;
$slide-endavant-768-2x-y: 0px;
$slide-endavant-768-2x-offset-x: -398px;
$slide-endavant-768-2x-offset-y: 0px;
$slide-endavant-768-2x-width: 72px;
$slide-endavant-768-2x-height: 104px;
$slide-endavant-768-2x-total-width: 554px;
$slide-endavant-768-2x-total-height: 490px;
$slide-endavant-768-2x-image: 'img/spritesheet@2x.png?1720790066986';
$slide-endavant-768-2x: (398px, 0px, -398px, 0px, 72px, 104px, 554px, 490px, 'img/spritesheet@2x.png?1720790066986', 'slide-endavant-768@2x', );
$slide-endavant-2x-name: 'slide-endavant@2x';
$slide-endavant-2x-x: 0px;
$slide-endavant-2x-y: 0px;
$slide-endavant-2x-offset-x: 0px;
$slide-endavant-2x-offset-y: 0px;
$slide-endavant-2x-width: 102px;
$slide-endavant-2x-height: 144px;
$slide-endavant-2x-total-width: 554px;
$slide-endavant-2x-total-height: 490px;
$slide-endavant-2x-image: 'img/spritesheet@2x.png?1720790066986';
$slide-endavant-2x: (0px, 0px, 0px, 0px, 102px, 144px, 554px, 490px, 'img/spritesheet@2x.png?1720790066986', 'slide-endavant@2x', );
$slide-enrere-320-2x-name: 'slide-enrere-320@2x';
$slide-enrere-320-2x-x: 470px;
$slide-enrere-320-2x-y: 176px;
$slide-enrere-320-2x-offset-x: -470px;
$slide-enrere-320-2x-offset-y: -176px;
$slide-enrere-320-2x-width: 50px;
$slide-enrere-320-2x-height: 72px;
$slide-enrere-320-2x-total-width: 554px;
$slide-enrere-320-2x-total-height: 490px;
$slide-enrere-320-2x-image: 'img/spritesheet@2x.png?1720790066986';
$slide-enrere-320-2x: (470px, 176px, -470px, -176px, 50px, 72px, 554px, 490px, 'img/spritesheet@2x.png?1720790066986', 'slide-enrere-320@2x', );
$slide-enrere-768-2x-name: 'slide-enrere-768@2x';
$slide-enrere-768-2x-x: 310px;
$slide-enrere-768-2x-y: 88px;
$slide-enrere-768-2x-offset-x: -310px;
$slide-enrere-768-2x-offset-y: -88px;
$slide-enrere-768-2x-width: 72px;
$slide-enrere-768-2x-height: 104px;
$slide-enrere-768-2x-total-width: 554px;
$slide-enrere-768-2x-total-height: 490px;
$slide-enrere-768-2x-image: 'img/spritesheet@2x.png?1720790066986';
$slide-enrere-768-2x: (310px, 88px, -310px, -88px, 72px, 104px, 554px, 490px, 'img/spritesheet@2x.png?1720790066986', 'slide-enrere-768@2x', );
$slide-enrere-2x-name: 'slide-enrere@2x';
$slide-enrere-2x-x: 102px;
$slide-enrere-2x-y: 0px;
$slide-enrere-2x-offset-x: -102px;
$slide-enrere-2x-offset-y: 0px;
$slide-enrere-2x-width: 102px;
$slide-enrere-2x-height: 144px;
$slide-enrere-2x-total-width: 554px;
$slide-enrere-2x-total-height: 490px;
$slide-enrere-2x-image: 'img/spritesheet@2x.png?1720790066986';
$slide-enrere-2x: (102px, 0px, -102px, 0px, 102px, 144px, 554px, 490px, 'img/spritesheet@2x.png?1720790066986', 'slide-enrere@2x', );
$slide-noticia-endavant-2x-name: 'slide-noticia-endavant@2x';
$slide-noticia-endavant-2x-x: 470px;
$slide-noticia-endavant-2x-y: 0px;
$slide-noticia-endavant-2x-offset-x: -470px;
$slide-noticia-endavant-2x-offset-y: 0px;
$slide-noticia-endavant-2x-width: 50px;
$slide-noticia-endavant-2x-height: 88px;
$slide-noticia-endavant-2x-total-width: 554px;
$slide-noticia-endavant-2x-total-height: 490px;
$slide-noticia-endavant-2x-image: 'img/spritesheet@2x.png?1720790066986';
$slide-noticia-endavant-2x: (470px, 0px, -470px, 0px, 50px, 88px, 554px, 490px, 'img/spritesheet@2x.png?1720790066986', 'slide-noticia-endavant@2x', );
$slide-noticia-enrera-2x-name: 'slide-noticia-enrera@2x';
$slide-noticia-enrera-2x-x: 470px;
$slide-noticia-enrera-2x-y: 88px;
$slide-noticia-enrera-2x-offset-x: -470px;
$slide-noticia-enrera-2x-offset-y: -88px;
$slide-noticia-enrera-2x-width: 50px;
$slide-noticia-enrera-2x-height: 88px;
$slide-noticia-enrera-2x-total-width: 554px;
$slide-noticia-enrera-2x-total-height: 490px;
$slide-noticia-enrera-2x-image: 'img/spritesheet@2x.png?1720790066986';
$slide-noticia-enrera-2x: (470px, 88px, -470px, -88px, 50px, 88px, 554px, 490px, 'img/spritesheet@2x.png?1720790066986', 'slide-noticia-enrera@2x', );
$tancar-alerta-2x-name: 'tancar-alerta@2x';
$tancar-alerta-2x-x: 104px;
$tancar-alerta-2x-y: 388px;
$tancar-alerta-2x-offset-x: -104px;
$tancar-alerta-2x-offset-y: -388px;
$tancar-alerta-2x-width: 46px;
$tancar-alerta-2x-height: 46px;
$tancar-alerta-2x-total-width: 554px;
$tancar-alerta-2x-total-height: 490px;
$tancar-alerta-2x-image: 'img/spritesheet@2x.png?1720790066986';
$tancar-alerta-2x: (104px, 388px, -104px, -388px, 46px, 46px, 554px, 490px, 'img/spritesheet@2x.png?1720790066986', 'tancar-alerta@2x', );
$top-2x-name: 'top@2x';
$top-2x-x: 204px;
$top-2x-y: 94px;
$top-2x-offset-x: -204px;
$top-2x-offset-y: -94px;
$top-2x-width: 86px;
$top-2x-height: 48px;
$top-2x-total-width: 554px;
$top-2x-total-height: 490px;
$top-2x-image: 'img/spritesheet@2x.png?1720790066986';
$top-2x: (204px, 94px, -204px, -94px, 86px, 48px, 554px, 490px, 'img/spritesheet@2x.png?1720790066986', 'top@2x', );
$vincle-extern-bl-2x-name: 'vincle-extern-bl@2x';
$vincle-extern-bl-2x-x: 520px;
$vincle-extern-bl-2x-y: 134px;
$vincle-extern-bl-2x-offset-x: -520px;
$vincle-extern-bl-2x-offset-y: -134px;
$vincle-extern-bl-2x-width: 26px;
$vincle-extern-bl-2x-height: 28px;
$vincle-extern-bl-2x-total-width: 554px;
$vincle-extern-bl-2x-total-height: 490px;
$vincle-extern-bl-2x-image: 'img/spritesheet@2x.png?1720790066986';
$vincle-extern-bl-2x: (520px, 134px, -520px, -134px, 26px, 28px, 554px, 490px, 'img/spritesheet@2x.png?1720790066986', 'vincle-extern-bl@2x', );
$vincle-extern-2x-name: 'vincle-extern@2x';
$vincle-extern-2x-x: 520px;
$vincle-extern-2x-y: 106px;
$vincle-extern-2x-offset-x: -520px;
$vincle-extern-2x-offset-y: -106px;
$vincle-extern-2x-width: 26px;
$vincle-extern-2x-height: 28px;
$vincle-extern-2x-total-width: 554px;
$vincle-extern-2x-total-height: 490px;
$vincle-extern-2x-image: 'img/spritesheet@2x.png?1720790066986';
$vincle-extern-2x: (520px, 106px, -520px, -106px, 26px, 28px, 554px, 490px, 'img/spritesheet@2x.png?1720790066986', 'vincle-extern@2x', );
$xarxes-portada-facebook-2x-name: 'xarxes-portada-facebook@2x';
$xarxes-portada-facebook-2x-x: 270px;
$xarxes-portada-facebook-2x-y: 308px;
$xarxes-portada-facebook-2x-offset-x: -270px;
$xarxes-portada-facebook-2x-offset-y: -308px;
$xarxes-portada-facebook-2x-width: 58px;
$xarxes-portada-facebook-2x-height: 60px;
$xarxes-portada-facebook-2x-total-width: 554px;
$xarxes-portada-facebook-2x-total-height: 490px;
$xarxes-portada-facebook-2x-image: 'img/spritesheet@2x.png?1720790066986';
$xarxes-portada-facebook-2x: (270px, 308px, -270px, -308px, 58px, 60px, 554px, 490px, 'img/spritesheet@2x.png?1720790066986', 'xarxes-portada-facebook@2x', );
$xarxes-portada-instagram-2x-name: 'xarxes-portada-instagram@2x';
$xarxes-portada-instagram-2x-x: 398px;
$xarxes-portada-instagram-2x-y: 188px;
$xarxes-portada-instagram-2x-offset-x: -398px;
$xarxes-portada-instagram-2x-offset-y: -188px;
$xarxes-portada-instagram-2x-width: 66px;
$xarxes-portada-instagram-2x-height: 70px;
$xarxes-portada-instagram-2x-total-width: 554px;
$xarxes-portada-instagram-2x-total-height: 490px;
$xarxes-portada-instagram-2x-image: 'img/spritesheet@2x.png?1720790066986';
$xarxes-portada-instagram-2x: (398px, 188px, -398px, -188px, 66px, 70px, 554px, 490px, 'img/spritesheet@2x.png?1720790066986', 'xarxes-portada-instagram@2x', );
$xarxes-portada-linkedin-2x-name: 'xarxes-portada-linkedin@2x';
$xarxes-portada-linkedin-2x-x: 328px;
$xarxes-portada-linkedin-2x-y: 308px;
$xarxes-portada-linkedin-2x-offset-x: -328px;
$xarxes-portada-linkedin-2x-offset-y: -308px;
$xarxes-portada-linkedin-2x-width: 58px;
$xarxes-portada-linkedin-2x-height: 60px;
$xarxes-portada-linkedin-2x-total-width: 554px;
$xarxes-portada-linkedin-2x-total-height: 490px;
$xarxes-portada-linkedin-2x-image: 'img/spritesheet@2x.png?1720790066986';
$xarxes-portada-linkedin-2x: (328px, 308px, -328px, -308px, 58px, 60px, 554px, 490px, 'img/spritesheet@2x.png?1720790066986', 'xarxes-portada-linkedin@2x', );
$xarxes-portada-mes-2x-name: 'xarxes-portada-mes@2x';
$xarxes-portada-mes-2x-x: 208px;
$xarxes-portada-mes-2x-y: 308px;
$xarxes-portada-mes-2x-offset-x: -208px;
$xarxes-portada-mes-2x-offset-y: -308px;
$xarxes-portada-mes-2x-width: 62px;
$xarxes-portada-mes-2x-height: 60px;
$xarxes-portada-mes-2x-total-width: 554px;
$xarxes-portada-mes-2x-total-height: 490px;
$xarxes-portada-mes-2x-image: 'img/spritesheet@2x.png?1720790066986';
$xarxes-portada-mes-2x: (208px, 308px, -208px, -308px, 62px, 60px, 554px, 490px, 'img/spritesheet@2x.png?1720790066986', 'xarxes-portada-mes@2x', );
$xarxes-portada-twitter-2x-name: 'xarxes-portada-twitter@2x';
$xarxes-portada-twitter-2x-x: 104px;
$xarxes-portada-twitter-2x-y: 234px;
$xarxes-portada-twitter-2x-offset-x: -104px;
$xarxes-portada-twitter-2x-offset-y: -234px;
$xarxes-portada-twitter-2x-width: 78px;
$xarxes-portada-twitter-2x-height: 66px;
$xarxes-portada-twitter-2x-total-width: 554px;
$xarxes-portada-twitter-2x-total-height: 490px;
$xarxes-portada-twitter-2x-image: 'img/spritesheet@2x.png?1720790066986';
$xarxes-portada-twitter-2x: (104px, 234px, -104px, -234px, 78px, 66px, 554px, 490px, 'img/spritesheet@2x.png?1720790066986', 'xarxes-portada-twitter@2x', );
$xarxes-portada-youtube-2x-name: 'xarxes-portada-youtube@2x';
$xarxes-portada-youtube-2x-x: 182px;
$xarxes-portada-youtube-2x-y: 234px;
$xarxes-portada-youtube-2x-offset-x: -182px;
$xarxes-portada-youtube-2x-offset-y: -234px;
$xarxes-portada-youtube-2x-width: 78px;
$xarxes-portada-youtube-2x-height: 56px;
$xarxes-portada-youtube-2x-total-width: 554px;
$xarxes-portada-youtube-2x-total-height: 490px;
$xarxes-portada-youtube-2x-image: 'img/spritesheet@2x.png?1720790066986';
$xarxes-portada-youtube-2x: (182px, 234px, -182px, -234px, 78px, 56px, 554px, 490px, 'img/spritesheet@2x.png?1720790066986', 'xarxes-portada-youtube@2x', );
$spritesheet-width: 277px;
$spritesheet-height: 245px;
$spritesheet-image: 'img/spritesheet.png?1720790066986';
$spritesheet-sprites: ($acordio-desplega, $acordio-plegar, $candau-bl, $candau, $cercar-menu-tablet, $cercar-menu, $destacat-puntets, $ico-acrobatpdf, $ico-compartir-correu, $ico-compartir-telegram, $ico-compartir-whatsapp, $ico-megamenu-dropdown-hover, $ico-megamenu-dropdown, $ico-megarmenu-tancar, $ico-entrevistes, $ico-opinions, $icon-alerta, $icon-desplega-menu-sup, $icon-download, $icon-enllacos-pdf, $icon-enllacos, $icon-fitxa-adressa, $icon-fitxa-fax, $icon-fitxa-mail, $icon-fitxa-telefon, $icon-fitxa-usuari, $icon-fitxa-video, $icon-link, $icon-menu-small, $icon-mes, $icon-professorat, $icon-programa-pdf, $icon-qualitat, $icona-filtre-cercar, $kland-llistat-nivell-1, $kland-llistat-nivell-2, $kland-llistat-nivell-3, $kland-peu, $logo-linkedin, $logo-peu-int-facebook, $logo-peu-int-instagram, $logo-peu-int-linkedin, $logo-peu-int-twitter, $logo-peu-int-xarxes, $logo-peu-int-youtube, $logo-twitter, $megamenu-tancar, $menu-mobile-b-nivell1, $menu-mobile-b-nivell2, $menu-mobile-b-nivell3, $share-facebook, $share-instagram, $share-linkedin, $share-twitter, $slide-endavant-320, $slide-endavant-768, $slide-endavant, $slide-enrere-320, $slide-enrere-768, $slide-enrere, $slide-noticia-endavant, $slide-noticia-enrera, $tancar-alerta, $top, $vincle-extern-bl, $vincle-extern, $xarxes-portada-facebook, $xarxes-portada-instagram, $xarxes-portada-linkedin, $xarxes-portada-mes, $xarxes-portada-twitter, $xarxes-portada-youtube, );
$spritesheet: (277px, 245px, 'img/spritesheet.png?1720790066986', $spritesheet-sprites, );
$retina-spritesheet-width: 554px;
$retina-spritesheet-height: 490px;
$retina-spritesheet-image: 'img/spritesheet@2x.png?1720790066986';
$retina-spritesheet-sprites: ($acordio-desplega-2x, $acordio-plegar-2x, $candau-bl-2x, $candau-2x, $cercar-menu-tablet-2x, $cercar-menu-2x, $destacat-puntets-2x, $ico-acrobatpdf-2x, $ico-compartir-correu-2x, $ico-compartir-telegram-2x, $ico-compartir-whatsapp-2x, $ico-megamenu-dropdown-hover-2x, $ico-megamenu-dropdown-2x, $ico-megarmenu-tancar-2x, $ico-entrevistes-2x, $ico-opinions-2x, $icon-alerta-2x, $icon-desplega-menu-sup-2x, $icon-download-2x, $icon-enllacos-pdf-2x, $icon-enllacos-2x, $icon-fitxa-adressa-2x, $icon-fitxa-fax-2x, $icon-fitxa-mail-2x, $icon-fitxa-telefon-2x, $icon-fitxa-usuari-2x, $icon-fitxa-video-2x, $icon-link-2x, $icon-menu-small-2x, $icon-mes-2x, $icon-professorat-2x, $icon-programa-pdf-2x, $icon-qualitat-2x, $icona-filtre-cercar-2x, $kland-llistat-nivell-1-2x, $kland-llistat-nivell-2-2x, $kland-llistat-nivell-3-2x, $kland-peu-2x, $logo-linkedin-2x, $logo-peu-int-facebook-2x, $logo-peu-int-instagram-2x, $logo-peu-int-linkedin-2x, $logo-peu-int-twitter-2x, $logo-peu-int-xarxes-2x, $logo-peu-int-youtube-2x, $logo-twitter-2x, $megamenu-tancar-2x, $menu-mobile-b-nivell1-2x, $menu-mobile-b-nivell2-2x, $menu-mobile-b-nivell3-2x, $share-facebook-2x, $share-instagram-2x, $share-linkedin-2x, $share-twitter-2x, $slide-endavant-320-2x, $slide-endavant-768-2x, $slide-endavant-2x, $slide-enrere-320-2x, $slide-enrere-768-2x, $slide-enrere-2x, $slide-noticia-endavant-2x, $slide-noticia-enrera-2x, $tancar-alerta-2x, $top-2x, $vincle-extern-bl-2x, $vincle-extern-2x, $xarxes-portada-facebook-2x, $xarxes-portada-instagram-2x, $xarxes-portada-linkedin-2x, $xarxes-portada-mes-2x, $xarxes-portada-twitter-2x, $xarxes-portada-youtube-2x, );
$retina-spritesheet: (554px, 490px, 'img/spritesheet@2x.png?1720790066986', $retina-spritesheet-sprites, );

// These "retina group" variables are mappings for the naming and pairing of normal and retina sprites.
//
// The list formatted variables are intended for mixins like `retina-sprite` and `retina-sprites`.
$acordio-desplega-group-name: 'acordio-desplega';
$acordio-desplega-group: ('acordio-desplega', $acordio-desplega, $acordio-desplega-2x, );
$acordio-plegar-group-name: 'acordio-plegar';
$acordio-plegar-group: ('acordio-plegar', $acordio-plegar, $acordio-plegar-2x, );
$candau-bl-group-name: 'candau-bl';
$candau-bl-group: ('candau-bl', $candau-bl, $candau-bl-2x, );
$candau-group-name: 'candau';
$candau-group: ('candau', $candau, $candau-2x, );
$cercar-menu-tablet-group-name: 'cercar-menu-tablet';
$cercar-menu-tablet-group: ('cercar-menu-tablet', $cercar-menu-tablet, $cercar-menu-tablet-2x, );
$cercar-menu-group-name: 'cercar-menu';
$cercar-menu-group: ('cercar-menu', $cercar-menu, $cercar-menu-2x, );
$destacat-puntets-group-name: 'destacat-puntets';
$destacat-puntets-group: ('destacat-puntets', $destacat-puntets, $destacat-puntets-2x, );
$ico-acrobatpdf-group-name: 'ico-acrobatpdf';
$ico-acrobatpdf-group: ('ico-acrobatpdf', $ico-acrobatpdf, $ico-acrobatpdf-2x, );
$ico-compartir-correu-group-name: 'ico-compartir-correu';
$ico-compartir-correu-group: ('ico-compartir-correu', $ico-compartir-correu, $ico-compartir-correu-2x, );
$ico-compartir-telegram-group-name: 'ico-compartir-telegram';
$ico-compartir-telegram-group: ('ico-compartir-telegram', $ico-compartir-telegram, $ico-compartir-telegram-2x, );
$ico-compartir-whatsapp-group-name: 'ico-compartir-whatsapp';
$ico-compartir-whatsapp-group: ('ico-compartir-whatsapp', $ico-compartir-whatsapp, $ico-compartir-whatsapp-2x, );
$ico-megamenu-dropdown-hover-group-name: 'ico-megamenu-dropdown-hover';
$ico-megamenu-dropdown-hover-group: ('ico-megamenu-dropdown-hover', $ico-megamenu-dropdown-hover, $ico-megamenu-dropdown-hover-2x, );
$ico-megamenu-dropdown-group-name: 'ico-megamenu-dropdown';
$ico-megamenu-dropdown-group: ('ico-megamenu-dropdown', $ico-megamenu-dropdown, $ico-megamenu-dropdown-2x, );
$ico-megarmenu-tancar-group-name: 'ico-megarmenu-tancar';
$ico-megarmenu-tancar-group: ('ico-megarmenu-tancar', $ico-megarmenu-tancar, $ico-megarmenu-tancar-2x, );
$ico-entrevistes-group-name: 'ico_entrevistes';
$ico-entrevistes-group: ('ico_entrevistes', $ico-entrevistes, $ico-entrevistes-2x, );
$ico-opinions-group-name: 'ico_opinions';
$ico-opinions-group: ('ico_opinions', $ico-opinions, $ico-opinions-2x, );
$icon-alerta-group-name: 'icon-alerta';
$icon-alerta-group: ('icon-alerta', $icon-alerta, $icon-alerta-2x, );
$icon-desplega-menu-sup-group-name: 'icon-desplega-menu-sup';
$icon-desplega-menu-sup-group: ('icon-desplega-menu-sup', $icon-desplega-menu-sup, $icon-desplega-menu-sup-2x, );
$icon-download-group-name: 'icon-download';
$icon-download-group: ('icon-download', $icon-download, $icon-download-2x, );
$icon-enllacos-pdf-group-name: 'icon-enllacos-pdf';
$icon-enllacos-pdf-group: ('icon-enllacos-pdf', $icon-enllacos-pdf, $icon-enllacos-pdf-2x, );
$icon-enllacos-group-name: 'icon-enllacos';
$icon-enllacos-group: ('icon-enllacos', $icon-enllacos, $icon-enllacos-2x, );
$icon-fitxa-adressa-group-name: 'icon-fitxa-adressa';
$icon-fitxa-adressa-group: ('icon-fitxa-adressa', $icon-fitxa-adressa, $icon-fitxa-adressa-2x, );
$icon-fitxa-fax-group-name: 'icon-fitxa-fax';
$icon-fitxa-fax-group: ('icon-fitxa-fax', $icon-fitxa-fax, $icon-fitxa-fax-2x, );
$icon-fitxa-mail-group-name: 'icon-fitxa-mail';
$icon-fitxa-mail-group: ('icon-fitxa-mail', $icon-fitxa-mail, $icon-fitxa-mail-2x, );
$icon-fitxa-telefon-group-name: 'icon-fitxa-telefon';
$icon-fitxa-telefon-group: ('icon-fitxa-telefon', $icon-fitxa-telefon, $icon-fitxa-telefon-2x, );
$icon-fitxa-usuari-group-name: 'icon-fitxa-usuari';
$icon-fitxa-usuari-group: ('icon-fitxa-usuari', $icon-fitxa-usuari, $icon-fitxa-usuari-2x, );
$icon-fitxa-video-group-name: 'icon-fitxa-video';
$icon-fitxa-video-group: ('icon-fitxa-video', $icon-fitxa-video, $icon-fitxa-video-2x, );
$icon-link-group-name: 'icon-link';
$icon-link-group: ('icon-link', $icon-link, $icon-link-2x, );
$icon-menu-small-group-name: 'icon-menu-small';
$icon-menu-small-group: ('icon-menu-small', $icon-menu-small, $icon-menu-small-2x, );
$icon-mes-group-name: 'icon-mes';
$icon-mes-group: ('icon-mes', $icon-mes, $icon-mes-2x, );
$icon-professorat-group-name: 'icon-professorat';
$icon-professorat-group: ('icon-professorat', $icon-professorat, $icon-professorat-2x, );
$icon-programa-pdf-group-name: 'icon-programa-pdf';
$icon-programa-pdf-group: ('icon-programa-pdf', $icon-programa-pdf, $icon-programa-pdf-2x, );
$icon-qualitat-group-name: 'icon-qualitat';
$icon-qualitat-group: ('icon-qualitat', $icon-qualitat, $icon-qualitat-2x, );
$icona-filtre-cercar-group-name: 'icona-filtre-cercar';
$icona-filtre-cercar-group: ('icona-filtre-cercar', $icona-filtre-cercar, $icona-filtre-cercar-2x, );
$kland-llistat-nivell-1-group-name: 'kland-llistat-nivell-1';
$kland-llistat-nivell-1-group: ('kland-llistat-nivell-1', $kland-llistat-nivell-1, $kland-llistat-nivell-1-2x, );
$kland-llistat-nivell-2-group-name: 'kland-llistat-nivell-2';
$kland-llistat-nivell-2-group: ('kland-llistat-nivell-2', $kland-llistat-nivell-2, $kland-llistat-nivell-2-2x, );
$kland-llistat-nivell-3-group-name: 'kland-llistat-nivell-3';
$kland-llistat-nivell-3-group: ('kland-llistat-nivell-3', $kland-llistat-nivell-3, $kland-llistat-nivell-3-2x, );
$kland-peu-group-name: 'kland-peu';
$kland-peu-group: ('kland-peu', $kland-peu, $kland-peu-2x, );
$logo-linkedin-group-name: 'logo-linkedin';
$logo-linkedin-group: ('logo-linkedin', $logo-linkedin, $logo-linkedin-2x, );
$logo-peu-int-facebook-group-name: 'logo-peu-int-facebook';
$logo-peu-int-facebook-group: ('logo-peu-int-facebook', $logo-peu-int-facebook, $logo-peu-int-facebook-2x, );
$logo-peu-int-instagram-group-name: 'logo-peu-int-instagram';
$logo-peu-int-instagram-group: ('logo-peu-int-instagram', $logo-peu-int-instagram, $logo-peu-int-instagram-2x, );
$logo-peu-int-linkedin-group-name: 'logo-peu-int-linkedin';
$logo-peu-int-linkedin-group: ('logo-peu-int-linkedin', $logo-peu-int-linkedin, $logo-peu-int-linkedin-2x, );
$logo-peu-int-twitter-group-name: 'logo-peu-int-twitter';
$logo-peu-int-twitter-group: ('logo-peu-int-twitter', $logo-peu-int-twitter, $logo-peu-int-twitter-2x, );
$logo-peu-int-xarxes-group-name: 'logo-peu-int-xarxes';
$logo-peu-int-xarxes-group: ('logo-peu-int-xarxes', $logo-peu-int-xarxes, $logo-peu-int-xarxes-2x, );
$logo-peu-int-youtube-group-name: 'logo-peu-int-youtube';
$logo-peu-int-youtube-group: ('logo-peu-int-youtube', $logo-peu-int-youtube, $logo-peu-int-youtube-2x, );
$logo-twitter-group-name: 'logo-twitter';
$logo-twitter-group: ('logo-twitter', $logo-twitter, $logo-twitter-2x, );
$megamenu-tancar-group-name: 'megamenu-tancar';
$megamenu-tancar-group: ('megamenu-tancar', $megamenu-tancar, $megamenu-tancar-2x, );
$menu-mobile-b-nivell1-group-name: 'menu-mobile-b-nivell1';
$menu-mobile-b-nivell1-group: ('menu-mobile-b-nivell1', $menu-mobile-b-nivell1, $menu-mobile-b-nivell1-2x, );
$menu-mobile-b-nivell2-group-name: 'menu-mobile-b-nivell2';
$menu-mobile-b-nivell2-group: ('menu-mobile-b-nivell2', $menu-mobile-b-nivell2, $menu-mobile-b-nivell2-2x, );
$menu-mobile-b-nivell3-group-name: 'menu-mobile-b-nivell3';
$menu-mobile-b-nivell3-group: ('menu-mobile-b-nivell3', $menu-mobile-b-nivell3, $menu-mobile-b-nivell3-2x, );
$share-facebook-group-name: 'share-facebook';
$share-facebook-group: ('share-facebook', $share-facebook, $share-facebook-2x, );
$share-instagram-group-name: 'share-instagram';
$share-instagram-group: ('share-instagram', $share-instagram, $share-instagram-2x, );
$share-linkedin-group-name: 'share-linkedin';
$share-linkedin-group: ('share-linkedin', $share-linkedin, $share-linkedin-2x, );
$share-twitter-group-name: 'share-twitter';
$share-twitter-group: ('share-twitter', $share-twitter, $share-twitter-2x, );
$slide-endavant-320-group-name: 'slide-endavant-320';
$slide-endavant-320-group: ('slide-endavant-320', $slide-endavant-320, $slide-endavant-320-2x, );
$slide-endavant-768-group-name: 'slide-endavant-768';
$slide-endavant-768-group: ('slide-endavant-768', $slide-endavant-768, $slide-endavant-768-2x, );
$slide-endavant-group-name: 'slide-endavant';
$slide-endavant-group: ('slide-endavant', $slide-endavant, $slide-endavant-2x, );
$slide-enrere-320-group-name: 'slide-enrere-320';
$slide-enrere-320-group: ('slide-enrere-320', $slide-enrere-320, $slide-enrere-320-2x, );
$slide-enrere-768-group-name: 'slide-enrere-768';
$slide-enrere-768-group: ('slide-enrere-768', $slide-enrere-768, $slide-enrere-768-2x, );
$slide-enrere-group-name: 'slide-enrere';
$slide-enrere-group: ('slide-enrere', $slide-enrere, $slide-enrere-2x, );
$slide-noticia-endavant-group-name: 'slide-noticia-endavant';
$slide-noticia-endavant-group: ('slide-noticia-endavant', $slide-noticia-endavant, $slide-noticia-endavant-2x, );
$slide-noticia-enrera-group-name: 'slide-noticia-enrera';
$slide-noticia-enrera-group: ('slide-noticia-enrera', $slide-noticia-enrera, $slide-noticia-enrera-2x, );
$tancar-alerta-group-name: 'tancar-alerta';
$tancar-alerta-group: ('tancar-alerta', $tancar-alerta, $tancar-alerta-2x, );
$top-group-name: 'top';
$top-group: ('top', $top, $top-2x, );
$vincle-extern-bl-group-name: 'vincle-extern-bl';
$vincle-extern-bl-group: ('vincle-extern-bl', $vincle-extern-bl, $vincle-extern-bl-2x, );
$vincle-extern-group-name: 'vincle-extern';
$vincle-extern-group: ('vincle-extern', $vincle-extern, $vincle-extern-2x, );
$xarxes-portada-facebook-group-name: 'xarxes-portada-facebook';
$xarxes-portada-facebook-group: ('xarxes-portada-facebook', $xarxes-portada-facebook, $xarxes-portada-facebook-2x, );
$xarxes-portada-instagram-group-name: 'xarxes-portada-instagram';
$xarxes-portada-instagram-group: ('xarxes-portada-instagram', $xarxes-portada-instagram, $xarxes-portada-instagram-2x, );
$xarxes-portada-linkedin-group-name: 'xarxes-portada-linkedin';
$xarxes-portada-linkedin-group: ('xarxes-portada-linkedin', $xarxes-portada-linkedin, $xarxes-portada-linkedin-2x, );
$xarxes-portada-mes-group-name: 'xarxes-portada-mes';
$xarxes-portada-mes-group: ('xarxes-portada-mes', $xarxes-portada-mes, $xarxes-portada-mes-2x, );
$xarxes-portada-twitter-group-name: 'xarxes-portada-twitter';
$xarxes-portada-twitter-group: ('xarxes-portada-twitter', $xarxes-portada-twitter, $xarxes-portada-twitter-2x, );
$xarxes-portada-youtube-group-name: 'xarxes-portada-youtube';
$xarxes-portada-youtube-group: ('xarxes-portada-youtube', $xarxes-portada-youtube, $xarxes-portada-youtube-2x, );
$retina-groups: ($acordio-desplega-group, $acordio-plegar-group, $candau-bl-group, $candau-group, $cercar-menu-tablet-group, $cercar-menu-group, $destacat-puntets-group, $ico-acrobatpdf-group, $ico-compartir-correu-group, $ico-compartir-telegram-group, $ico-compartir-whatsapp-group, $ico-megamenu-dropdown-hover-group, $ico-megamenu-dropdown-group, $ico-megarmenu-tancar-group, $ico-entrevistes-group, $ico-opinions-group, $icon-alerta-group, $icon-desplega-menu-sup-group, $icon-download-group, $icon-enllacos-pdf-group, $icon-enllacos-group, $icon-fitxa-adressa-group, $icon-fitxa-fax-group, $icon-fitxa-mail-group, $icon-fitxa-telefon-group, $icon-fitxa-usuari-group, $icon-fitxa-video-group, $icon-link-group, $icon-menu-small-group, $icon-mes-group, $icon-professorat-group, $icon-programa-pdf-group, $icon-qualitat-group, $icona-filtre-cercar-group, $kland-llistat-nivell-1-group, $kland-llistat-nivell-2-group, $kland-llistat-nivell-3-group, $kland-peu-group, $logo-linkedin-group, $logo-peu-int-facebook-group, $logo-peu-int-instagram-group, $logo-peu-int-linkedin-group, $logo-peu-int-twitter-group, $logo-peu-int-xarxes-group, $logo-peu-int-youtube-group, $logo-twitter-group, $megamenu-tancar-group, $menu-mobile-b-nivell1-group, $menu-mobile-b-nivell2-group, $menu-mobile-b-nivell3-group, $share-facebook-group, $share-instagram-group, $share-linkedin-group, $share-twitter-group, $slide-endavant-320-group, $slide-endavant-768-group, $slide-endavant-group, $slide-enrere-320-group, $slide-enrere-768-group, $slide-enrere-group, $slide-noticia-endavant-group, $slide-noticia-enrera-group, $tancar-alerta-group, $top-group, $vincle-extern-bl-group, $vincle-extern-group, $xarxes-portada-facebook-group, $xarxes-portada-instagram-group, $xarxes-portada-linkedin-group, $xarxes-portada-mes-group, $xarxes-portada-twitter-group, $xarxes-portada-youtube-group, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `retina-sprite` mixin sets up rules and a media query for a sprite/retina sprite.
//   It should be used with a "retina group" variable.
//
// The media query is from CSS Tricks: https://css-tricks.com/snippets/css/retina-display-media-query/
//
// $icon-home-group: ('icon-home', $icon-home, $icon-home-2x, );
//
// .icon-home {
//   @include retina-sprite($icon-home-group);
// }
@mixin sprite-background-size($sprite) {
  $sprite-total-width: nth($sprite, 7);
  $sprite-total-height: nth($sprite, 8);
  background-size: $sprite-total-width $sprite-total-height;
}

@mixin retina-sprite($retina-group) {
  $normal-sprite: nth($retina-group, 2);
  $retina-sprite: nth($retina-group, 3);
  @include sprite($normal-sprite);

  @media (-webkit-min-device-pixel-ratio: 2),
         (min-resolution: 192dpi) {
    @include sprite-image($retina-sprite);
    @include sprite-background-size($normal-sprite);
  }
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}

// The `retina-sprites` mixin generates a CSS rule and media query for retina groups
//   This yields the same output as CSS retina template but can be overridden in SCSS
//
// @include retina-sprites($retina-groups);
@mixin retina-sprites($retina-groups) {
  @each $retina-group in $retina-groups {
    $sprite-name: nth($retina-group, 1);
    .#{$sprite-name} {
      @include retina-sprite($retina-group);
    }
  }
}
