#skiptocontent a {
  padding:6px;
  position: absolute;
  top:-40px;
  left:0px;
  color:#000;
  border-right:1px solid $primary-color;
  border-bottom:1px solid $primary-color;
  border-bottom-right-radius:8px;
  background:#fff;
  -webkit-transition: top 1s ease-out, background 1s linear;
    transition: top 1s ease-out, background 1s linear;
    z-index: 100;
}

#skiptocontent a:focus {
  position:absolute;
  left:0px;
  top:0px;

  outline:0;

  -webkit-transition: top .1s ease-in, background .5s linear;
    transition: top .1s ease-in, background .5s linear;
}

.main-header {
  .logo__desktop{
      display: none;
  }
  h1,div.logo {
    margin: rem-calc(22 0 18);
    font-size:1em;
    text-align: center;
    @media #{$tablet-up} {
      margin: rem-calc(19 0 21);
      text-align: left;
    }
    a{
      display: inline-block;
    }
    a:hover {
      border: 0;
    }

    img {
      width: 180px;
      /*@media #{$small-up} {
        width: 293px;
      }*/

      @media #{$small-up} {
        width: 270px;
      }
      @media #{$medium-up} {
        width: 302px;
      }
      &.logo__mobil{
        max-width: 220px;
      }
    }
  }
  .cercar-mobile{
     position: absolute;
     top:20px;
     right:15px;
     z-index: 2;
     @extend .cercar-menu;
      width: 22px;
      height: 21px;
      display: inline-block;
      span{
        @include visually-hidden;
      }
     @media #{$tablet-up} {
       display:none;
    }
  }
  .eines {
    display: none;
    position:relative;
    .lang,.dreceres {
      a{
        position:relative;
        padding-right:20px;
        &:after{
          @extend .icon-desplega-menu-sup;
          position: absolute;
          content: '';
          right: 0;
          top: 50%;
          margin-top: -$icon-desplega-menu-sup-height/2;
        }
      }
    }
    .cercar{
      position:absolute;
      right:5px;
      top:70px;
      margin:0;
      @extend .cercar-menu-tablet;
      /*width: 22px;
      height: 21px;*/
      display: inline-block;
      @media #{$medium-up} {
        top:13px;
        background-position:$cercar-menu-offset-x $cercar-menu-offset-y;
      }
      span{
        @include visually-hidden;
      }
    }
  }
  .barra-tauleta{
    display: none;
  }
  @media #{$tablet-up} {
    h1,div.logo {
      width: 37%;
      float: left;
      margin-top:8px;
      margin-bottom:11px;
      line-height: 1;
      padding-top:6px;
      @media #{$medium-up} {
        padding-top:0;
        width: 40%;
        margin-bottom:10px;
      }
    }
    .mobile-menu,.logo__mobil{
      display: none;
    }
    .logo__desktop{
      display: block;
    }
    .eines {
      display: block;
      width: 40%;
      float: right;
      text-align: right;
      padding-top: 15px;
      a{
        font-size: rem-calc(12);
        text-transform: uppercase;
        margin-right: 12px;
        color: #737373;

      }
      .dreceres a{
          margin-right:0;
      }
      @media #{$medium-up} {
        width: 60%;
        padding-top: 12px;
        a{
          margin-right: 28px;
        }
        .dreceres {
          margin-right:35px;
          a{
             margin-right: 28px;
          }
        }
      }
      .lang,.dreceres {
        display: inline-block;
        margin-bottom: 0;
        ul{
          display: none;
        }
      }
      .lang a{
         text-transform: none;
      }

    }
    .barra-tauleta{
      background-color:$primary-color;
      min-height: 56px;
      margin-bottom:0;
      display:block;
      .anagrama{
        position:absolute;
        top:0;
        left:50%;
        transform: translateX(-50%);
        width:132px;
      }
      @media #{$medium-up} {
        display:none;
      }
    }
  }
  .eines-mobil{
    background-color: #464646;
    a{
      color: #FFF;
      display: block;
      padding: .5em 2.5em;
      border-bottom: solid 1px #FFF;
      position: relative;
      &.idioma,&.drecera{
        &:after{
          @extend .menu-mobile-b-nivell3;
          position: absolute;
          content: '';
          right: 5%;
          top: 50%;
          margin-top: -$menu-mobile-b-nivell3-height/2;
        }
        &.open{
          border-color: transparent;
           &:after{
            @extend .menu-mobile-b-nivell2;
            margin-top: -$menu-mobile-b-nivell2-height/2;
          }
        }
      }
    }
    .lang{
      background-color: #747474;
    }
    .desplegable{

      .main-nav__lang,.main-nav__dreceres{
        position:relative;
        background-color: #747474;
        border:none;
        border-bottom: solid 1px #FFF;

        a{
          padding: .5em 2.5em!important;
          margin: 0!important;
          border-color: transparent;
        }
      }
      .main-nav__dreceres{
        background-color: #464646;
      }
    }

  }
}
.alerta{
    &.covid {
      background-color: #908383;
      color: #fff;
      a, a:visited {
          color: #fff;
      }
      div:before {
        display: block;
        content: ' ';
        background-image: url('/media/css/img/covid.svg');
        background-size: 77px 77px;
        background-position: initial;
        height: 77px;
        width: 77px;
      }
    }

  background-color: #f5ae23;
  margin-bottom: rem-calc(20);
  div{
    @include grid-row();
    position:relative;
    min-height: 88px;
    padding:45px 20px 5px 115px;
    p{
      font-size:rem-calc(16);
      font-family: 'Roboto Slab', serif;
      font-weight: bold;
      line-height: 1.35;
    }
    @media #{$tablet-up} {
      padding-top:33px;
      p{
        font-size:rem-calc(28);
      }
    }

    &:before{
      position: absolute;
      content: '';
      left: 15px;
      top: 33%;
      width:77px;
      height: 77px;
      margin-top: -$icon-alerta-height/2;
    }

    .tancar__alerta{
      position:absolute;
      top:5px;
      right:5px;
      background-color:transparent;
      color:#000;
      font-size:rem-calc(12);
      text-transform: uppercase;
      margin:0;
      padding:5px 35px 5px 0;
      @media #{$medium-up} {
        top:12px;
      }

      &:after{
         @extend .tancar-alerta;
         content: '';
         width:23px;
         height: 23px;
         position:absolute;
         top:0;
         right: 0;

      }
    }
  }
}
.cercar-contenidor{
  background-color:$primary-color;
  background-color:rgba($primary-color,.97);
  position:fixed;
  width:100%;
  z-index:1000;
  top:0;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
  form{
    position:relative;
    height: 300px;
    display:none;
    @media #{$tablet-up} {
      padding-top: 20px;
    }
    .tancar__cercar{
      position:absolute;
      top:12px;
      right:19px;
      z-index:1001;
      background-color:transparent;
      color:$white;
      font-size:rem-calc(12);
      text-transform: uppercase;
      margin:0;
      padding:5px 35px 5px 0;
      &:after{
         @extend .tancar-alerta;
         content: '';
         width:23px;
         height: 23px;
         position:absolute;
         top:0;
         right: 0;

      }
    }
    .titolCercar {
        font-weight: normal;
        font-size: 18px;
        margin-top: 45px;
        margin-bottom: 0;
        color: $white;
        font-weight: 300;
    }
    input[type="search"] {
      font-weight: bold;
      font-size:rem-calc(28);
      background-color:transparent;
      border: 0;
      outline: 0;
      color: $white;
      width: 100%;
      padding:0.3em 0;
      margin:0;
      line-height:1.1;
      border-bottom: 2px solid $white;
      display: inline-block;
      box-shadow:none;

      @media #{$tablet-up} {
        font-size:rem-calc(60);
         height: 60px;
      }

    }
    button.boto-cercar{
      width: 100%;
      font-size:rem-calc(20);
      display: inline-block;
      color: $white;
      margin-top:35px;
      border:solid 1px $white;
      max-width:150px;
      @media #{$tablet-up} {
        font-size:rem-calc(30);
         margin-top:96px;
         height: 75px;
         max-width:350px;
      }

    }

  }
}
