.cercador{
  .contingut {
    form{
      label{
        display: none;
      }
      input{
        float:left;
        max-width:400px;
        margin-right:5px;
      }
      button{
        float:left;
        padding: .4rem;
        color:#fff;
        padding-left:2em;
        padding-right:2em;
      }
    }
    ul li{
      border-bottom: solid 1px $iron;
      padding-bottom: 2rem;
      padding-left:0;
      &:before{
        content:none;
      }
    }
    ul.inline-list{
      border:solid 1px $primary-color;
    }
    ul.inline-list li{
      border-bottom: none;
      padding-bottom: 0.1rem;
    }
    ul.inline-list a{
      border:solid 1px transparent;
    }
  }
}