.hidden {
    position: absolute;
    left: 0;
    top: -500px;
    width: 1px;
    height: 1px;
    overflow: hidden;
}
#breadcrumbs{
  @include grid-row();
  p{
     @include grid-column(12);
     font-size: rem-calc(14);
  }
}
hr.line{
  display:none;
  @media #{$tablet-up} {
    display:block;
    border-color:#c9c9c9;
    margin-top:rem-calc(13);
    margin-bottom:rem-calc(35);
  }
}
///desplegables menú accessibles
.desplegable{
  [aria-haspopup="true"] {
      position: relative;
  }
  ul[aria-hidden="true"] {
      display: none;
  }
  [aria-haspopup="true"] + ul[aria-hidden="false"] {
      margin: 0;
      display: block!important;
      position: absolute;
      z-index: 100;
      min-width:200px;
      box-shadow: 0 2px 4px rgba(0,0,0,0.2);
      background: $white;
      border: 1px solid $iron;
      border: 1px solid rgba(0,0,0,.2);
      list-style:none;
      text-align: left;
      padding:0;

  }
  &.dreceres [aria-haspopup="true"] + ul[aria-hidden="false"] {
      right: 0;
      @media #{$medium-up} {
        right: 60px;
      }
  }

  &.lang [aria-haspopup="true"] + ul li, &.dreceres [aria-haspopup="true"] + ul li {
      float: none;
      a{
        display: block;
        margin:0.5em 0;
        padding:3px 1em;
        font-size:rem-calc(16);
        text-transform: none;
        &:after{
          content: none;
        }
        &:hover{
          background-color: $secondary-color;
          color:$white;
        }
      }
  }

}

/* 3. Progressive collapsibles */
.contingut {
  .collapsible {
    border-bottom: solid 1px #979797;
    margin:2em 0;
    h3{
      margin:0;
    }
    button{
      font-size: rem-calc(17);
      margin: 0;
      border-top: solid 1px #979797;
      padding:1em 1em 1em 2.75em;
      background-color:transparent;
      outline:0;

      display: block;
      width: 100%;
      text-align: left;
      &:hover,&:active,&:focus{
        color:$body-font-color;
      }
      &:focus{
        background-color: #efefef;
      }
    }
    div{
      padding:0 1em;
    }
    h3 [aria-expanded]:before {
      @extend .acordio-desplega;
      position: absolute;
      content: '';
      left: 10px;
      top: 15px;
    }

    h3 [aria-expanded="true"]:before {
      @extend .acordio-plegar;
    }
    h3 + [aria-hidden] {
      display: none;
    }

    h3 + [aria-hidden="false"] {
      display: block;
    }
    &.fons{
      border:none;
      margin-top:1.5em;
      h3{
        margin-top:0.5em;
      }
      button{
        background-color: $light-gray;
        border:none;
        &:focus{
          background-color: $light-gray;
          outline: 1px $primary-color dashed;
        }
      }
      div{
         background-color: $light-gray;
         padding-bottom:1em;
      }
    }
  }
  h2.b + .collapsible {
    margin-top: 1em!important;
  }

}

.comparteix{
     margin-top: rem-calc(32);
    .titol{
        font-size:rem-calc(14);
        position:relative;
        padding-bottom:7px;
        margin-bottom:11px;
        &:after{
            content:'';
            position:absolute;
            bottom:0;
            left:0;
            width: 120px;
            height: 2px;
            background-color:#bbbbbb;
        }
    }
    ul{
        @include inline-list;
        li{
             padding-left:0;
            &:before{
              content: none;
            }
          }
        a{
          //@extend .logo-peu-int-facebook;
          display: inline-block;
          background-color:transparent;
          padding: 0;
          margin-top:3px;
          span{
             @include visually-hidden;
          }
          &.facebook{
            @extend .share-facebook;
          }
          &.twitter{
            @extend .share-twitter;
          }
          &.linkedin{
            @extend .share-linkedin;
          }
          &:focus{
            background-color: transparent;
            //border:solid 1px #;
            border:none;
            outline:#ffbf47 solid 3px;
          }
          &.external:after{
            content: none;
          }

        }
      }

}
.contingut #tabs, .contingut .pestanyes, .contingut .memoria, .contenidor .pestanyes {
    @include inline-list;
    border-bottom:solid 1px $primary-color;
    margin-left: 0;
    margin-bottom: rem-calc(35);
    font-size:rem-calc(15);
    @media #{$tablet-up} {
      font-size:rem-calc(16);
    }
    @media #{$large-up} {
      font-size:rem-calc(17);
    }
    a{
      display: block;
      background-color: #dddddd;
      padding:0.5em 0.25em;
      min-width:110px;
      text-align: center;
      @media #{$tablet-up} {
        padding:1em 1.25em;
      }
      color:$body-font-color;
      text-decoration: none;
      &:hover{
       background-color: darken(#dddddd, 5%);
      }
      &:focus{
        border:none;
        background-color: darken(#dddddd, 40%);
        outline:#ffbf47 solid 3px;
        color:#fff;
      }
      &.active{
        background-color: $primary-color;
        color:#fff;
      }
    }
    li{
      padding:0;
      margin-bottom:0;
      margin-right:0.25em;
      margin-left:0;

      @media #{$large-up} {
         margin-right:1em;
      }
      &.active{
        background-color: $primary-color;
        padding:0.5em 0.25em;
        min-width:110px;
        text-align: center;
        @media #{$tablet-up} {
          padding:1em 1.25em;
        }
         color:$white;
      }
       &:before{
        content: none;
      }
      &:last-child{
        margin-right:0;
      }
    }


}
.contingut .memoria{
   font-size:rem-calc(14)!important;
}
#no-items-found{
  background-color:#FFF9C4;
  padding:1em 4em;
  &:before{
    content:none;
  }
}
form{
  label{
    margin-bottom:0.25em;
  }
  textarea{
    height:200px;
    margin: 0 0 1rem;
    font-size:  rem-calc(16);
  }
  textarea[rows] {
    max-height:200px;
    margin: 0 0 1rem;
  }
  button[type=submit]{
    font-size: rem-calc(18);
  }
}
/* amagar pujar anterior */
span.pujar{
  display:none;
}
