.slider {
  .item {
    position: relative;
    div.text {
      @include grid-row();
      .box {
        background: #f0f0f0;
        padding: 28px;
        height: 350px;
      }
    }
    h2 {
      font-family: "Roboto Slab", serif;
      font-size: rem-calc(21);
      font-weight: 700;
      margin-bottom: 0.25em;
      margin-top: 1em;
    }
    p {
      font-size: rem-calc(15);
      font-weight: 300;
      color: #000;
      strong {
        display: block;
        //font-size: 1.2rem;
      }
    }
    img {
      width: 100%;
      min-height: 240px;
      /*max-height: 240px;*/
      object-fit: cover;
      object-position: right 50%;
    }
  }
}

.slider-wrapper {
  position: relative;
  margin-bottom: 2em;
  max-height: 565px;
  overflow: hidden;
  @media #{$tablet-up} {
    opacity: 0;
  }
  .prev-btn,
  .next-btn {
    position: absolute;
    top: 14%;
    z-index: 10;
    span {
      @include visually-hidden;
    }
  }
  .prev-btn {
    left: 0;
    @extend .slide-enrere-320;
  }
  .next-btn {
    right: 0;
    @extend .slide-endavant-320;
  }
  .pager-wrapper {
    top: 255px;
    position: absolute;
    color: #000;
    width: 100%;
    text-align: center;
    z-index: 10 !important;
    span {
      display: inline-block;
      width: 12px;
      height: 12px;
      background-color: #fff;
      @include radius(100%);
      text-indent: -9999px;
      box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.5);
      margin-right: 18px;
      cursor: pointer;
      &.selected {
        background-color: #90282a;
        color: $white;
      }
    }
  }
}
@media #{$tablet-up} {
  .caroufredsel_wrapper {
    max-height: 324px;
    .noticies & {
      max-height: 524px;
    }
  }
  .slider-wrapper {
    min-height: 324px;
    margin-bottom: rem-calc(36);
    .prev-btn,
    .next-btn {
      top: 50%;
    }
    .prev-btn {
      left: 5px;
      background-position: $slide-enrere-768-offset-x $slide-enrere-768-offset-y;
      margin-top: -$slide-enrere-768-height/2;
      width: $slide-enrere-768-width;
      height: $slide-enrere-768-height;
    }
    .next-btn {
      right: 5px;
      background-position: $slide-endavant-768-offset-x $slide-endavant-768-offset-y;
      margin-top: -$slide-endavant-768-height/2;
      width: $slide-endavant-768-width;
      height: $slide-endavant-768-height;
    }
    .pager-wrapper {
      left: 60px;
      top: 270px;
      width: 344px;
    }
  }
  .slider {
    max-height: 324px;
    overflow: hidden;
    height: 324px;
    min-height: 324px;
    .item {
      display: block;
      position: relative;
      float: left;
      h2 {
        margin-top: 0;
      }
      div.text {
        .box {
          position: absolute;
          top: 5%;
          width: 344px;
          background: rgba(255, 255, 255, 0.85);
          padding: 16px;
          max-height: 305px;
          height: 290px;
          left: 60px;
        }
      }
      img {
        width: 100%;
        min-height: 324px;
        max-height: 1000px;
      }
    }
  }
}
@media #{$medium-up} {
  .caroufredsel_wrapper {
    max-height: 414px;
  }
  .slider-wrapper {
    min-height: 414px;

    .prev-btn {
      background-position: $slide-enrere-offset-x $slide-enrere-offset-y;
      margin-top: -$slide-enrere-height/2;
      width: $slide-enrere-width;
      height: $slide-enrere-height;
    }
    .next-btn {
      background-position: $slide-endavant-offset-x $slide-endavant-offset-y;
      margin-top: -$slide-endavant-height/2;
      width: $slide-endavant-width;
      height: $slide-endavant-height;
    }
    .pager-wrapper {
      left: 100px;
      top: 323px;
    }
  }
  .slider {
    max-height: 414px;
    height: 414px;
    min-height: 414px;
    .item {
      div.text {
        .box {
          left: 100px;
          top: rem-calc(60);
          padding: 25px;
        }
      }
      img {
        //min-height: 414px;
      }
    }
  }
}
@media #{$xlarge-up} {
  .caroufredsel_wrapper {
    max-height: 502px;
  }
  .slider-wrapper {
    min-height: 502px;
    .prev-btn {
      left: 30px;
    }
    .next-btn {
      right: 30px;
    }

    .pager-wrapper {
      left: 15%;
      top: 320px;
    }
  }
  .slider {
    max-height: 502px;
    height: 502px;
    min-height: 502px;
    .item {
      div.text {
        .box {
          left: 15%;
          top: rem-calc(60);
        }
      }
      img {
        //MODIFICAT TEMP
        min-height: 452px;
      }
    }
  }
}
@media #{$xxlarge-up} {
  .caroufredsel_wrapper {
    max-height: 506px;
  }
  .slider-wrapper {
    min-height: 506px;

    .pager-wrapper {
      left: 20%;
      top: 358px;
      width: 384px;
    }
  }
  .slider {
    max-height: 506px;
    height: 506px;
    min-height: 506px;
    .item {
      div.text {
        .box {
          left: 20%;
          top: rem-calc(100);
          width: 384px;
        }
      }
      img {
        min-height: 506px;
      }
    }
  }
}
/* Slider notícies*/
/*.carousel-wrapper{
    overflow: hidden;
    position: relative;
    height: 525px;
    @media #{$xxlarge-up} {
      height: 495px;
    }

  }
.carousel .slide {
    display: block;
    position: relative;
    float: left;
}
.carousel .slide span {
    display: block;
    width: 90%;
    margin: 0 auto;
    max-height: 149px;
    overflow: hidden;
    position: relative;
    @media #{$tablet-up} {
      max-height: 209px;
    }
    span.more{
          position: absolute;
          top: 50%;
          left: 50%;
          background: #fff;
          width: 70px;
          height: 70px;
          border-radius: 100%;
          text-align: center;
          line-height: 70px;
          font-size: 50px;
          margin-top: -35px;
          margin-left: -35px;
    }
}
.carousel .slide .carouselContent {
    margin: 0 auto;
    width: 90%;
}
.carousel .slide .carouselContent {
    padding-top: 20px;
}
.carousel-bt{
    position: absolute;
    top: 0;
    width: 10%;
    height: 20%;
    z-index: 75;
    background-color: #fff;
    background-color: rgba(255,255,255,0.9);
    @media #{$tablet-up} {
      height: 100%;
    }
    span{
       @include visually-hidden;
    }
    &.carousel-next{
      right: 0;
      &:after{
        @extend .slide-noticia-endavant;
          position: absolute;
          content: '';
          right: 0;
          top: 15%;
          left:50%;
          margin-left: -$slide-noticia-endavant-width/2-8;
      }
      &.disabled{
          display: none!important;
        }
    }
    &.carousel-prev{
        left: 0;
        &:after{
          @extend .slide-noticia-enrera;
            position: absolute;
            content: '';
            right: 0;
            top: 15%;
            left:50%;
             margin-left: -$slide-noticia-enrera-width/2-8;
        }
        &.disabled{
          display: none!important;
        }
    }
}*

/* Nou slider notícies  i js slick*/
/* Slider */

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;

  &:focus {
    outline: none;
  }

  &.dragging {
    cursor: pointer;
    cursor: hand;
  }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;

  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }

  .slick-loading & {
    visibility: hidden;
  }
}
.carousel {
  opacity: 0;
  &.slick-initialized {
    display: block;
    opacity: 1;
  }
}
.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  [dir="rtl"] & {
    float: right;
  }
  img {
    display: block;
  }
  &.slick-loading img {
    display: none;
  }

  display: none;

  &.dragging img {
    pointer-events: none;
  }

  .slick-initialized & {
    display: block;
    opacity: 1;
  }

  .slick-loading & {
    visibility: hidden;
  }

  .slick-vertical & {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
}
.slick-arrow.slick-hidden {
  display: none;
}

.carousel__container {
  position: relative;
  overflow: hidden;
  .wrapper-slick {
    margin: 0 auto;
    max-width: 77.5rem;
    width: 100%;
    overflow: visible;
    .carousel {
      margin: 0 auto;
      overflow: visible !important;
      .slick-slide {
        transition: all ease-in-out 0.3s;
        opacity: 1;
        img {
          width: 100%;
        }
        .carouselContent {
          padding: em-calc(20) em-calc(2) 0;
          /*p{
              max-height: 145px;
              overflow: hidden;
            }*/
        }
      }
      .slick-list {
        overflow: visible;
        position: relative;
      }
    }
    .slick-slider {
      position: unset;
    }
    .slide span {
      display: block;
      width: 100%;
      margin: 0 auto;
      max-height: 149px;
      overflow: hidden;
      position: relative;
      @media #{$tablet-up} {
        max-height: 209px;
      }
      span.more {
        position: absolute;
        top: 50%;
        left: 50%;
        background: #fff;
        width: 70px;
        height: 70px;
        border-radius: 100%;
        text-align: center;
        line-height: 70px;
        font-size: 50px;
        margin-top: -35px;
        margin-left: -35px;
        border: solid 2px #fff;
        transition: all 0.3s ease-out;
        &:after {
          content: "";
          display: inline-block;
          position: absolute;
          top: 50%;
          left: 50%;
          @extend .xarxes-portada-mes;
          margin-top: -$xarxes-portada-mes-height/2;
          margin-left: -$xarxes-portada-mes-width/2;
        }
      }
    }
    .slide a.mes-noticies {
      &:hover {
        span {
          span.more {
            border-color: #c1c1c1;
          }
        }
      }
    }
  }
}
.slick-arrow {
  position: absolute;
  top: 0;
  width: 10%;
  height: 20%;
  z-index: 75;
  margin: 2px 0;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9);
  cursor: pointer;
  opacity: 1;
  @include single-transition();
  @media #{$tablet-up} {
    height: 100%;
  }
  span {
    @include visually-hidden;
  }
  &:hover,
  &:focus {
    background-color: #fff;
    background-color: rgba(255, 255, 255, 0.9);
  }
  &:focus {
    outline: 3px solid #ffbf47;
    outline-offset: 0.25rem;
    &:not(:focus-visible) {
      outline: 0;
    }
    &:focus-visible {
      outline: 3px solid #ffbf47;
      outline-offset: 0.25rem;
    }
  }
  &.slick-next {
    right: 0;
    &:after {
      @extend .slide-noticia-endavant;
      position: absolute;
      content: "";
      right: 0;
      top: 15%;
      left: 50%;
      margin-left: -$slide-noticia-endavant-width/2;
    }
  }
  &.slick-prev {
    left: 0;
    &:after {
      @extend .slide-noticia-enrera;
      position: absolute;
      content: "";
      right: 0;
      top: 15%;
      left: 50%;
      margin-left: -$slide-noticia-enrera-width/2;
    }
  }
  &.slick-disabled {
    opacity: 0;
  }
}
