.main-footer {
  background: $primary-color;
  margin-top: 3rem;
   padding: rem-calc(27) 0 0;

  p,
  ul {
    font-size: rem-calc(14);
    color:#fff;
    @media #{$small-up} {
      font-size: rem-calc(15);
    }

    @media #{$medium-up} {
      font-size: rem-calc(16);
    }


  }
  a{
    color:#fff;
  }
  .row div{
    padding-top: 10px;
    padding-bottom:30px;
  }
  .main-footer__contact{
    @include grid-column(12);
    span{
      display: block;
      margin-bottom:rem-calc(32);
    }
  }
  .main-footer__vincles{
    /*@include grid-column(12);*/
    p{
      margin-bottom:rem-calc(29);
    }
    ul{
      font-size: rem-calc(19);
      margin-left:0;
      li{
        margin-bottom: 0.75rem;
        list-style:none;
        a{
          position: relative;
          padding-left:30px;
          display: block;
          &:before{
           @extend .kland-peu;
           content: '';
           position: absolute;
           width:18px;
           height: 18px;
           top: 8px;
           left:0;
          }
        }
      }

      &.xarxes{
        @include inline-list;

        a{
          @extend .logo-peu-int-facebook;
          display: inline-block;
          background-color:transparent;
          padding: 0;
          margin-top:3px;
          span{
             @include visually-hidden;
          }
          &:before{
            content: none;
          }
          &.facebook{
            @extend .logo-peu-int-facebook;
          }
          &.twitter{
            @extend .logo-peu-int-twitter;
            margin-top: 4px;
          }
          &.instagram{
            @extend .logo-peu-int-instagram;
          }
          &.linkedin{
            @extend .logo-peu-int-linkedin;
            height: 25px;
          }
          &.youtube{
            @extend .logo-peu-int-youtube;
            margin-top: 6px;
          }
          &.altres{
            @extend .logo-peu-int-xarxes;
             margin-top: 5px;
          }
        }
      }
    }
  }
  .main-footer__logos{
      @include grid-column(12);

    }
  .copyright{
    background:#fff;
    text-align: center;
    width:100%;
    padding:20px;
    color:#000;
    font-size: rem-calc(14);
    a{
      color:#000;
    }
    span{
      padding:0 0.5em;
    }
  }
}
#flag{
  display: block;
}
/*@media #{$medium-up} {
  .main-footer {
    .main-footer__contact{
      @include grid-column(3);
    }
    .main-footer__vincles{
      @include grid-column(4);
    }
    .main-footer__logos{
      @include grid-column(5);
      text-align: right;
    }
  }
}*/