.home {
  section{
    margin-bottom:rem-calc(40);
  }
  h2{
    font-family: 'Roboto Slab', serif;
    font-size: rem-calc(30);
    font-weight: 300;
    color: #000;
    margin-bottom: rem-calc(30);
  }
  .noticies{
      h2{
         margin-bottom: rem-calc(13);
      }
      h3{
        font-size: rem-calc(18);
        font-weight: bold;
      }
      p{
        font-size: rem-calc(14);
        &.data{
          margin-bottom: rem-calc(10);
          color: $primary-color;
          span.post_type{
            display: inline;
            text-transform: uppercase;
            font-weight: bold;
            padding-left: 28px;
            margin-left: 5px;
            position: relative;
            &:after{
              content: '';
              display: block;
              position: absolute;
              top:0;
              left:0;
            }
            &.entrevista{
              //background-image: url('http://diaridigital.urv.cat/wp-content/themes/urvactiva/img/ico_entrevistes.png');
              &:after{
                @extend .ico_entrevistes;
              }
            }
            &.opinio{
              padding-left: 23px;
              //background-image: url('http://diaridigital.urv.cat/wp-content/themes/urvactiva/img/ico_opinions.png');
              &:after{
                @extend .ico_opinions;
              }
            }
          }
        }
      }
      .slide{
        a{
          color:#3a3a3a;
          display: block;
          min-height: 414px;
          margin: 2px .75em;
          @media only screen and (min-width: 1200px){
            margin: 2px .5em;
          }
          @media only screen and (min-width: 1400px){
            margin: 2px 1em;
          }
          &:hover{
            h3{color:$primary-color};
          }
          &:focus{
                outline: 3px solid #ffbf47;

          }
        }
      }

  }

  .agenda{
    background-color:$light-gray;
    padding:30px 0;
    a{
      color: #3a3a3a;
      &:hover{
          color:$primary-color;
        }
    }
    h2{
      border-bottom: solid 1px #c1c1c1;
      padding-bottom:13px;
    }
    a.totes{
      position:absolute;
      top:0;
      right:25px;
    }
    .data{
      background-color:$primary-color;
      color:#fff;
       @include grid-column(3);
       text-align: center;
       padding:10px 5px;
       @media #{$tablet-up} {
         @include grid-column(4);
         padding:10px 5px;
       }
       span{
        display: block;
        line-height: .95;
       }
       .dia__setmana{
          font-size: rem-calc(11);
          text-transform: uppercase;
          font-weight: bold;
       }
       .dia{
          font-size: rem-calc(28);
          margin:0.2em 0;
          font-weight: bold;
          @media #{$large-up} {
            font-size: rem-calc(53);
            margin:0;
          }
       }
       .mes{
          font-size: rem-calc(11);
          text-transform: uppercase;
       }
    }
    .text{
      @include grid-column(9);
      @media #{$tablet-up} {
         @include grid-column(8);
      }
    }
    h3{
      font-weight: bold;
      line-height: 1.3;
      font-size: rem-calc(18);
      @media #{$large-up} {
        font-size: rem-calc(20);
      }
    }
    p{
      font-size: rem-calc(13);
      margin:0;
    }
  }
  .xarxes{
    li{
      text-align: center;
    }
    a{
      //@extend .logo-peu-int-facebook;
      display: inline-block;
      background-color:$white;
      padding:0;
      margin-top:3px;
      width: 72px;
      height: 72px;
      position:relative;
      @include radius(100%);
      text-indent: -9999px;
      border:solid 2px $white;
      @include single-transition();
      span{
        display: inline-block;
        position:absolute;
        top:50%;
        left:50%;
      }
      &:before{
        content: none;
      }
      &:hover{
         border-color:$iron;
      }
      &.facebook{
        span{
          @extend .xarxes-portada-facebook;
          margin-top: -$xarxes-portada-facebook-height/2;
          margin-left: -$xarxes-portada-facebook-width/2;
        }
      }
      &.twitter{
        span{
          @extend .xarxes-portada-twitter;
          margin-top: -$xarxes-portada-twitter-height/2;
          margin-left: -$xarxes-portada-twitter-width/2;
        }
      }
      &.instagram{
        span{
          @extend .xarxes-portada-instagram;
          margin-top: -$xarxes-portada-instagram-height/2;
          margin-left: -$xarxes-portada-instagram-width/2;
        }
      }
      &.linkedin{
        span{
          @extend .xarxes-portada-linkedin;
          margin-top: -$xarxes-portada-linkedin-height/2;
          margin-left: -$xarxes-portada-linkedin-width/2;
        }
      }
      &.youtube{
        span{
          @extend .xarxes-portada-youtube;
          margin-top: -$xarxes-portada-youtube-height/2;
          margin-left: -$xarxes-portada-youtube-width/2;
        }
      }
      &.mes{
        span{
          @extend .xarxes-portada-mes;
          margin-top: -$xarxes-portada-mes-height/2;
          margin-left: -$xarxes-portada-mes-width/2;
        }
      }
    }
  }

}
a.totes{
    color:#000;
    font-size:rem-calc(15);
    margin:0;
    padding:0 35px 0 0;
    position:relative;
    display: inline-block;
    margin-top:18px;
    &:after{
       @extend .icon-mes;
       content: '';
       width:22px;
       height: 22px;
       position:absolute;
       top:50%;
       right: 0;
       margin-top:-$icon-mes-height/2;
    }
}
/* compartits portada i subportades */
.home, .subportada {
  .destaquem{
  	  .columns{
  	  	margin-bottom:2rem;
  	  	@media #{$tablet-up} {
  	  		margin-bottom:0;
  	  	}
  	  }
      figure {
         display: block;
         position: relative;
         border:none;
         padding:0;
         margin:0;
         overflow: hidden;
         max-width:380px;
         border-bottom: solid 7px $primary-color;
         max-height: 207px;
         @media #{$medium-up} {
           max-height: 265px;
         }
         img{
          margin:0;
          width:100%;
          -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
          transition: opacity 0.35s, transform 0.35s;
         }
         div{
            opacity: 0;
            @include single-transition(opacity, 300ms, ease-in);
            height: 100%;
            width:100%;
            background:rgba(0,0,0,.3);
            position:absolute;
            z-index: 2;
         }
      }

      h3{
        font-family: 'Roboto Slab', serif;
        font-size: rem-calc(21);
        font-weight: 700;
        margin-bottom: .25rem;
      }
      a{
        color:$body-font-color;
        &:hover{
          h3{color:$primary-color};
          img{
             -webkit-transform: scale3d(1.15,1.15,1);
            transform: scale3d(1.15,1.15,1);
          }
          div{
            opacity: 1;
          }
        }
      }
  }
  .destaquem-vincles{
    background-color:$light-gray;
    padding:85px 0 75px;
    .columns{
      margin-bottom:15px;
    }
    a{
      color:$body-font-color;
      width: 100%;
      &:hover{
        width: 100%;
        h3{color:$primary-color};
        .text{
          &:after{
          opacity: 1;
          width:60px;
          background-color: $primary-color;
          }
        }
      }
    }
    a,.columns{
      display: -webkit-flex;
      display: flex;
    }
    figure {
      width: 44px;
      border:none;
      float:left;
      margin:0;
      padding:0;
      margin-right:1em;
      flex-shrink: 0;
      @media #{$medium-up} {
        width: 88px;
      }
    }
    .text {
      -webkit-flex: 1;
      flex: 1;
      position:relative;
      min-width: 70%;
      @media #{$medium-up} {
        max-width: 72%;
      }
      &:after{
          content:'';
          height:2px;
          width:0;
          background-color: $light-gray;
          left:0;
          bottom:-10px;
          position:absolute;
          opacity:0;
          @include single-transition();
        }
      p{
        margin:0;
      }
      h3{
        font-size: rem-calc(17);
        font-weight: 700;
        margin-bottom: .25rem;
        text-transform: uppercase;
      }
      a{
        color:$body-font-color;
      }
    }
  }
  .estudia{
    .estudia-item{
      background-color:$primary-color;
      color:#fff;
      display:block;
      min-height: 300px;
      position:relative;
      margin-bottom:2em;
      h3{
        color:#fff;
        font-family: 'Roboto Slab', serif;
        font-size: rem-calc(41);
        font-weight: 700;
        line-height: 1.2;
        text-shadow: 0 2px 7px rgba(0, 0, 0, 0.5);
        position:absolute;
        bottom:26px;
        @include single-transition();
      }
      p{
        margin:0;

        visibility: hidden;
        @include single-transition();
        bottom:-126px;
        position:absolute;
        background-color:$primary-color;
        background-color:rgba($primary-color,.85);
        color:#fff;
        margin-left:-8px;
        padding-left:8px;
      }
      span{
        display: block;
      }
      div{
        background:rgba(0,0,0,.5);
        position:absolute;
        z-index: 2;
        width:100%;
        height: 100%;
        top:0;
        left:0;
        padding:26px;
        min-height: 300px;
        @include single-transition();
        overflow:hidden;
      }
      &.vine{
        background:url('img/vine-urv.jpg') no-repeat 0 0;
        background-size:cover;
      }
      &.perque{
        background:url('img/perque-urv.jpg') no-repeat 0 0;
        background-size:cover;
      }
      &.vies{
        background:url('img/vies-acces.jpg') no-repeat 0 0;
        background-size:cover;
      }
      &.coneix{
        background:url('img/coneix-urv.jpg') no-repeat 0 0;
        background-size:cover;
      }
      &:hover{
        div{
            background:rgba(0,0,0,.1);
        }
        p{
          visibility: visible;
          bottom:12px;
        }
        h3{
           bottom:92px;
        }
      }
    }
  }
}

.home .noticies.noticies-mobil{
  article{
    border-bottom: solid 1px #c1c1c1;
    margin-bottom: rem-calc(14);
    padding-bottom: rem-calc(14);
  }
  h3{
    font-size: rem-calc(18);
    margin:0;
  }
  a{
    color: $body-font-color;
    &.totes{
      padding-left:35px;
      margin:0;
      &:after{
        left:0;
      }
    }
  }
  p{
    margin-bottom:0;
    margin-top:rem-calc(14);
    &.data{
      margin-top:rem-calc(5);
    }
  }
  img{
     margin-bottom:rem-calc(14);
  }
}
