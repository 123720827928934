/* sub-navigation panel */
.section-nav {
   display:none;
   @media #{$tablet-up} {
     display:block;
     @include grid-row();
     background-color:$white;
     margin-top: -20px;
     ul{
        @include inline-list;
        @include grid-column(12);
        margin:0;
        li{
          margin:rem-calc(3) 0 rem-calc(10);
          a{
            position:relative;
            padding:rem-calc(2 22 4);
            color: #4a4a4a;
            @include single-transition(color, 300ms, ease-in);
            &:before{
              content: '';
              height:100%;
              width: 1px;
              background-color:#979797;
              position:absolute;
              left:0;
              top:0;
            }
            &.active{
              background-color:$primary-color;
              color:#fff !important;
              &:before{
                background-color:$primary-color;
              }
            }
            &:hover{
               color:$primary-color;
            }
          }
          &.active:first-child{
            a.active{
              background-color:#fff;
              color:#4a4a4a !important;
              &:before{
                background-color:#979797;;
              }
            }
          }
          &.isIndex.active{
            a.active{
              background-color:$primary-color;
              color:#fff !important;
              &:before{
                background-color:$primary-color;
              }
            }
          }
          &:last-child a{
              margin-right:0;
              &:after{
                content: '';
                height:100%;
                width: 1px;
                background-color:#979797;
                position:absolute;
                right:0;
                top:0;
              }
              &.active{
                &:after{
                  background-color:$primary-color;
                }
              }
            }
        }
     }
  }
  ul li a{
    &.external{
      span{
          @extend .vincle-extern;
          margin-left:10px;
          display: inline-block;
          margin-top: 0.21em;
      }
    }
    &.privat,&.privat.external{
      span{
          @extend .candau;
          margin-left:10px;
          display: inline-block;
          margin-top: 0.215em;
      }
    }

  }

}



