// imatge esquerra/dreta
.img-left,
.txtImgEsq,
.img-right,
.txtImgDr {
  img {
    margin-bottom: $paragraph-margin-bottom;
  }
}
@media #{$tablet-up} {
  .img-left,
  .txtImgEsq {
    img {
      float: left;
      margin-right: $column-gutter/2;
      min-width: 148px;
    }
  }

  .img-right,
  .txtImgDr {
    img {
      float: right;
      margin-left: $column-gutter/2;
      min-width: 148px;
    }
  }
}

//images amb flexbox per  ten paràgrafs de continuació
@media #{$medium-up} {
  .img-left {
    display: flex;
    .image {
      flex-shrink: 0;
      /*img {
        width: 100%;
      }*/
    }
    .text {
      padding-left: 0.75em;
    }
  }
}
//vincles
a:focus {
  outline: 3px solid #ffbf47;
  outline-offset: 0.25rem;
  &:not(:focus-visible) {
    outline: 0;
  }
  &:focus-visible {
    outline: 3px solid #ffbf47;
    outline-offset: 0.25rem;
  }
}

.contingut,
aside {
  a {
    text-decoration: underline;
    word-break: break-word;
    &:hover {
      color: $body-font-color;
    }
    &:visited {
      color: darken($primary-color, 12%);
      // outline:0;
    }
    &:focus,
    &:active {
      background-color: $primary-color;
      color: #fff;
      // outline:0;
      border-top: 1px solid darken($primary-color, 7%);
      border-bottom: 1px solid darken($primary-color, 7%);
    }

    &.external {
      position: relative;
      &:after {
        @extend .vincle-extern;
        display: inline-block;
        content: "";
        margin-right: 5px;
        margin-left: 5px;
      }
    }
    &.privat {
      position: relative;
      white-space: nowrap;
      &:after {
        @extend .candau;
        display: inline-block;
        content: "";
        margin-right: 5px;
        margin-left: 5px;
      }
    }
  }
  // paragraf alerta
  .alerta {
    padding: rem-calc(25);
    margin: rem-calc(30) 0 rem-calc(40);
  }
  .introImg {
    background-color: #dddddd;
    margin-bottom: rem-calc(35);
    &.collapse {
      margin-bottom: rem-calc(35);
    }
    .text {
      padding: 2em 2em 1em;
    }
    img {
      width: 100%;
    }
    @media #{$tablet-up} {
      .imatge {
        @include grid-column(5);
        padding: 0;
        overflow: hidden;
        img {
          min-height: 275px;
          max-width: 800px;
          width: auto;
        }
      }
      .text {
        @include grid-column(7);
        padding: 2em 2em 1em;
      }
    }
    @media #{$xlarge-up} {
      .imatge {
        img {
          min-height: 254px;
        }
      }
    }
  }
  &.medium-12 .introImg {
    @media #{$tablet-up} {
      .imatge {
        @include grid-column(4);
        padding: 0;
        overflow: hidden;
      }
      .text {
        @include grid-column(8);
        padding: 2em 2em 1em;
      }
    }
  }
  //ul:not(.pagination,.related-links-list) {
  ul {
    li {
      &.nom {
        position: relative;
        margin-bottom: 0.5em;
        margin-top: 0.5em;
        line-height: 1.4;
        padding-left: 1.75em;
        list-style: none;
        &:before {
          @extend .icon-fitxa-usuari;
          content: "";
          display: block;
          position: absolute;
          top: 0.2em;
          left: 2px;
        }
        a {
          display: inline;
          border: none;
          padding: 0;
          color: $primary-color;
          text-decoration: underline;
          &:focus {
            color: #fff;
          }
        }
      }
      &.adr {
        position: relative;
        margin-bottom: 0.5em;
        margin-top: 0.5em;
        line-height: 1.4;
        padding-left: 1.75em;
        list-style: none;
        &:before {
          @extend .icon-fitxa-adressa;
          content: "";
          display: block;
          position: absolute;
          top: 0.2em;
          left: 0;
        }
        a {
          display: inline;
          border: none;
          padding: 0;
          color: $primary-color;
          text-decoration: underline;
          &:focus {
            color: #fff;
          }
        }
      }
      &.email {
        position: relative;
        margin-bottom: 0.5em;
        margin-top: 0.5em;
        line-height: 1.4;
        padding-left: 1.75em;
        list-style: none;
        &:before {
          @extend .icon-fitxa-mail;
          content: "";
          display: block;
          position: absolute;
          top: 0.2em;
          left: 0;
        }
        a {
          display: inline;
          border: none;
          padding: 0;
          color: $primary-color;
          text-decoration: underline;
          &:focus {
            color: #fff;
          }
        }
      }
      &.fax {
        position: relative;
        margin-bottom: 0.5em;
        margin-top: 0.5em;
        line-height: 1.4;
        padding-left: 1.75em;
        list-style: none;
        &:before {
          @extend .icon-fitxa-fax;
          content: "";
          display: block;
          position: absolute;
          top: 0.2em;
          left: 0;
        }
        a {
          display: inline;
          border: none;
          padding: 0;
          color: $primary-color;
          text-decoration: underline;
          &:focus {
            color: #fff;
          }
        }
      }
      &.tel {
        position: relative;
        margin-bottom: 0.5em;
        margin-top: 0.5em;
        line-height: 1.4;
        padding-left: 1.75em;
        list-style: none;
        &:before {
          @extend .icon-fitxa-telefon;
          content: "";
          display: block;
          position: absolute;
          top: 0.2em;
          left: 2px;
        }
        a {
          display: inline;
          border: none;
          padding: 0;
          color: $primary-color;
          text-decoration: underline;
          &:focus {
            color: #fff;
          }
        }
      }
      &.video {
        position: relative;
        margin-bottom: 0.5em;
        margin-top: 0.5em;
        line-height: 1.4;
        padding-left: 1.75em;
        list-style: none;
        &:before {
          @extend .icon-fitxa-video;
          content: "";
          display: block;
          position: absolute;
          top: 0.2em;
          left: 0;
        }
        a {
          display: inline;
          border: none;
          padding: 0;
          color: $primary-color;
          text-decoration: underline;
          &:focus {
            color: #fff;
          }
        }
      }
    }
    &.contacte {
      padding: 1em;
      box-shadow: 0 0 2px rgba(0, 0, 0, 0.15), 0 2px 2px rgba(0, 0, 0, 0.2);
    }
  }
}
// llistes
.contingut {
  //ul:not(.pagination,.related-links-list) {
  ul {
    list-style: none;
    margin-left: 0;

    li {
      position: relative;
      padding-left: 1.3em;
      margin-bottom: 0.5em;
      margin-top: 0.5em;
      line-height: 1.4;
      &:before {
        content: "";
        display: block;
        @extend .kland-llistat-nivell-1;
        position: absolute;
        left: 0;
        top: 0.375em;
      }

      p {
        margin-bottom: 0;
      }

      // subllista 1
      ul {
        margin-left: 0;
        li {
          &:before {
            @extend .kland-llistat-nivell-2;
          }

          p {
            font-size: inherit;
          }

          // subllista 2
          ul {
            margin-bottom: 1em;
            margin-top: 0;
            font-size: rem-calc(15);
            li {
              padding-left: 1em;
              &:before {
                top: 0.55em;
                @extend .kland-llistat-nivell-3;
              }

              p {
                font-size: inherit;
              }
            }
          }
        }
      }
    }

    // llistat dues columnes
    &.duesCol {
      @media #{$medium-up} {
        @include block-grid(2);
        margin: 0 0 $paragraph-margin-bottom 0;

        li {
          padding-left: 1.3em;
          padding-bottom: 0;
        }
      }
    }

    // llista amb fons de color
    &.fons,
    &.llista_fons {
      margin-left: 0;

      li {
        background-color: $light-gray;
        padding: 0.5em 35px;
        margin-bottom: 0.5em;

        &:before {
          top: 0.9em;
          left: 14px;
        }
      }
    }
    &.tipusA {
      background-color: $light-gray;
      //border:solid 1px $primary-color;
      //box-shadow: 0 0 2px rgba(0,0,0,0.15),0 2px 2px rgba(0,0,0,0.2);
      box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);
      padding: 1em 2em;
    }
    &.tipusB {
      border: solid 1px $primary-color;
      padding: 1em 2em;
    }
    &.contacte {
      padding: 1em 2em;
    }

    // llista separadors
    &.llista_separadors {
      margin-left: 0;

      li {
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        border-bottom: 1px dotted $secondary-color;
        margin-bottom: 0;

        &:before {
          top: 1.05em;
        }
      }
    }

    // llista caixa
    &.llista_caixa {
      margin-left: 0;
      background: $secondary-color;
      padding: 8px;

      li {
        padding: 0.5em 30px;
        margin-bottom: 0.55em;
        background-color: $white;

        &:last-child {
          margin-bottom: 0;
        }

        &:before {
          top: 1.05em;
          left: 14px;
        }

        &.caixaTitol {
          color: $white;
          background: $primary-color;
          color: $white;
          font-size: rem-calc(17);
        }
      }
    }
  }

  ol {
    margin-left: $column-gutter;
  }
}

// botons
.btn,
.button {
  @include button($padding: $button-sml);

  a {
    color: $white;
    text-decoration: none;
  }
}

a.button {
  text-decoration: none;
}

// baixar arxiu
.dl {
  background: $secondary-color;
  padding: 0.5em 1em;
  border-radius: 12px;

  a {
    @include hover;
  }

  &:before {
    @extend .icon-download;
    content: "";
    display: inline-block;
    vertical-align: text-bottom;
    margin-right: 10px;
  }
}

// vincle destacat
.dest {
  background: $secondary-color;
  padding: 0.5em 1em;
  border-radius: 12px;

  a {
    @include hover;
  }

  &:before {
    content: "";
    display: inline-block;
    margin-right: 5px;
    width: 10px;
    height: 10px;
    background: $primary-color;
    border-radius: 3px;
  }
}

// paragraf destacat
.destacat {
  position: relative;
  font-style: italic;
  padding: rem-calc(28) 0;
  border-bottom: solid 7px $primary-color;
  margin: rem-calc(30) 0 rem-calc(40);

  background-color: #fff;
  p {
    font-size: rem-calc(24);
  }
  &:before {
    @extend .destacat-puntets;
    position: absolute;
    content: "";
    left: 5px;
    top: 0;
  }
}
.destacatDr {
  @extend .destacat;
  float: right;
  margin: rem-calc(0 0 50 40);
  max-width: 250px;
  p {
    font-size: rem-calc(18);
    @media #{$medium-up} {
      font-size: rem-calc(20);
    }
    @media #{$large-up} {
      font-size: rem-calc(24);
    }
  }
  @media #{$large-up} {
    max-width: 400px;
  }
}
.destacatEsq {
  @extend .destacat;
  float: left;
  margin: rem-calc(0 40 50 0);
  max-width: 250px;
  p {
    font-size: rem-calc(18);
    @media #{$medium-up} {
      font-size: rem-calc(20);
    }
    @media #{$large-up} {
      font-size: rem-calc(24);
    }
  }
  @media #{$large-up} {
    max-width: 400px;
  }
}
aside {
  .destacatDr,
  .destacatEsq {
    float: none;
    margin-top: 0;
    margin: rem-calc(0 0 55 0);
  }
}
//paràgraf enllaça pdf
.pdf_gran_nomargin {
  a {
    position: relative;
    display: inline-block;
    padding: 1.5em 1.5em 1.5em 4.25em;
    border-top: solid 2px $iron;
    border-bottom: solid 2px $iron;
    &:before {
      @extend .icon-programa-pdf;
      position: absolute;
      content: "";
      left: 1em;
      top: 50%;
      margin-top: -$icon-programa-pdf-height/2;
    }
    &:hover {
      background-color: $light-gray;
      color: $primary-color;
    }
  }
}
.pdf_gran_nomargin + .pdf_gran_nomargin {
  a {
    border-top: none;
    margin-top: -1.25rem;
  }
}
// paragraf caixa destacada
.caixa-destacada {
  background-color: $light-gray;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);
  padding: rem-calc(20);
  margin-bottom: rem-calc(40);
  strong,
  b {
    font-family: "Roboto Slab", serif;
    font-size: rem-calc(18);
    margin-bottom: rem-calc(10);
    display: inline-block;
  }
  img {
    margin-bottom: rem-calc(15);
    display: block;
  }
  a {
    text-decoration: underline;
    &:focus {
      border: none;
    }
  }
  p {
    margin-bottom: 0;
  }
}
.link_destacat {
  border-radius: 12px;
  background-color: $light-gray;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);
  padding: 0.5em 1em;
  img {
    margin-right: 15px;
  }
}
.contingut ul li p.link_destacat {
  margin: 0.75rem 0;
}
.primer {
  position: relative;
  padding-left: 18px;
  &:before {
    @extend .kland-llistat-nivell-1;
    position: absolute;
    content: "";
    left: 0;
    top: 8px;
  }
}
.contingut {
  .caixa-destacada {
    @include grid-row();
    margin: rem-calc(30) 0 rem-calc(40);
    @media #{$tablet-up} {
      img {
        float: left;
        width: 35%;
        display: block;
      }
      img + p {
        float: right;
        width: 65%;
        display: block;
        padding-left: 30px;
      }
    }
  }
  //llistats caixes destacades
  .caixa {
    @include block-grid(1);
    @media #{$tablet-up} {
      @include block-grid(2);
    }
    li {
      &:before {
        content: none;
      }
      div {
        @extend .caixa-destacada;
        margin: 0;
        img {
          display: block;
          width: 100%;
          float: none;
        }
        h2 {
          border: none;
          font-size: rem-calc(18);
          font-weight: 700;
          margin-bottom: 0.5em;
          padding: 0;
        }
      }
    }
    &.caixa-3 {
      @media #{$tablet-up} {
        @include block-grid(3);
      }
    }
    &.caixa-4 {
      @media #{$tablet-up} {
        @include block-grid(3);
      }
      @media #{$medium-up} {
        @include block-grid(4);
      }
    }
  }
}
@media #{$large-up} {
  .contingut {
    padding-right: 35px;
  }
  .medium-10.contingut.medium-centered,
  .large-12.contingut {
    padding-right: 0.9375rem;
  }
}
@media #{$xlarge-up} {
  .contingut {
    padding-right: 45px;
  }
  .medium-10.contingut.medium-centered,
  .large-12.contingut,
  .medium-12.contingut {
    padding-right: 0.9375rem;
  }
}

.contingut ul.botons-accio {
  @include block-grid(1);
  li {
    &:before {
      content: none;
    }
    a {
      display: block;
      background-color: #f0f0f0;
      box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);
      padding: 0 1em;
      margin: 3px 0;
      font-family: "Roboto Slab", serif;
      text-decoration: none;
      font-size: rem-calc(19);
      font-weight: 700;
      min-height: 88px;
      line-height: 1.2;
      position: relative;
      @include single-transition();
      &:before {
        display: block;
        content: "";
        position: absolute;
        top: 50%;
        left: 14px;
      }
      &:hover {
        background-color: lighten(#f0f0f0, 4%);
      }
      &:focus {
        border: none;
        color: #000;
        outline: #ffbf47 solid 3px;
      }
      span {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        display: block;
        padding: 0;
      }
      &.external {
        &:after {
          content: none;
        }
        span {
          max-width: 278px;
          &:after {
            @extend .vincle-extern;
            position: absolute;
            content: "";
            margin-left: 10px;
            display: inline-block;
            margin-top: 0.23em;
          }
        }
      }
    }
    &.professorat {
      a {
        padding-left: 72px;
        &:before {
          @extend .icon-professorat;
          margin-top: -$icon-professorat-height/2;
        }
      }
    }
    &.qualitat {
      a {
        padding-left: 73px;
        &:before {
          @extend .icon-qualitat;
          margin-top: -$icon-qualitat-height/2;
        }
      }
    }
    &.programa {
      a {
        padding-left: 64px;
        &:before {
          @extend .icon-programa-pdf;
          margin-top: -$icon-programa-pdf-height/2;
        }
      }
    }
  }
  @media #{$tablet-up} {
    @include block-grid(3);
  }
  &.b {
    @media #{$tablet-up} {
      @include block-grid(2);
    }
  }
}
.separacio-inferior {
  margin-top: 3em;
}
/* compatibilitat estils anteriors*/

.peuFoto {
  line-height: 1.2;
  font-size: 0.8em;
  padding-top: 0.8em;
  color: #777;
}
table {
  width: 100%;
}
/* contacte
---------------------------------------------*/
.resposta {
  background-color: $light-gray;
}
#contacte {
  background: url(img/missatge_enviat_ok.jpg) no-repeat;
  padding-left: 100px;
  margin: 0 auto 1.125em;
  max-width: 600px;
}
#contacte h3 {
  font-weight: normal;
  font-size: 1.3125em;
  background: none;
  padding: 0;
  color: #216824;
  margin: 0 0 0.5em;
}
#contacte p {
  margin: 0 0 0.5em;
}
#contacte.error {
  background-image: url(img/missatge_enviat_error.jpg);
}
#contacte.error h3 {
  color: #ad231c;
}
