.subportada{
  .contingut .intro{
    padding:25px 25px 30px;
    text-align: center;
    p{
      font-size: rem-calc(20);
      line-height: 1.5;
    }
  }
  .caixes{
    overflow: hidden;
    @include block-grid(1);
    @media screen and (min-width: 35em) {
      @include block-grid(2);
    }
    @media #{$medium-up} {
      @include block-grid(3);
    }

    a{
      display: block;
       max-width:380px;
       overflow: hidden;
       &:hover{
          div{
            opacity: 1;
            span{
              top: 50%;
               opacity: 1;
            }
          }
       }
    }
    figure {
       display: block;
       position: relative;
       border:none;
       padding:0;
       margin-bottom:0.5em;
       overflow: hidden;
       max-width:380px;
       img{
        margin:0;
        width:100%;
        -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
        transition: opacity 0.35s, transform 0.35s;
       }
       div{
          opacity: 0;
          @include single-transition(opacity, 300ms, ease-in);
          height: 100%;
          width:100%;
          background:rgba(0,0,0,.3);
          position:absolute;
          z-index: 2;
          color:#fff;
          span{
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 3;
            //@include single-transition(all, 600ms, ease-in);
            transform: translate(-50%, -50%);
            font-size:18px;
            line-height: 1;
            border:solid 1px $white;
            padding:0.5em 1em;
            max-width:60%;
            opacity: 0;
            text-align: center;
            color:$white;
             //@extend .slide-noticia-endavant;
          }

       }

    }
    figcaption {
        position: absolute;
        bottom: -3px;
        background: white;
        z-index: 3;
        max-width: 90%;

    }
    h2{
      font-family: 'Roboto Slab', serif;
      font-size: rem-calc(20);
      font-weight:700;
      margin:0;
      padding:0.5em 1em 0.5em 0;
      color: $primary-color;
      @media #{$large-up} {
        font-size: rem-calc(25);
      }
    }
    p{
      font-size: rem-calc(15);
      color: $body-font-color;
    }


    a img
     {
      -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
      transition: opacity 0.35s, transform 0.35s;
    }
    a:hover img {
      -webkit-transform: scale3d(1.15,1.15,1);
      transform: scale3d(1.15,1.15,1);
    }
    &.b{
      h2{
          font-size: rem-calc(16);
          line-height: 1.21;
        }
      @media #{$medium-up} {
        @include block-grid(4);
        h2{
          font-size: rem-calc(19);
        }
      }
      &.c{
        @media #{$medium-up} {
          @include block-grid(5);
        }
      }
    }
  }
  .noticies-relacionades{
    border-top: solid 2px #c8c8c8;
    padding-top:rem-calc(40);
    position:relative;
    h2{
      font-family: 'Roboto Slab', serif;
      font-weight: 300;
      font-size:rem-calc(30);
    }
    h3{
      font-weight: 700;
      font-size:rem-calc(18);
       color: #3a3a3a;
       padding-right:8px;
    }
    img{
      margin-bottom: 15px;
    }
    a{
      color:$body-font-color;
    }
    p{
      font-size:rem-calc(15);
      padding-right:8px;
    }
    .totes{
      position: absolute;
      right:0;
      top:rem-calc(40);
    }
    ul{
      @include block-grid(1);
      @media #{$tablet-up} {
        @include block-grid(3);
      }
      @media #{$medium-up} {
        @include block-grid(5);
      }
    }
  }
  .caixes-colors{
    @include block-grid(1);
    @media #{$tablet-up} {
      @include block-grid(3);
    }
    &.b{
       @media #{$medium-up} {
        @include block-grid(4);
      }
    }
    &.c{
       @media #{$medium-up} {
        @include block-grid(5);
      }
    }

    a{
      display: block;
      color:$white;
      position:relative;
      padding:40px 20px;

       width: 100%;
       overflow:hidden;
      @include single-transition();
      background-color:$primary-color;
      text-align: center;
      font-family: 'Roboto Slab', serif;
      @media #{$tablet-up} {
          min-height: 200px;
      }
      &:before{
        content:'';
        position:absolute;
        top:0;
        left:0;
        width:1%;
        height: 4px;
        @include single-transition();
        background-color:$primary-color;
      }
      &:after{
        content:'';
        position:absolute;
        bottom:0;
        left:101%;
        width:100%;
        height: 4px;
        @include single-transition();
        background-color:$primary-color;
      }
      span{
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 2;
        width:100%;
        font-size: rem-calc(20);
        font-weight: 700;
        line-height: 1.3;
        transform: translate(-50%, -50%);
        padding: 0 1em;
        @media #{$medium-up} {
          font-size: rem-calc(23);
        }
        @media #{$large-up} {
          font-size: rem-calc(27);
        }
      }
    }
    li{
      &:nth-child(8n+2) a{
         //background-color:#c98c49;
         background-color:#C6853E;
         &:before,&:after{
          background-color:#C6853E;
         }
      }
       &:nth-child(8n+3) a{
         background-color:#cf613a;
         &:before,&:after{
            background-color:#cf613a;
         }
      }
       &:nth-child(8n+4) a{
         background-color:#883057;
         &:before,&:after{
          background-color:#883057;
         }
      }
       &:nth-child(8n+5) a{
         background-color:#4d355b;
         &:before,&:after{
          background-color:#4d355b;
         }
      }
       &:nth-child(8n+6) a{
         background-color:#0e9698;
         &:before,&:after{
          background-color:#0e9698;
         }
      }
      &:nth-child(8n+7) a{
         //background-color:#82bb93;
         background-color:#559D6A;
         &:before,&:after{
          background-color:#559D6A;
         }
      }
      &:nth-child(8n+8) a{
        background-color:#31446b;
        &:before,&:after{
          background-color: #31446b;
        }
      }
      a:hover{
        background-color:$white;
        color:$black;
        &:before{
          width:100%;
        }
        &:after{
          left:0;
        }
      }
    }
  }
}