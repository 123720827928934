.master{
  &.inici{
    @media #{$tablet-up} {
      .main-footer{
        margin-top: 0;
      }
    }
  }
  &.subportada{
    .caixes.b{
      h2{
        margin-bottom: 0;
      }
    }
  }
  &.master-portada{
    .main-footer{
      margin-top: 0;
    }
  }
  ul.tabs{
      margin:0;
      li{
        display: inline;
        margin-left: 0;
        margin-right: .5rem;
        a{
          display: inline-block;
          border-top-left-radius: 6px;
          border-top-right-radius: 6px;
          background-color: #d6d6d6;
          font-weight: bold;
          //font-size: rem-calc(12);
          font-size: rem-calc(14);
          padding:.75rem .75rem 1rem;
          text-align: center;
          line-height:1.2;
          //width: 75px;
          color:#000;
          transition: all .3s;
          @media #{$tablet-up} {
            font-size: rem-calc(16);
            padding:.75rem 3rem 1rem;
            min-width: 200px;
            margin-bottom:-6px;
            line-height:1.6;
          }
          @media #{$large-up} {
            min-width: 300px;
            font-size: rem-calc(18);
          }
          &.active {
            background-color: #fff!important;
          }
          &:hover{
            background-color: darken(#d6d6d6,5%);
            @media #{$tablet-up} {
               box-shadow: -1px 5px 25px -5px rgba(0,0,0,0.5);
              transform: translate3d(0,-2px,0);

            }
          }
        }
        &:first-child{
          a{
            width:100px;
            @media #{$tablet-up} {
               width:300px;
            }
          }
        }
      }
    }

  .main-titol{
    //min-height: 450px;
    /*background-position: 0 0;
    background-repeat: no-repeat;
    background-size: cover;*/
     background-color: #90282a;
    background-image: linear-gradient(to bottom, #90282a, #c14e50);
    margin:0;
    position:relative;
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: cover;


    .slider-wrapper {
      display:none;
    }

    @media #{$tablet-up} {
      //max-height: 260px;
      //min-height: 260px;
      overflow:hidden;
      .slider-wrapper {
        display:block;
        /*max-height: 300px!important;
        position:absolute;
        top:0;
        right:0;
        width:50%;
        height:100%!important;
        margin:0;
        overflow:hidden;*/
        margin:0;
         min-height: 300px!important;
        img{
          min-height: 300px!important;
          min-width:620px!important;
          max-width:620px!important;
        }
        .caroufredsel_wrapper,.slider {
          max-height: 300px!important;
          //min-height: 300px!important;
        }
        .pager-wrapper{
          top: auto;
          bottom:20px!important;
          left:50%;
          transform: translateX(-50%);
        }
      }
    }
    @media #{$medium-up} {
      //min-height: 350px;
      //max-height: 350px;
      min-height: 300px;
      .slider-wrapper {
          max-height: 300px!important;
          //height:250px!important;
          //min-height: 300px!important;
          img{
            min-height: 300px!important;
            min-width:620px!important;
            max-width:620px!important;
          }
          .caroufredsel_wrapper,.slider {
            max-height: 300px!important;
            //min-height: 300px!important;
          }
      }
    }
    @media only screen and (min-width: 1250px) {
      .slider-wrapper {
          img{
            min-height: 300px!important;
            min-width:300px!important;
            max-width:620px!important;
          }
      }
    }
    @media only screen and (min-width: 1441px) {
      min-height: 300px;
      max-height: 300px;
      .slider-wrapper {
          max-height: 300px!important;
          height:300px!important;
          min-height: 300px!important;
          img{
            min-height: 300px!important;
            min-width:300px!important;
            max-width:620px!important;
          }
          .caroufredsel_wrapper,.slider {
            max-height: 300px!important;
            min-height: 300px!important;
        }
      }
    }
    @media #{$xxlarge-up} {
      .slider-wrapper {
          img{
            min-height: 300px!important;
            min-width:300px!important;
            max-width:620px!important;
          }
      }
    }
    /*.overlay{
      background: rgba(0,0,0,.5);
      width: 100%;
      height: 100%;
      position: absolute;
      @media #{$medium-up} {
         width: 55%;
        min-width: 600px;
        background: rgba(0,0,0,.8);
      }
    }*/
    h1{
      max-width: 500px;
      margin: 0;
      font-size: rem-calc(26);
      padding: 2.8125rem .9375rem;
      line-height:1.3;
      @media #{$large-up} {
         font-size: rem-calc(28);
      }
      @media only screen and (min-width: 1450px){
        font-size: rem-calc(32);
      }


      span{
          font-weight:300;
        }
    }
  }
  .section-nav{
    margin: 0;
    max-width: 100%;
    background-color: #515151;
    display: block;
    padding: 1em 0;

    @media #{$medium-up} {
      padding: 0;
    }
    h2{
      color: #fff;
    }
    ul{
      padding: 0 ;
      margin:0;
      @media #{$small-only} {
        li{
          width: 50%;
          display: block;
          float: left;
        }
      }
      li{
          padding: 0 ;
          margin:0 0 .5em;
          color: #fff;
          @media #{$medium-up} {
            margin:0;
            //height: 93px;
            overflow: hidden;
            text-align: center;
            /*&.viure{
              max-width: 120px;
            }
            &.faq{
              max-width: 95px;
            }*/
          }
          @media #{$large-up} {
            //height: 88px;
            /*&.viure{
              max-width: 130px;
            }
            &.faq{
              max-width: 110px;
            }*/
          }
          @media only screen and (min-width: 1200px){
            /*min-width: 140px;

            &.viure{
              max-width: 190px;
            }*/
          }
          a{
            color:#fff;
            font-family: 'Roboto Slab', serif;
            display: block;
            font-size: rem-calc(17);
            padding: .3em .85em;
            line-height:1.2;
            @media #{$medium-up} {
              padding: 2.5em 1.2em;
              font-size: rem-calc(15);
              span{
                display: block;
              }
              &.b{
                padding-top: 2em;
                padding-bottom: 1.8em;
              }
            }
            @media only screen and (min-width: 1090px){
              font-size: rem-calc(16);
            }
            @media only screen and (min-width: 1220px){
              padding: 2em 1.5em;
              font-size: rem-calc(17);
              &.b{
                padding-top: 1.45em;
                padding-bottom: 1.4em;
              }
            }

            @include single-transition();
            &:hover{
              background-color: #414141;
            }
            &:before{
              content: none;
            }
            &:focus{
      outline:0;
    }
          }
          &.active{
            a{
              background-color: #c14e50;
            }
          }
          &:last-child{
            a:after{
              content: none;
            }
          }
      }
    }

  }

  .contingut{
    margin-top:3em;
    &.b{
      margin-top:0;
    }
    .intro p{
      font-size: rem-calc(18);
    }
    ul.logos{
      @include inline-list;
      li{
        padding-left: 0;
        height: 80px;
        line-height: 80px;

        &:before{
          content: none;
        }
        img{
          max-width: 200px;
          max-height: 80px;
          display: inline-block;
          vertical-align: middle;
        }
        a{
          @include single-transition();
          display: block;
          padding: 8px;
          margin-left: -8px;
          &:active,&:focus{
            background-color: #fff;
            color: #fff;
            border:none;
            transform: translate3d(0,-2px,0);
            box-shadow: 0 2px 5px 0 rgba(0,0,0,.26);
          }

        }
      }
      &.segells{
        li{
          height: auto;
          img{
            //max-width: 300px;
            max-height: 150px;
          }
        }
      }
    }
  }
  .institucions{
    h2{
      font-family: Lato,sans-serif;
      //margin:0;
      font-size: rem-calc(16);
      font-weight: bold;
    }
    p{
      font-size: rem-calc(16);
      line-height: 1.4;
      a{
        text-decoration: none;
        &:hover{
          text-decoration: underline;
          color:$primary-color;
        }
      }
    }
  }
  hr.line{
    margin:1.5em 0 2em;
    display: block;
    @media #{$medium-up} {
      margin:2.5em 0 3em;
    }
  }

  .btn{
    border-radius: 6px;
    border: solid 1px $primary-color;
    background-color: #fff;
    color: $primary-color;
    @include single-transition();
    font-size: rem-calc(14);
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none;
    padding: .75em 2em;
    &:hover{
      color: #fff;
      background-color: $primary-color;
    }
    &--b{
       background-color:#707070;
       border-color: transparent;
        color:#fff;
    }
  }


  .btn-preinscripcio-master{
    background-color: $primary-color;
    color:#fff;
    font-size: rem-calc(14);
    display: block;
    border-radius: 6px;
    text-transform: uppercase;
    max-width: 100%;
    text-align: center;
    margin: 0;
    border:solid 1px transparent;
    @include single-transition();
    font-weight: bold;
    padding: .5rem .75rem;
    line-height: 1.2;
    @media #{$xlarge-up} {
      font-size: rem-calc(18);
      padding: 1rem;
    }
    &:hover{
      background-color: #fff;
      border-color: $primary-color;
      color:$primary-color;
      box-shadow: 0 2px 5px 0 rgba(0,0,0,.26);
    }
  }
  .btn-pregunta{
    padding: .5rem .75rem;
    font-size: rem-calc(14);
    display: block;
    margin:0;
    text-align: center;
    line-height: 1.2;
    color:#fff;
    border-color:#707070;
    background-color:#707070;
    font-weight:normal;

    @media #{$xlarge-up} {
      font-size: rem-calc(18);
      padding: 1rem;
      display: block;
    }
    &:hover{
      background-color: #fff;
     color:$primary-color;
     border-color:$primary-color;
      box-shadow: 0 2px 5px 0 rgba(0,0,0,.26);
    }
  }

  .btn-masters{
    border-radius: 6px;
    background-color: #707070;
    display: block;
    margin:0 auto;
    text-transform: uppercase;
    color:#fff;
    font-size: rem-calc(16);
    text-decoration: none;
    @include single-transition();
    font-weight: bold;
    padding: .5em 1em;
    text-align: center;
    @media #{$medium-up} {
      padding: 1.5em 3em;
      font-size: rem-calc(18);
    }
    &:hover{
      background-color: $primary-color;
      box-shadow: 0 2px 5px 0 rgba(0,0,0,.26);
      color:#fff;
    }
    &.admissio{
      display: inline-block;
      margin: 1em 0 1em 2em;

      @media #{$medium-up} {
        padding: .5em 2em;
        font-size: rem-calc(16);
      }
    }
  }
  .btn-resum-master{
    &:visited{
      color:#fff;
    }
    @media #{$medium-up} {
       text-align:left;
       padding-left:73px;
       width: 70%;
       display: inline-block;
    }
    span{
      position: relative;
      display: block;
      @media #{$medium-up} {
        padding-left: rem-calc(44);
      }
      &:before{
        @extend .ico-acrobatpdf;
        @media #{$medium-up} {
          display: block;
          content: '';
          position: absolute;
          top:50%;
          left:0;
          margin-top:-$ico-acrobatpdf-height/2;
        }
      }
    }
  }
  .comparteix-master{
    text-align: center;
    .titol{
      text-transform: uppercase;
      color: #707070;
      font-size: rem-calc(14);
    }
    ul{
        list-style-type: none;
        text-align: center;
       margin-left: auto;
      margin-right: auto;
      margin-top:1em;
      //width:170px;
      li{
          line-height: 1;
          padding:0;
          display: inline-block;
          margin-left: 1em;
          &:before{
            content: none;
          }
          &:first-child{
            margin-left:0;
          }
          &.whatsapp{
             @media #{$tablet-up} {
              display:none;
            }
          }
        }
      a{
        display: inline-block;
        background-color:transparent;
        width: 50px;
        height: 50px;
        line-height: 1;
        border-radius: 3px;
        text-align: center;
        position: relative;
        color:#000;
        &:hover{
          box-shadow: 0 2px 5px 0 rgba(0,0,0,.26);
        }
        &:before{
          content:'';
          display: block;
          position: absolute;
          top:50%;
          left:50%;
          transform: translate(-50%,-50%);
        }

        &.facebook{
           background-color: #3b5998;
           color:#fff;
           &:before{
              @extend .logo-peu-int-facebook;
           }
        }
        &.twitter{
           background-color: #1da1f2;
           &:before{
              @extend .logo-peu-int-twitter;
           }
        }
        &.linkedin{
           background-color: #0077b5;
           color:#fff;
           &:before{
              @extend .logo-peu-int-linkedin;
           }
        }
        &.whatsapp{
           background-color: #25d366;
           &:before{
              @extend .ico-compartir-whatsapp;
           }
        }
        &.telegram{
           background-color: #0088cc;
           &:before{
              @extend .ico-compartir-telegram;
           }
        }
        &.email{
           background-color: #8898a3;
           &:before{
              @extend .ico-compartir-correu;
           }
        }
        span{
           @include visually-hidden;
        }

        &:focus{
          //border:solid 1px #;
          border:none;
          outline:#ffbf47 solid 3px;
        }
        &.external:after{
          content: none;
        }

      }
    }
  }
  .experiencies{
    margin-bottom:3rem;

    background-color: #ececec;
    .imatge-alumne{
      text-align:center;
      padding-top: 2rem;
    }
    &.alumne{
      h1{
        margin: 0;
      }
      img{
        margin-top:1rem;
      }
      ul{
        margin:2rem 0;
        li{

          &:before{
            content: none!important;
          }
        }
      }
      .videoWrapper{
        margin-top:2rem;
      }
    }
    img{
      max-width: 200px;
      border-radius: 100%;
      &.fitxa{
        margin-top:2rem;
      }
    }
    figure{
      margin: 0;
    }
    .video{
      position: absolute;
      width: 100%;
      height: 100%;
      top:0;
      left:0;
      z-index:2;
      /*background: rgba(0,0,0,.3);*/
      a{
        position: absolute;
        bottom:20px;
        left:50%;
        display: block;
        margin:0;
        margin-left: -30px;
      }
      img{
        border-radius:0;
      }
    }

    .text{
      padding:2rem;
       @media #{$tablet-up} {
           padding:2rem 0;
        }
      .info{
        p{
          margin:.25em;
          font-size:rem-calc(14);
          line-height: 1.2;
        }

      }
      blockquote{
        font-size:rem-calc(20);
        margin-top: 1rem;
        font-weight: 300;
         line-height: 1.2;
         border:none;
         padding: 0;
         color:#000;
      }
      .button{
          padding:.3em 1em;
          background-color:transparent;
          color:$primary-color;
          border:solid 1px $primary-color;
          border-radius: 6px;
          text-transform: uppercase;
          font-size: rem-calc(14);
          &:hover{
            background-color:$primary-color;
              color:#fff;
          }
          margin:1rem 0 0;
        }

    }
    &.alumne{
      .text{
        @media #{$tablet-up} {
           padding:2rem;
        }
      }
    }
  }
  .dades{
    padding: 0;
  }
  .dades-info{
    background-color: #F7EEEE;
    min-height: 100vh;
    max-width: 99.99%;
    padding: 2em 0;
    @media #{$tablet-up} {
      padding-bottom: 4rem;
    }
    ul{
      padding: 0 2em;
      max-width:100%;
    }
    dl{
      padding: 0 2em;
      margin:0;
    }
    @supports (display: flex) {
      dl {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        padding:0;
        background: #F7EEEE;
      }
      dt,dd{
        background: #ECDADA;
        padding: 1em 0 1em 2em;
        margin:0;
      }
      dt {
        width: 35%;
      }
      dd{
        width: 65%;
        padding-right: 1em;
      }
      dt:nth-child(4n + 1), dd:nth-child(4n + 2) { background: #F7EEEE; }
    }
    .contacte{
      padding: 2em 2em 0;
      margin-top: 1em;
      font-weight: bold;
      border-top: solid 1px #000000;
      p{
        font-weight: bold;
      }
    }
  }
  .xarxes{
    margin-top: rem-calc(32);
    padding: 0 2em;
    ul{
      @include inline-list;
      margin-bottom:0;
      li{
          padding-left:0;
          &:before{
            content: none!important;
          }
        }
      a{
        display: inline-block;
        background-color:transparent;
        padding: 0;
        margin-top:3px;
        border:none;
        span{
           @include visually-hidden;
        }
        &.facebook{
          @extend .share-facebook;
        }
        &.twitter{
          @extend .share-twitter;
        }
        &.linkedin{
          @extend .share-linkedin;
        }
        &.instagram{
          @extend .share-instagram;
        }
        &:focus{
          background-color: transparent;
          //border:solid 1px #;
          border:none;
          outline:#ffbf47 solid 3px;
        }
        &.external:after{
          content: none;
        }

      }
    }
  }
  &.interior{
    .main-titol{
      @media #{$tablet-up} {
        min-height: 250px;
      }
      .slider-wrapper {
        opacity: 1;
      }
    }
    .section-nav{
      margin-bottom: 2rem;
      @media #{$medium-up} {
        margin-bottom: 4rem;
      }
    }
    .xarxes{
      padding:0;
      ul{
        margin:0;
        li:first-child{
          margin-left:0;
        }
      }
    }
    .title{
      font-family: 'Roboto Slab', serif;
      font-size: rem-calc(20);
      font-weight:bold;
      line-height: 1.2;
      color:#000;
      max-width:600px;
      margin-bottom: rem-calc(16);
      @media #{$medium-up} {
         font-size: rem-calc(26);
      }
    }
    @media #{$medium-up} {
      .text-header{
        text-align:center;
      }
    }
    h2{
      font-family: 'Roboto Slab', serif;
      font-weight:300;
      margin-bottom: 1.5rem;
    }
    /*.accions{

      .btn-preinscripcio-master{
        margin:0;
        width:100%;
        font-size: rem-calc(16);
      }
    }*/
    .text{
      h3{
        margin-top:3rem;
        &:first-child{
          margin-top:0;
        }
      }
      h2+h3{
        margin-top: 0;
      }
      ul{
        li{
          list-style:none;
          position:relative;
          margin-bottom: rem-calc(16);
          &:before {
              color: $primary-color;
              content: '\2022';
              position:absolute;
              left:-15px;
              font-size:rem-calc(18);
              top:-2px;
          }
        }
      }
    }
    .dades{
      margin-top: rem-calc(25);
      section{
          padding: rem-calc(27) 0 rem-calc(7);
          &:nth-child(odd) {background: #F0F0F0}
          h3{
            font-size: rem-calc(16);
            font-weight: bold;
            margin:0;
          }

          ul{
            li{
              list-style:none;
              position:relative;
              margin-bottom: rem-calc(16);
              &:before {
                  color: $primary-color;
                  content: '\2022';
                  position:absolute;
                  left:-15px;
                  font-size:rem-calc(18);
                  top:-2px;
              }
            }
          }
          @media #{$medium-up} {
            .medium-9{
              padding-left: rem-calc(25);
              padding-right: 15%;
            }
            ul{
              max-width: 500px;
            }
          }
          &.vincles{
             width: 100%;
            @media #{$tablet-up} {
              text-align: center;
            }
            ul{
              max-width: 100%;
              margin-left: 0;
              @media #{$tablet-up} {
                margin:0 auto 1rem;
              }
              li{
                a{
                  display: block;
                  text-align: center;
                  @media #{$tablet-up} {
                    display: inline-block;
                  }
                }
                @media #{$tablet-up} {
                   display: inline;
                   margin-left: 2rem;
                  &:first-child{
                    margin-left: 0;
                  }
                }
                &:before{
                  content: none;
                }
              }
            }
            a{
              border-radius: 6px;
              background-color: #707070;
              display:inline-block;
              color: #fff;
              @include single-transition();
              font-size: rem-calc(14);
              text-decoration: none;
              padding: .75em 2em;
              line-height:1.1;
              @media #{$medium-up} {
                font-size: rem-calc(16);
              }
              &:hover{
                color: #fff;
                background-color: $primary-color;
              }
            }
          }
          &.web{
            background-color:#fff;
            .btn{
              text-transform: none;
              font-size: rem-calc(14);
              font-weight: normal;
              margin: 0;
              span{
                color:#000;
                font-weight: bold;
                display: block;
              }
              @media #{$medium-up} {
                font-size: rem-calc(16);
                span{
                  display: inline-block;
                  padding-right:rem-calc(25);
                }
                padding: 1.5em 3em;
              }

            }
          }
      }
      &.pmf{
        section{
          h3{
            margin-bottom: .5rem;
          }
        }
      }
    }
    &.pla-estudis{
        .title{
          margin-left: auto;
          margin-right: auto;
        }
    }
    .taula-dades{
      margin-top: 3rem;
      h3{
        font-family: 'Roboto Slab', serif;
        font-size: rem-calc(18);
        margin-bottom: 1rem;
        @media #{$medium-up} {
          font-size: rem-calc(26);
        }
      }
      table{
        margin-bottom: 3rem;
        border: solid 1px #c6c6c6;
        padding: 1rem;
        background-color: #F6F6F6;
        border-collapse: initial;
        overflow: auto;
        overflow-y: hidden;
        overflow-x: auto;
        width: 100% !important;
        display: block;
        @media #{$medium-up} {
          display: table;
        }

        th{
            font-family: 'Roboto Slab', serif;
            text-align: left!important;
        }
        tr,th,td{
          background-color: #F6F6F6;
          text-align: left!important;
          @media #{$medium-up} {
            font-size: rem-calc(16);
          }
        }
        th,td{
          border-bottom: solid 1px #dfdfdf;
          padding:1em .5em;
          @media #{$medium-up} {
            padding:1.5em 1em;
          }

        }
        tr:last-child{
          th,td{
            border:none;
          }
        }
      }
      .taules{
        #estructura{
          table:nth-child(2){
             tbody th,td{
              @media #{$medium-up} {
                font-size: rem-calc(20);
              }
             }
          }
        }
        table+p{
          margin-top:-2rem;
          margin-bottom:3rem;
        }
      }

    }
    #entrevista,#perfil-docent-coordinador{
      background-color: #f0f0f0;
      padding: 3rem 0;
      margin-bottom:4rem;
      h3{

        font-family: 'Roboto Slab', serif;
        font-size: rem-calc(16);
        font-weight: bold;
      }
      p{

        font-weight: 300;
      }

      @media #{$medium-up} {
          h3,p{max-width: 70%;font-size: rem-calc(18);}
          li{
            h3,p{
              max-width: 90%;
            }
          }
      }
    }

    .professorat{
      figure{
          margin:0;
          padding:0;
          img{
              margin-bottom:.5rem;
          }
      }

      .carrec{
        //font-style: italic;

        @media #{$medium-up} {
          max-width: 80%;
        }
        p{
          font-weight: 300;
          margin-bottom: .6em;
          font-size:rem-calc(14);
          line-height: 1.2;
          @media #{$medium-up} {
            font-size:rem-calc(16);
          }
        }
        ul{
          margin:0;
          li{
            list-style: none;
            font-weight: 300;
            margin-bottom: .6em;
            font-size:rem-calc(14);
            line-height: 1.2;
            @media #{$medium-up} {
              font-size:rem-calc(16);
            }
          }
        }
        margin: rem-calc(10) 0 rem-calc(50);
        a{
          font-weight:bold;
          display: inline-block;
          margin-top: .35rem;
        }
      }
      .frase{
        font-family: 'Roboto Slab', serif;
        font-size: rem-calc(16);
        position: relative;
        margin-top: 1rem;
        padding-left:3.5rem;
        font-weight: 300;
        @media #{$medium-up} {
          max-width: 90%;
        }

        span{
          font-size: rem-calc(160);
          color: $primary-color;
          position: absolute;
          top: -10px;
          left:0;
          line-height: 1;
        }
      }
      p.mes{
        text-align: center;
        display: none;
        @media #{$tablet-up} {
          text-align: left;
        }
      }

      .professor{
        margin-bottom:1rem;
        margin-top:3rem;
        button{
          margin: 0;
          padding:.3em 1em;
          background-color:#fff;
          color:#707070;
          border:solid 1px #707070;
          border-radius: 6px;
          text-transform: uppercase;
          font-size: rem-calc(14);
          &:hover{
            background-color:#707070;
              color:#fff;
          }
          margin:1rem 0 0;
          @media #{$tablet-up} {
            margin-left:5rem;
            &.more-perfil,&.less-perfil{
              margin-left:2rem;
            }
          }
          &.less,&.less-perfil{
             display:none;
          }

        }
        img{
          max-width: 200px;
          @media #{$medium-up} {
            max-width: 100%;
          }

        }
        h2{
          font-size:rem-calc(32);
          margin: 0;
          font-weight: normal;
          a{
            color:#000;
          }
        }
        .frase{
          font-size: rem-calc(18);
          padding-left:5rem;
          font-weight: normal;
          margin-top: 2rem;
          @media #{$medium-up} {
            font-size: rem-calc(24);
             max-width: 90%;
          }
          span{
            font-size: rem-calc(160);
            top: -20px;
          }
        }
      }
      &--llista{
        @include block-grid(1);
        @media #{$tablet-up} {
          @include block-grid(2);
        }
        li{
          margin-top: 3rem;
        }
        //.inner{
          .carrec{
            margin-bottom:0;
            li{
               margin-top: 0;
            }
          }

          h3{
            font-family: 'Roboto Slab', serif;
            font-size: rem-calc(18);
            @media #{$medium-up} {
              font-size: rem-calc(22);
            }
            a{
              color:#000;
            }
          }


        //}
        .mes{
          margin: 0;
        }
        .js-to_expand{
          margin-top: -5px;
          .perfil-docent{
            background-color: #f0f0f0;
            margin: 0 .5em;
            padding: 1em;
            text-align: left;
            li{
              margin-top:0;
              margin-bottom:1em;
            }
            .docent{
              font-weight: bold;
              padding-bottom: 1em;
              margin-bottom: 1em;
              border-bottom: solid 1px #ccc;
              text-align: center;
            }

          }
        }
        button{
          margin: 0;
          padding:.3em 1em;
          background-color:#fff;
          color:#707070;
          border:solid 1px #707070;
          border-radius: 6px;
          text-transform: uppercase;
          font-size: rem-calc(14);
          &:hover{
            background-color:#707070;
              color:#fff;
          }
          margin:1rem 0 0;

          &.less-perfil{
             display:none;
             background-color: #f0f0f0;
             &:hover{
              color:#707070;
             }
          }

        }
      }
      .btn-masters{
        margin:3rem 0 2rem;
        display: inline-block;
      }
    }
  }
  &.master-portada{
    .main-titol{
      min-height: 200px;
      h1{
        max-width:100%;
        color:#fff;
        font-size:rem-calc(38);
        @media #{$tablet-up} {
          //margin-top: rem-calc(130);
           font-size:rem-calc(52);
        }
      }
    }
  }
  &.master-global{
    .main-titol {
       min-height: auto;
        h1{
          max-width:100%;
        }
        .seccio{
        max-width:100%;
        color:#fff;
        font-size:rem-calc(26);
        font-weight: bold;
        @media #{$tablet-up} {
              padding: 2.8125rem .9375rem;
           font-size:rem-calc(36);
        }
      }

    }
    .section-nav{
      //background-color: #fff;
      background-color: #f1f1f1;
      background-image: linear-gradient(to left, #f1f1f1, #dedede);

      h2{
        color: #000;
      }
      ul{

        li{
            color: #000;
            a{
              color:#000;
              font-weight:bold;
              @include single-transition();

              @media #{$small-only} {
                border-bottom:solid 1px #979797;
                padding-right: 0;
                padding-top: .45em;
                padding-bottom: .4em;
                min-height:65px;
                padding-left: 0;
                margin-right: .85em;
              }
              @media #{$medium-up} {
                padding: 2em 1.75em;
                font-size: rem-calc(14);
                //max-width: 180px;

              }
              @media only screen and (min-width: 1090px){
                font-size: rem-calc(15);
              }
              @media only screen and (min-width: 1240px){
                font-size: rem-calc(17);
              }
              &:hover{
                background-color: darken(#f1f1f1,10%);
              }
              &:before{
                content: none;
              }
            }
            &.active{
              a{
                background-color: transparent;
                color:$primary-color;
                @media #{$tablet-up} {
                  background-color: #f7f7f7;
                  color:#000!important;
                }
              }
            }
            @media #{$medium-up} {
            &.b{
              a{
                padding-top: 1.5em;
                padding-bottom: 1.35em;
              }
            }
            }
            &:nth-last-child(2){
              a{
                border-bottom: none;
              }
            }
            &:last-child{
              a{
                border-bottom: none;
              }
            }
        }
      }
    }
    .hero{
      padding-top: 3rem;
      background-color: #f7f7f7;
      .wrapper{
        @media #{$medium-up} {
          padding-left: rem-calc(40);
        }
      }
      .accions{
        .btn-preinscripcio-master{
          margin:2rem 0 0;
          width:100%;
          padding-top:1.5em;
            padding-bottom:1.5em;
          @media #{$medium-up} {
            margin:0;
          }
        }
        ul{
          margin: 1rem 0 2rem;
          @media #{$medium-up} {
            margin: 2rem 0;
          }
          li{
            list-style:none;
            a{
              text-transform: uppercase;
              display:block;
              text-align: center;
              padding:1rem;
              border-bottom: solid 1px #535353;
              font-size:rem-calc(16);
              @include single-transition();
              color:#000;
              @media #{$medium-up} {
                font-size:rem-calc(18);
              }
              &:hover{
                color:$primary-color;
              }
            }
            &:last-child{
              a{
                border-bottom:none;
              }
            }
          }
        }

      }
      .cataleg{
        position: relative;
        border-bottom:none;
        max-height: 212px;
        overflow: hidden;
        text-align: center;
        h2{
          font-size:rem-calc(18);
          margin:0 0 1rem;
          font-weight:bold;
          text-transform: uppercase;
        }
        span{
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          max-width: 140px;
          font-weight: bold;
          z-index: 2;
          bottom:15px;
          background-color: #f7f7f7;
          //background: rgba(#ccc,.98);
          color:#000;
          font-size: rem-calc(14);
          line-height:1.2;
          text-align: left;
          opacity: .92;
          @include single-transition();
          padding:.5rem .5rem .75rem 45px;
          img{
            position:absolute;
            top:5px;
            left:5px;
            width:33px;
          }

        }
        img{
            max-width: 95px;
            @media #{$tablet-up} {
              max-width: 55px;
            }
            @media #{$medium-up} {
             max-width: 150px;
            }
        }
        a{
          display:block;
          padding: 10px 10px 0;
           background-color:#fff;
          @include single-transition();
          &:hover{
            background-color:#ececec;
            span{
                bottom:32%;
                opacity: 1;
            }
          }
        }
      }
    }
  }

  .seccio-destacada{
    padding:3rem 0;
    h2{
      text-align:center;
      margin-bottom: 2rem;
      text-transform:none;
    }
    @media #{$medium-up} {
      padding:4rem 0;
    }
    figure{
      margin: 0;
      padding: 0;
    }
    time{
      font-size: rem-calc(13);
      padding-bottom: .35em;
      display: block;
      font-weight: bold;
    }
    h3{
      margin:0 0 2.5rem;
      font-size: rem-calc(20);
      max-width:360px;
      font-family: 'Roboto Slab', serif;
      font-weight:300;

      @media #{$medium-up} {
        font-size: rem-calc(28);
      }

    }
    a{
      color:#000;
      text-decoration: none;
      @include single-transition;
      display:block;
      position: relative;
      &:hover{
        h3{
          color:$primary-color;
        }
      }
      &.btn{
        margin-top:0;
        display:inline-block;
        color:$primary-color;
        &:hover{
          color:#fff;
        }
      }
    }
    .caixes{
      h2{
        margin:0;
      }
    }

    &.bg{
      background-color: #ececec;
    }
    &.experiencies{
      margin-bottom:0;
      text-align: center;
      .imatge{
        position:relative;
        max-width: 200px;
        margin: 0 auto;
      }
      .video{
        //border-radius: 1000px;
        img{
          width:61px;
          position: absolute;
          bottom: 20px;
          left: 50%;
          display: block;
          margin: 0;
          margin-left:-31px;
        }
      }
      h3{
        margin:1rem 0;
      }
      .text{
        padding:0;
        @media #{$medium-up} {
          max-width:95%;
        }
        blockquote{
          margin:0 0 2.5rem;
        }
      }
    }
    &.seccio-intro{
      h2{
        font-family: 'Roboto Slab', serif;
        font-weight:300;
      }
      a.btn{
        margin:1.5rem 0 0;
      }
      p{
        font-size: rem-calc(22);
        font-weight: 300;
        text-align:center;
        @media #{$medium-up} {
          font-size: rem-calc(24);
          max-width:90%;
          margin-left: auto;
          margin-right: auto;

        }
      }
    }
    &.seccio-videos-masters{
      margin-top: 0;
      padding-top: 0;
    }
    &.noticies{
      img{
        width: 100%;
        max-height: 287px;
      }

    }
  }

  .seccio-text{
    padding-top:1rem;
    h3{
      margin:3rem 0 2rem;
      position: relative;
      padding-bottom:1em;
      line-height:1.2;
      &:after {
          background-color: $primary-color;
          bottom: -2px;
          content: '';
          height: 4px;
          left: 0;
          position: absolute;
          width: 100px;
      }
    }
    @media #{$tablet-up} {
      p{
        max-width:90%;
      }
    }
  }
  .videos-masters{
    margin-bottom: 1.5rem;
    .imatge{
      max-height: 292px;
      overflow: hidden;
      position: relative;
      .video{
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -32px;
        margin-left: -32px;
        width: 64px;
        height: 64px;
        background-color: #000;
        background-color: rgba(#000,.6);
        border-radius: 100%;
        @include single-transition();
        svg{
          position: absolute;
          top: 50%;
          left: 50%;
          margin-top: -16px;
          margin-left: -16px;
          width:32px;
          height:32px;
          @include single-transition();
        }
      }
    }
    a{
      display: block;
      &:hover{
        .imatge{
          .video{
            background-color: $primary-color;
            background-color: rgba($primary-color,.9);
            svg{
              fill: #fff;
            }
          }
        }
        h2{
          color: $primary-color;
        }
      }
    }
    h2{
      font-size: rem-calc(18);
      margin: .6rem 0 .2rem!important;
      @media #{$tablet-up} {
         font-size: rem-calc(20);
        }
    }
    p{
      font-size: rem-calc(14);
      opacity: .75;
      color: #222;
    }
    p+p{
      margin-top: 1em;
    }

    &.seccio-destacada{
      margin-bottom: 0;
      h2{
         text-align: center;
        margin-bottom: 2rem!important;
        text-transform: none;
        @media #{$tablet-up} {
         font-size: rem-calc(36);
        }
      }
      h3{
        margin: .6rem 0 .2rem;
        font-size: rem-calc(18);
        @media #{$tablet-up} {
         font-size: rem-calc(20);
        }
      }
      a.btn{
        margin-top:.6rem;
      }
    }
    &--items{
      h2{
        //text-align: center;
        font-size: rem-calc(20);
        border-bottom: none;
        padding: 0;
      }
      ul{
        li{
          padding-left: 0;
          &:before{
            content: none;
          }
          a{
            border-bottom: none;
            text-decoration: none;
            display: block;
            &:focus{
              border:none;
              background-color: transparent;
              h3{
                color: $primary-color;
              }
            }
            &:hover{
              h3{
                color: $primary-color;
              }
            }
          }
          .imatge{
            max-height: 161px;
            overflow: hidden;
          }
          h3{
            font-size: rem-calc(16);
            margin: .25rem 0;
          }
        }
      }
    }

  }
  .destacats{
    /*h2{
      text-align:left;
      padding-left: .9375rem;
    }*/
    .flex-hover-card-container {

        min-height: 100px;

        @media #{$tablet-up} {
          display: -ms-flexbox;
          display: flex;
          clear: both;
          flex-wrap: wrap;
          justify-content: center;
        }
    }
    .card{
      margin-bottom:2rem;
      padding-left: .9375rem;
      padding-right: .9375rem;
      @media #{$tablet-up} {
        width: 33.33333%;
        flex: initial;
      }
      h3{
        color:#fff;
        font-weight: bold;
         font-family: 'Roboto Slab', serif;
         font-size: rem-calc(28);
         margin-bottom:1rem;
      }
      p{
        color:#fff;
        font-size: rem-calc(18);
      }
      a{
        background-color: #c14e50;
        display:block;
        height: 100%;
        color:#fff;
        box-shadow: none;
        @include single-transition();
         padding: 1rem;
        span{
          border-top:solid 1px #fff;
          display: block;
          padding-top: 1rem;
        }
        &:hover{
          background-color:#fff;
          color:$primary-color;
          box-shadow: -1px 5px 25px -5px rgba(0,0,0,0.8);
          transform: translate3d(0,-3px,0);
          h3,p{
            color:$primary-color;
          }
          span{
            border-color:$primary-color;
          }
        }
      }

    }

  }
  .box{
    border: solid 1px #979797;
    padding:2rem;
    margin:.5rem 0 3rem;
    h3{
      font-family: 'Roboto Slab', serif;
      font-size:rem-calc(20);
      font-weight:300;
       margin-bottom:1rem;
    }
    p
    {
      font-weight:bold;
      //font-size:rem-calc(18);
      color:#000;
    }
  }
  &.oferta{
    .main-titol{
      margin: 0;
      min-height: auto;
      h1{
        max-width: 720px;
      }
    }
    @media #{$tablet-up} {
      .section-nav{
        margin-bottom: 0;
      }
    }
    .main-titol-oferta{
      min-height:40px!important;
      padding-top: 3rem;
      margin-bottom: 2rem;
      background-color:#f7f7f7;
      background-image: none;
      overflow: hidden;

    }
    .taula-oferta{
      position: relative;
      .no-resultats{
        padding:1rem;
        font-size: rem-calc(20);
      }
      .loading{
        position: absolute;
        width:100%;
        height: 100%;
        background-color: #fff;
        //background: rgba(255,255,255,.98);
        z-index: 2;
        text-align: center;
        img{
          padding: .25rem;
          background-color: #fff;
          border-radius: 1000px;
          box-shadow: 0 2px 5px 0 rgba(0,0,0,.26);
          width:54px;
          margin:0 auto;
        }
      }
      @media #{$small-only} {
        .columns{
          padding:0;
        }
      }
    }
    table{
      margin-bottom: 3rem;
      background-color: #F6F6F6;

      overflow: auto;
      overflow-y: hidden;
      overflow-x: auto;
      width: 100% !important;
      display: block;
      @media #{$medium-up} {
        display: table;
      }
      border:none;
      th{
        background-color: #c86f70;
        font-size: rem-calc(14);
        color:#000;
        padding:1em;
        font-family: 'Roboto Slab', serif;
        @media #{$tablet-up} {
           padding:2em 1em;
           font-size: rem-calc(16);
        }

      }
      tbody{
        tr{
          background-color:#fff!important;
          border-bottom:solid 1px #dfdfdf;
          @include single-transition();
          &:hover{
            background-color:#efefef!important;
            td{
              color:#000;
            }
          }
        }
        td{
          padding:1em;
          font-size: rem-calc(14);
          @media #{$tablet-up} {
           padding:2em 1em;
           font-size: rem-calc(16);
          }

          p{
            margin:0;
          }
          @include single-transition();
          &:first-child{
            width:50%;
            a{
              font-weight: bold;
            }
          }

        }
      }
    }
  }
  &.ambits{
    .main-footer{
      margin-top: 0;
    }
    h2{
      margin-bottom: 1rem;
    }
    .caixes{
      margin-bottom: 3rem;
    }
  }


}
.tabs-components{
  text-align:center;
  background-color:#F6F6F6;
  margin-top: 3rem;
  @media #{$medium-up} {
    margin-top: 2rem;
  }
  ul{
    margin:0;
    overflow: hidden;
    overflow-y: hidden;
    overflow-x: auto;
    white-space: nowrap;
    li{
      display:inline-block;
      margin:0;
      font-family: 'Roboto Slab', serif;
      font-weight: bold;
      max-width: 130px;
      vertical-align: middle;
      @media #{$tablet-up} {
        max-width: 1000px;
      }
      a{
        white-space: normal;
        display:block;
        cursor: pointer;
        height:66px;
        color:$primary-color;
        padding:1em .5rem;
        font-size: rem-calc(12);
         border-top:solid 2px transparent;
        border-bottom:solid 2px transparent;
        background-color:transparent;
        @include single-transition();

        @media #{$tablet-up} {
          padding:1rem;
          font-size: rem-calc(17);
        }
        &.selected{
          background-color:#fff;
          color:#000;
        }
        &:focus{
          outline:0;
          border-bottom:solid 2px $primary-color;
        }
        &.selected{
          &:focus{
            border-bottom:solid 2px #999;
          }
        }
        &:hover{
          border-bottom:solid 2px $primary-color;
        }
      }
    }
  }
  .btn{
    position:absolute;
    top:-30px;
    right:1rem;
    padding:3px;
    text-transform:none;
    margin:0;
    transform: translateY(-50%);
    @media #{$medium-up} {
        top:50%;
        transform: translateY(-50%);
         padding:5px 10px;
    }
  }
}
.master-form{
  margin:3rem 0;
  input,select{
    border-radius: 6px;
    border: solid 1px #d9d9d9;
    box-shadow: none;
    background-color: #fafafa;
    height: 3rem;
    box-sizing: border-box;
    margin-bottom: 1rem;
     &:focus{
        border-color: #d9d9d9;
        outline: 0;
        background-color: #fff;
        box-shadow: 0 2px 2px 0 rgba(0,0,0,0.16), 0 0 0 1px rgba(0,0,0,0.08);
    }
  }
  .btn{
     @media #{$tablet-up} {
        margin: 1rem 0 0;
      }
  }
  .advanced-search{
    text-align: center;
    button{
      margin: 0;
      padding:.3em;
      background-color:#fff;
      color: #777;
      &.less{
         display:none;
      }
      @media #{$tablet-up} {
        display:none!important;
      }
    }
    &--form{
      display:none;
      text-align: left;
      @media #{$tablet-up} {
        display:block;
      }
    }
  }
}
.master-form-b{
  margin:3rem 0;
  padding: 0 .9rem;

  input,select{
    //border-radius: 6px;
    border: solid 1px #d9d9d9;
    box-shadow: none;
    background-color: #fafafa;
    height: 3rem;
    box-sizing: border-box;
    border-right: none;
    &:focus{
        border-color: #d9d9d9;
        outline: 0;
        background-color: #fff;
        box-shadow: 0 2px 2px 0 rgba(0,0,0,0.16), 0 0 0 1px rgba(0,0,0,0.08);
    }
  }
  #cercar{

    margin:0;
    position: absolute;
    bottom: 16px;
    padding: .8rem;
    right: 0;
    border-top-right-radius:6px;
    border-bottom-right-radius:6px;
    div{
      @extend .cercar-menu-tablet;
      span{
        @include visually-hidden;
      }
    }
  }
  #cercar-master{
    border-top-right-radius:6px;
    border-bottom-right-radius:6px;
  }
  #arees{
    border-top-left-radius:6px;
    border-bottom-left-radius:6px;
  }
  .btn{
    margin-top: 2rem 0 0;
  }
}
.js{
  #components{
    overflow:hidden;
    .item{
      height: 1px;
      float: left;
      margin-left: -100%;
      width: 100%;
      top: 0;
      transform: translateX(-110%);
      transition: transform 300ms cubic-bezier(0.930, 0.210, 0.005, 0.890);
      &:first-child {
        margin-left: 0;
      }
      &.selected {
        height: auto;
        transform: translateX(0);
        transition: transform 300ms 700ms cubic-bezier(0.930, 0.210, 0.005, 0.890);
      }
    }
  }
}
.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#master-breadcrumb{
  background-color: #fff;
  box-shadow: 0 0 4px rgba(0,0,0,.14), 0 4px 8px rgba(0,0,0,.28);
  //box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.4);
  //position: fixed;
  top:0;
  width:100%;
  z-index: 20;
  display: block;
  opacity: 0;
  visibility: hidden;
  height: 0;
  max-height: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  position: static;
  @media #{$medium-up} {
    top:41px!important;
    will-change: opacity;
     @include single-transition('opacity');

  }

  &.fixed{
    opacity: 1;
    visibility: visible;
    height: auto;
    max-height: 100px;
    background-image: linear-gradient(to bottom,#fff,#e7e7e7);
    .row{
      position: relative;
      padding-top: .75rem;
      padding-bottom: .75rem;
      @media #{$tablet-up} {
         padding-top: 1rem;
        padding-bottom: 1rem;
      }
    }
  }
  .master-titol{
     padding-left: .9375rem;
     font-family: 'Roboto Slab', serif;
      font-weight: bold;
      font-size: rem-calc(14);
      display: none;
      @media #{$tablet-up} {
        display: block;
        width: 500px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      @media #{$medium-up} {
         text-overflow: none;
         width: auto;
      }
  }
  .opcions{
    text-align: right;
    @media #{$tablet-up} {
      position: absolute;
      right:0;
      top:50%;
      transform: translateY(-50%);
    }
    ul{
      margin: 0;
      padding: 0;
      li{
        list-style: none;
        display: inline-block;
        margin: 0 .9375rem 0 1rem;
        padding: 0;

        a{
          margin:0;
          font-size: rem-calc(14);
          &.btn{
            padding: .2rem 1rem;
            text-transform: none;
            background-color: $primary-color;
            color:#fff;
            transform: translateZ(0);
            -webkit-transform: translateZ(0);
            transition:none;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            &:hover{
              background-color: #fff;
              color: $primary-color;
              box-shadow: 0 2px 5px 0 rgba(0,0,0,.26);
            }
          }
        }
      }
    }
  }
}
.moreless{
  span{
    display:inline-block;
    border:solid 1px #aaa;
    cursor:pointer;
    border-radius: 6px;
    line-height: 1.1;
    width: 20px;
    height: 20px;
    text-align: center;
  }
}
.masters-per-ambit{
  margin-top:3rem;
  li{
    a,
    div.caixa /* per a les plantilles de mòduls */ {
      background: #fff;
      border-radius: 2px;
      box-shadow: 0 2px 2px rgba(0,0,0,.24), 0 0 2px rgba(0,0,0,.12);
      margin: 0;
      padding: rem-calc(20);
      position: relative;
      @include single-transition();
      @media #{$tablet-up} {
        min-height: 225px;
      }
      .titol{
        font-size: rem-calc(20);
        line-height: 1.4;
        font-family: 'Roboto Slab', serif;
        color: $primary-color;
        margin-bottom: .75rem;
      }
      .subtitol{
        line-height: 1.3;
        font-weight: 300;
        margin-bottom: 2rem;
        @media #{$tablet-up} {
          margin-bottom: .5rem;
        }
      }
      @media #{$tablet-up} {
        .text{
          position: absolute;
          bottom:rem-calc(20);
          left:rem-calc(20);
          right:rem-calc(20);
        }
      }
    }
    a {
      &:hover{
        box-shadow: -1px 5px 25px -5px rgba(0,0,0,.5);
        transform: translate3d(0,-2px,0);
      }
    }
  }
}
.js{
  .js-to_expand[aria-hidden=true], .js-to_expand[data-hidden=true] {
      display: none;
  }
  .master .professorat{
    p.mes{
      display: block;
    }
  }
}

/* DATATABLES  */
.masters{
  .dataTables_wrapper .dataTables_filter{
    float:none;
    width:100%;
    text-align: left;
    background-color: #f3f3f3;
    box-shadow: 0 1px 0 0 rgba(101, 101, 101, 0.5);
    padding:25px 1em 1em;
    margin-bottom:2em;
    position:relative;
    min-height: 73px;
    margin-top:rem-calc(30);
    input{
      @include radius(13px);
      background-image: linear-gradient(to bottom, #ffffff, #e6e6e6);
      border: solid 1px #bcbcbc;
      display:block;
      padding-left:40px;
      margin-top:8px;
      @media #{$tablet-up} {
        max-width:70%;
        position:absolute;
        left:175px;
        top:18px;
        margin-top:0;
      }
    }
    label{
      &:before{
        content: '';
        display: block;
        @extend .icona-filtre-cercar;
        position: absolute;
        left: 23px;
        top: 61px;
        z-index:3;
        @media #{$tablet-up} {
          left: 183px;
          top: 25px;
        }
      }
    }

  }
  table, table.dataTable{
    border:none;
    margin-bottom:rem-calc(50);
    th{
      background-color: $primary-color;
      color:$white;
      border:none;
      min-width:25%;
      font-size:rem-calc(17);
      padding: .75em 1.5em;
      border-bottom:solid 5px $white;
      &:first-child{
        border-right:solid 15px $white;
        width:75%;
      }
    }
    td{
      background-color:$white;
      border-bottom: solid 1px #cbcbcb;
      padding:1em 0 1em 1.75em;
      font-size:rem-calc(15);
      position:relative;
      vertical-align: top;
      a{
        color:#50798d;
        border-bottom: dashed 1px #6e9bb1;
        text-decoration: none;
        padding-bottom:3px;
        &:focus{
          color:$white;
        }
      }
      p{
        margin:0;
        font-size:rem-calc(15);
      }
      &:first-child:before {
        content: '';
        display: block;
        @extend .kland-llistat-nivell-1;
        position: absolute;
        left: 3px;
        top: 1.4em;
      }
    }
    tbody tr:hover td{
      background-color:$secondary-color;
      color:$white;
      border-bottom: solid 1px $white;
      a{
        color:$white;
        border-bottom: dashed 1px $white;
      }
    }

  }
}
table.dataTable thead th,
table.dataTable tfoot th {
  font-weight: bold;
}
/*table.dataTable thead th,
table.dataTable thead td {
  padding: 10px 18px;
  border-bottom: 1px solid #111;
}*/
table.dataTable thead th:active,
table.dataTable thead td:active {
  outline: none;
}
table.dataTable tfoot th,
table.dataTable tfoot td {
  padding: 10px 18px 6px 18px;
  border-top: 1px solid #111;
}
table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc {
  cursor: pointer;
  *cursor: hand;
}
table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
  background-repeat: no-repeat;
  background-position: center right;
}
table.dataTable thead .sorting {
  background-image: url("img/DataTables/sort_both.png");
}
table.dataTable thead .sorting_asc {
  background-image: url("img/DataTables/sort_asc.png");
}
table.dataTable thead .sorting_desc {
  background-image: url("img/DataTables/sort_desc.png");
}
table.dataTable thead .sorting_asc_disabled {
  background-image: url("img/DataTables/sort_asc_disabled.png");
}
table.dataTable thead .sorting_desc_disabled {
  background-image: url("img/DataTables/sort_desc_disabled.png");
}

table.dataTable.nowrap th, table.dataTable.nowrap td {
  white-space: nowrap;
}
table.dataTable.compact thead th,
table.dataTable.compact thead td {
  padding: 4px 17px 4px 4px;
}
table.dataTable.compact tfoot th,
table.dataTable.compact tfoot td {
  padding: 4px;
}
table.dataTable.compact tbody th,
table.dataTable.compact tbody td {
  padding: 4px;
}
table.dataTable th.dt-left,
table.dataTable td.dt-left {
  text-align: left;
}
table.dataTable th.dt-center,
table.dataTable td.dt-center,
table.dataTable td.dataTables_empty {
  text-align: center;
}
table.dataTable th.dt-right,
table.dataTable td.dt-right {
  text-align: right;
}
table.dataTable th.dt-justify,
table.dataTable td.dt-justify {
  text-align: justify;
}
table.dataTable th.dt-nowrap,
table.dataTable td.dt-nowrap {
  white-space: nowrap;
}
table.dataTable thead th.dt-head-left,
table.dataTable thead td.dt-head-left,
table.dataTable tfoot th.dt-head-left,
table.dataTable tfoot td.dt-head-left {
  text-align: left;
}
table.dataTable thead th.dt-head-center,
table.dataTable thead td.dt-head-center,
table.dataTable tfoot th.dt-head-center,
table.dataTable tfoot td.dt-head-center {
  text-align: center;
}
table.dataTable thead th.dt-head-right,
table.dataTable thead td.dt-head-right,
table.dataTable tfoot th.dt-head-right,
table.dataTable tfoot td.dt-head-right {
  text-align: right;
}
table.dataTable thead th.dt-head-justify,
table.dataTable thead td.dt-head-justify,
table.dataTable tfoot th.dt-head-justify,
table.dataTable tfoot td.dt-head-justify {
  text-align: justify;
}
table.dataTable thead th.dt-head-nowrap,
table.dataTable thead td.dt-head-nowrap,
table.dataTable tfoot th.dt-head-nowrap,
table.dataTable tfoot td.dt-head-nowrap {
  white-space: nowrap;
}
table.dataTable tbody th.dt-body-left,
table.dataTable tbody td.dt-body-left {
  text-align: left;
}
table.dataTable tbody th.dt-body-center,
table.dataTable tbody td.dt-body-center {
  text-align: center;
}
table.dataTable tbody th.dt-body-right,
table.dataTable tbody td.dt-body-right {
  text-align: right;
}
table.dataTable tbody th.dt-body-justify,
table.dataTable tbody td.dt-body-justify {
  text-align: justify;
}
table.dataTable tbody th.dt-body-nowrap,
table.dataTable tbody td.dt-body-nowrap {
  white-space: nowrap;
}

table.dataTable,
table.dataTable th,
table.dataTable td {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

/*
 * Control feature layout
 */
.dataTables_wrapper {
  position: relative;
  clear: both;
  *zoom: 1;
  zoom: 1;
}
.dataTables_wrapper .dataTables_length {
  float: left;
}
.dataTables_wrapper .dataTables_filter {
  float: right;
  text-align: right;
}
/*.dataTables_wrapper .dataTables_filter input {
  margin-left: 0.5em;
}*/
.dataTables_wrapper .dataTables_info {
  clear: both;
  float: left;
  padding-top: 0.755em;
}
.dataTables_wrapper .dataTables_paginate {
  float: right;
  text-align: right;
  padding-top: 0.25em;
}
.dataTables_wrapper .dataTables_paginate .paginate_button {
  box-sizing: border-box;
  display: inline-block;
  min-width: 1.5em;
  padding: 0.5em 1em;
  margin-left: 2px;
  text-align: center;
  text-decoration: none !important;
  cursor: pointer;
  *cursor: hand;
  color: #333 !important;
  border: 1px solid transparent;
  border-radius: 2px;
}
.dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  color: #333 !important;
  border: 1px solid #979797;
  background-color: white;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, white), color-stop(100%, #dcdcdc));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, white 0%, #dcdcdc 100%);
  /* Chrome10+,Safari5.1+ */
  background: -moz-linear-gradient(top, white 0%, #dcdcdc 100%);
  /* FF3.6+ */
  background: -ms-linear-gradient(top, white 0%, #dcdcdc 100%);
  /* IE10+ */
  background: -o-linear-gradient(top, white 0%, #dcdcdc 100%);
  /* Opera 11.10+ */
  background: linear-gradient(to bottom, white 0%, #dcdcdc 100%);
  /* W3C */
}
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
  cursor: default;
  color: #666 !important;
  border: 1px solid transparent;
  background: transparent;
  box-shadow: none;
}
.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  color: white !important;
  border: 1px solid #111;
  background-color: #585858;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #585858), color-stop(100%, #111));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #585858 0%, #111 100%);
  /* Chrome10+,Safari5.1+ */
  background: -moz-linear-gradient(top, #585858 0%, #111 100%);
  /* FF3.6+ */
  background: -ms-linear-gradient(top, #585858 0%, #111 100%);
  /* IE10+ */
  background: -o-linear-gradient(top, #585858 0%, #111 100%);
  /* Opera 11.10+ */
  background: linear-gradient(to bottom, #585858 0%, #111 100%);
  /* W3C */
}
.dataTables_wrapper .dataTables_paginate .paginate_button:active {
  outline: none;
  background-color: #2b2b2b;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #2b2b2b), color-stop(100%, #0c0c0c));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
  /* Chrome10+,Safari5.1+ */
  background: -moz-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
  /* FF3.6+ */
  background: -ms-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
  /* IE10+ */
  background: -o-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
  /* Opera 11.10+ */
  background: linear-gradient(to bottom, #2b2b2b 0%, #0c0c0c 100%);
  /* W3C */
  box-shadow: inset 0 0 3px #111;
}
.dataTables_wrapper .dataTables_paginate .ellipsis {
  padding: 0 1em;
}
.dataTables_wrapper .dataTables_processing {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 40px;
  margin-left: -50%;
  margin-top: -25px;
  padding-top: 20px;
  text-align: center;
  font-size: 1.2em;
  background-color: white;
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(25%, rgba(255, 255, 255, 0.9)), color-stop(75%, rgba(255, 255, 255, 0.9)), color-stop(100%, rgba(255, 255, 255, 0)));
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
  background: -ms-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
  background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
}
.dataTables_wrapper .dataTables_length,
.dataTables_wrapper .dataTables_filter,
.dataTables_wrapper .dataTables_info,
.dataTables_wrapper .dataTables_processing,
.dataTables_wrapper .dataTables_paginate {
  color: #333;
}
.dataTables_wrapper .dataTables_scroll {
  clear: both;
}
.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody {
  *margin-top: -1px;
  -webkit-overflow-scrolling: touch;
}
.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody th, .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody td {
  vertical-align: middle;
}
.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody th > div.dataTables_sizing,
.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody td > div.dataTables_sizing {
  height: 0;
  overflow: hidden;
  margin: 0 !important;
  padding: 0 !important;
}
.dataTables_wrapper.no-footer .dataTables_scrollBody {
  border-bottom: 1px solid #111;
}
.dataTables_wrapper.no-footer div.dataTables_scrollHead table,
.dataTables_wrapper.no-footer div.dataTables_scrollBody table {
  border-bottom: none;
}
.dataTables_wrapper:after {
  visibility: hidden;
  display: block;
  content: "";
  clear: both;
  height: 0;
}

@media screen and (max-width: 767px) {
  .dataTables_wrapper .dataTables_info,
  .dataTables_wrapper .dataTables_paginate {
    float: none;
    text-align: center;
  }
  .dataTables_wrapper .dataTables_paginate {
    margin-top: 0.5em;
  }
}
@media screen and (max-width: 640px) {
  .dataTables_wrapper .dataTables_length,
  .dataTables_wrapper .dataTables_filter {
    float: none;
    text-align: center;
  }
  .dataTables_wrapper .dataTables_filter {
    margin-top: 0.5em;
  }
}


table.dataTable.dtr-inline.collapsed > tbody > tr > td.child,
table.dataTable.dtr-inline.collapsed > tbody > tr > th.child,
table.dataTable.dtr-inline.collapsed > tbody > tr > td.dataTables_empty {
  cursor: default !important;
}
table.dataTable.dtr-inline.collapsed > tbody > tr > td.child:before,
table.dataTable.dtr-inline.collapsed > tbody > tr > th.child:before,
table.dataTable.dtr-inline.collapsed > tbody > tr > td.dataTables_empty:before {
  display: none !important;
}
table.dataTable.dtr-inline.collapsed > tbody > tr > td:first-child,
table.dataTable.dtr-inline.collapsed > tbody > tr > th:first-child {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
}
table.dataTable.dtr-inline.collapsed > tbody > tr > td:first-child:before,
table.dataTable.dtr-inline.collapsed > tbody > tr > th:first-child:before {
  top: 9px;
  left: 4px;
  height: 14px;
  width: 14px;
  display: block;
  position: absolute;
  color: white;
  border: 2px solid white;
  border-radius: 14px;
  box-shadow: 0 0 3px #444;
  box-sizing: content-box;
  text-align: center;
  font-family: 'Courier New', Courier, monospace;
  line-height: 14px;
  content: '+';
  background-color: #31b131;
}
table.dataTable.dtr-inline.collapsed > tbody > tr.parent > td:first-child:before,
table.dataTable.dtr-inline.collapsed > tbody > tr.parent > th:first-child:before {
  content: '-';
  background-color: #d33333;
}
table.dataTable.dtr-inline.collapsed > tbody > tr.child td:before {
  display: none;
}
table.dataTable.dtr-inline.collapsed.compact > tbody > tr > td:first-child,
table.dataTable.dtr-inline.collapsed.compact > tbody > tr > th:first-child {
  padding-left: 27px;
}
table.dataTable.dtr-inline.collapsed.compact > tbody > tr > td:first-child:before,
table.dataTable.dtr-inline.collapsed.compact > tbody > tr > th:first-child:before {
  top: 5px;
  left: 4px;
  height: 14px;
  width: 14px;
  border-radius: 14px;
  line-height: 14px;
  text-indent: 3px;
}
table.dataTable.dtr-column > tbody > tr > td.control,
table.dataTable.dtr-column > tbody > tr > th.control {
  position: relative;
  cursor: pointer;
}
table.dataTable.dtr-column > tbody > tr > td.control:before,
table.dataTable.dtr-column > tbody > tr > th.control:before {
  top: 50%;
  left: 50%;
  height: 16px;
  width: 16px;
  margin-top: -10px;
  margin-left: -10px;
  display: block;
  position: absolute;
  color: white;
  border: 2px solid white;
  border-radius: 14px;
  box-shadow: 0 0 3px #444;
  box-sizing: content-box;
  text-align: center;
  font-family: 'Courier New', Courier, monospace;
  line-height: 14px;
  content: '+';
  background-color: #31b131;
}
table.dataTable.dtr-column > tbody > tr.parent td.control:before,
table.dataTable.dtr-column > tbody > tr.parent th.control:before {
  content: '-';
  background-color: #d33333;
}
table.dataTable > tbody > tr.child {
  padding: 0.5em 1em;
}
table.dataTable > tbody > tr.child:hover {
  background: transparent !important;
}
table.dataTable > tbody > tr.child ul {
  display: inline-block;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
table.dataTable > tbody > tr.child ul li {
  border-bottom: 1px solid #efefef;
  padding: 0.5em 0;
}
table.dataTable > tbody > tr.child ul li:first-child {
  padding-top: 0;
}
table.dataTable > tbody > tr.child ul li:last-child {
  border-bottom: none;
}
table.dataTable > tbody > tr.child span.dtr-title {
  display: inline-block;
  min-width: 75px;
  font-weight: bold;
}

div.dtr-modal {
  position: fixed;
  box-sizing: border-box;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 100;
  padding: 10em 1em;
}
div.dtr-modal div.dtr-modal-display {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 50%;
  height: 50%;
  overflow: auto;
  margin: auto;
  z-index: 102;
  overflow: auto;
  background-color: #f5f5f7;
  border: 1px solid black;
  border-radius: 0.5em;
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.6);
}
div.dtr-modal div.dtr-modal-content {
  position: relative;
  padding: 1em;
}
div.dtr-modal div.dtr-modal-close {
  position: absolute;
  top: 6px;
  right: 6px;
  width: 22px;
  height: 22px;
  border: 1px solid #eaeaea;
  background-color: #f9f9f9;
  text-align: center;
  border-radius: 3px;
  cursor: pointer;
  z-index: 12;
}
div.dtr-modal div.dtr-modal-close:hover {
  background-color: #eaeaea;
}
div.dtr-modal div.dtr-modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 101;
  background: rgba(0, 0, 0, 0.6);
}

@media screen and (max-width: 767px) {
  div.dtr-modal div.dtr-modal-display {
    width: 95%;
  }
}


