/* llistat */
.ensenyaments{
  .contingut {
    .intro{
      background-color:#444;
      color:#fff;
      padding:1em;
      p,ul{font-size:rem-calc(18);}
      margin-bottom:rem-calc(38);
      @media #{$tablet-up} {
        padding:2.7em 2em 1em 40%;
        background:#444 url('img/foto-ensenyaments.jpg') no-repeat 0 0;
      }
      @media #{$large-up} {
        padding:3em 6em 3em 45%;
      }
      a{
        color:#fff;
      }
    }
    &.medium-12 {
      .intro{
        @media #{$medium-up} {
          padding:3em 6em 3em 45%;
          background-size:cover;
        }
        @media #{$large-up} {
          padding:6em 6em 6em 35%;

        }
      }
    }
    #tabs,.pestanyes{
      margin-bottom:0;
    }
    .estudis{
      input[type=search] {
        box-sizing: border-box;
        }
        input[type=search]::-webkit-search-cancel-button {
            -webkit-appearance: searchfield-cancel-button;

        }
      .filtrar{
        background-color: #f3f3f3;
        box-shadow: 0 1px 0 0 rgba(101, 101, 101, 0.5);
        padding:25px 1em 1em;
        margin-bottom:2em;
        position:relative;
        min-height: 73px;
        margin-top:rem-calc(30);
        input{
          padding-left:40px;
          @include radius(13px);
          background-image: linear-gradient(to bottom, #ffffff, #e6e6e6);
          border: solid 1px #bcbcbc;
          margin-top:8px;
          @media #{$tablet-up} {
            max-width:70%;
            position:absolute;
            left:162px;
            top:18px;
            margin-left:3px;
            margin-top:0;
          }
        }
        label{
          &:before{
            content: '';
            display: block;
            @extend .icona-filtre-cercar;
            position: absolute;
            left: 23px;
            top: 61px;
            z-index:3;
            @media #{$tablet-up} {
              left: 175px;
              top: 25px;
            }
          }
        }
      }
      ul{
        li{
          margin:1.5em 0;
          a{
            color:#50798d;
            border-bottom: dashed 1px #6e9bb1;
            text-decoration: none;
            padding-bottom:3px;
            &:hover{
              border-bottom: solid 1px $primary-color;
              color: $primary-color;
            }
            &:active{
              color:$white;
            }
            &:focus{
              color:$white;
            }
          }
        }
        &.inline-list{
          border-bottom:solid 1px $primary-color;
          margin:1em 0;
          padding-bottom:1em;
          li{
            padding-left:0.75em;
            margin:0.5em 0;
            margin-right: 0.75em;
            &:before{
              content:none;
            }
            a{
              color:$primary-color;
              border-bottom: 1px solid transparent;
              &:focus{
                color:$primary-color;
                border:none;
                outline: #ffbf47 solid 3px;
                background-color: $white;
              }
              &:hover{
                border-color: $primary-color;
                color:$primary-color;
              }
              &:active{
                color:$primary-color;
              }
            }
          }
        }
      }

    }
  }
}


