/* llistat */
div.gm-style div.gmglobus {
    background: url(/media/gif/fons_globus_gm.jpg) no-repeat left top;
    padding: 60px 0 0 0;
    width: 291px;
}
#mapa{
  width: 100%;
  height: 474px;
  font-size: .75em;
  line-height:120%;
  padding:0;
  overflow:hidden;
  margin-bottom:rem-calc(37);
  img{max-width:none;}
}
.localitzacio{
  .contingut {
    #tabs,.pestanyes{
      margin-bottom:0;
    }
    h2{
      font-size:rem-calc(26);
      border:none;
      padding-bottom:0;
    }
    .localitzacions{

      input[type=search] {
        box-sizing: border-box;
        }
        input[type=search]::-webkit-search-cancel-button {
            -webkit-appearance: searchfield-cancel-button;

        }
      .filtrar{
        background-color: #f3f3f3;
        box-shadow: 0 1px 0 0 rgba(101, 101, 101, 0.5);
        padding:25px 1em 1em;
        margin-bottom:2em;
        position:relative;
        min-height: 73px;
        margin-top:rem-calc(30);
        input{
          padding-left:40px;
          @include radius(13px);
          background-image: linear-gradient(to bottom, #ffffff, #e6e6e6);
          border: solid 1px #bcbcbc;
          margin-top:8px;
          @media #{$tablet-up} {
            max-width:70%;
            position:absolute;
            left:162px;
            top:18px;
            margin-left:3px;
            margin-top:0;
          }
        }
        label{
          &:before{
            content: '';
            display: block;
            @extend .icona-filtre-cercar;
            position: absolute;
            left: 23px;
            top: 61px;
            z-index:3;
            @media #{$tablet-up} {
              left: 175px;
              top: 25px;
            }
          }
        }
      }
      ul{
        li{
          margin:0;
          padding-right:25px;
          a{
            color:#50798d;
            border-bottom: dashed 1px #6e9bb1;
            text-decoration: none;
            padding-bottom:3px;
            &:hover{
              border-bottom: solid 1px $primary-color;
              color: $primary-color;
            }
            &:active{
              color:$white;
            }
            &:focus{
              color:$white;
            }
          }
          &.actiu{
            a{
              font-weight: bold;
              color:$body-font-color;
              border:none;
            }

          }
        }
      }

    }

  }
}



