.main-titol{
 background-color:$secondary-color;
 margin-bottom:rem-calc(40);
 position:relative;

  h1{
    @include grid-row();
    padding: rem-calc(45 15 25);
    font-family: 'Roboto Slab', serif;
    font-size: rem-calc(38);
    margin-bottom:0;
    font-weight: 700;
    color:#fff;

  }
  &.b{

    background-position:0 0;
    background-repeat: no-repeat;
    background-size: cover;
    div{
      //height: 100%;
      //width:100%;
      background:rgba(0,0,0,.2);
      //padding-top:rem-calc(75);
      //position:absolute;
      //top:0;
    }

    h1{
      font-size: rem-calc(48);
      padding-bottom: rem-calc(35);
      padding-top: rem-calc(115);
    }
  }

}
figure {
  margin: 0 0 2rem;
  //border-bottom: 1px solid $iron;
  padding-bottom: 1rem;

  img {
    margin-bottom: 1rem;
  }
}

figcaption {
  font-size: rem-calc(13);
  color: #4a4a4a;
  border-bottom: 1px solid $iron;
   padding-bottom: 1rem;
  @media #{$small-up} {
    font-size: rem-calc(14);
  }
}
.contingut{
  h2{
    font-family: 'Roboto Slab', serif;
    border-bottom: solid 1px $iron;
    padding-bottom: rem-calc(20);
    margin-bottom: rem-calc(20);
    &.b{
      border:none;
      padding-bottom:0;
       margin-bottom: 0;
       font-size: rem-calc(24);
    }
  }
  h3,h4,h5,h6{
    font-family: 'Roboto Slab', serif;
    margin-top:2rem;
    margin-bottom:.75rem;
  }
  ul+h2,p+h2{
    margin-top:2rem;
  }

}
aside{
  h3{
    color:$white;
    background-color:$primary-color;
    padding:rem-calc(10 15);
    font-size: rem-calc(17);
    font-weight: 700;
  }
  p+h3, p+h2{
    margin-top:rem-calc(40);
  }

  ul{
    margin-left:0;
    margin-bottom:rem-calc(40);
    li{
      list-style: none;
      a{
        color:$body-font-color;
        display: block;
        padding:rem-calc(10 15);
        margin-bottom:0;
        line-height: 1.3;
        border-bottom: solid 1px $iron;
        text-decoration: none;
        @include single-transition();
        &:hover{
          background-color:#f0f0f0;
        }
        &:focus,&:active{
          border-top:none;
          border-bottom: solid 1px $iron;
        }
      }
    }
  }
}
.boto-accio{
  h3{
    padding:0;
    background-color: transparent;
    margin:0!important;
  }
  a{
    background-color: $secondary-color;
    padding:rem-calc(28 33 90);
    display: block;
    position:relative;
    line-height: 1.3;
    font-size: rem-calc(29);
    color:$white;
    font-weight: normal;
    @include single-transition();
    margin-bottom:rem-calc(40);
    text-decoration: none;
    &:after{
      content: '';
      background: url('img/kland-enllac-destaca.svg') no-repeat 0 0;
      position:absolute;
      bottom:rem-calc(28);
      left:rem-calc(38);
      width:24px;
      height: 45px;
      opacity: 0.5;
      @include single-transition();
    }
    &.external:after{
      content: '';
      background: url('img/kland-enllac-destaca.svg') no-repeat 0 0;
      position:absolute;
      bottom:rem-calc(28);
      left:rem-calc(38);
      width:24px;
      height: 45px;
      opacity: 0.5;
      margin:0;
      @include single-transition();
    }
    &:hover{
      background-color: darken($secondary-color, 7%);
      color:$white;
      &:after{
        left:82%;
        opacity: 1;
      }
    }
    &:visited{
       color:$white!important;

    }
    &:focus{
      border:none;
      outline: #ffbf47 solid 3px;
      background-color: darken($secondary-color, 7%);
    }
  }
  &.corp{
    a{
      background-color: $primary-color;
      &:hover,&:focus{
        background-color: darken($primary-color, 7%);
      }
    }
  }
}
.boto-accio-corp{
  @extend  .boto-accio;
  a{
      background-color: $primary-color;
      &:hover,&:focus{
        background-color: darken($primary-color, 7%);
      }
    }
}
/* doctor honoris, any dones*/
.lateral-contingut.fitxa {
  figure, img{
    margin: 0;
    padding:0;
    width:100%;
    max-width: 300px;
  }
  dl{
    background-color:#444;
    padding:1em;
    color:#fff;
    dt{
      margin:0;
    }
    dd{
      margin: 0 0 .5em;
      padding: 0 0 .5em;
      border-bottom: 1px solid #fff;
    }
  }
}