.news {
  .section-header {
    margin-bottom: 0;
  }
}

.meta {
  padding: 1.25rem 0;

  p {
    border-bottom: 1px solid $iron;
    padding-bottom: 1rem;
    margin-bottom: 0;
  }

  @media #{$small-up} {
    font-size: rem-calc(15);
  }

  @media #{$medium-up} {
    font-size: rem-calc(16);
  }

  &.back {
    a {
      &:before {
        content: '\2190  ';
      }
    }
  }
}

.read-more {
  @include button($padding: rem-calc(5), $radius: 5px);
  font-size: rem-calc(12);

  @media #{$medium-up} {
    font-size: rem-calc(15);
  }

  &:hover {
    border: 0;
  }

  span {
    display: none;
  }
}


// llistat notícies
.list {

  article {
    margin-bottom: 2rem;

    img {
      margin-bottom: 1.5rem;
    }

    h2 {
      font-size: rem-calc(24);
    }

    @media #{$small-up} {
      h2 {
        font-size: rem-calc(28);
      }

      img {
        width: 175px;
        float: right;
        margin-left: 1.5rem;
      }
    }

    @media #{$medium-up} {
      img {
        width: auto;
      }
    }

    @media #{$large-up} {
      h2 {
        font-size: rem-calc(29);
      }
    }

    @media #{$xlarge-up} {
      h2 {
        font-size: rem-calc(34);
      }
    }
  }
}


// fitxa noticia
.view {
  article h1 {
    font-size: rem-calc(30);
    color: $primary-color;
    margin-bottom: 1rem;

    @media #{$small-up} {
      font-size: rem-calc(33);
    }

    @media #{$medium-up} {
      font-size: rem-calc(30);
    }

    @media #{$large-up} {
      font-size: rem-calc(32);
    }

    @media #{$xlarge-up} {
      font-size: rem-calc(34);
    }
  }

  .lead {
    font-size: rem-calc(16);
    color: $black;

    @media #{$medium-up} {
      font-size: rem-calc(17);
      border-bottom: 1px solid $border;
      padding-bottom: rem-calc(15);
    }

    @media #{$xlarge-up} {
      font-size: rem-calc(19);
    }
  }

  article .share {
    border: 1px solid $iron;
    border-width: 1px 0;
    margin: 1.5rem 0 2rem;
    padding: rem-calc(15 0);

    a {
      margin: 0 0.25rem;
      color: $primary-color;
    }
  }

  .fotos,
  .text {
    @include grid-column($columns: 12);
  }

  @media #{$small-up} {
    article .share {
      a {
        margin: 0 1rem;
      }
    }
  }

  @media #{$medium-up} {
    article .share {
      padding-top: 0;
      border-top: 0;
      margin-top: 0.5rem;
      text-align: left;

      a {
        margin: 0 0.4rem 0 0;
      }
    }

    .fotos {
      @include grid-column($columns: 5, $push: 7);
    }

    .text {
      @include grid-column($columns: 7, $pull: 5);
    }

  }

  @media #{$large-up} {
    article .share {
      a {
        margin: 0 2rem 0 0;
      }
    }
  }
}


