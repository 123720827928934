// Retocs als estils ja existents, per adaptar-ho com a mòduls.

.contingut .module {
    margin-bottom: 1.5rem;
    &--slider {
        .slider-container {
            > div.row {
                @include grid-row;
            }
        }
        h2 {
            margin-bottom: .8125rem;
            font-family: 'Roboto Slab',serif;
            font-size: 1.875rem;
            font-weight: 300;
            color: #000;
            padding-bottom: 0;
            border-bottom: 0;
        }
        h3 {
            font-size: 1.125rem;
            font-weight: 700;
            font-family: Lato,sans-serif;
            margin-top: 0.2rem;
            margin-top: 0.5rem;
        }
        a.totes {
            color:#000;
            font-size:rem-calc(15);
            margin:0;
            padding:0 35px 0 0;
            position:relative;
            display: inline-block;
            margin-top:18px;
            text-decoration: none;
            &::after{
               @extend .icon-mes;
               content: '';
               width:22px;
               height: 22px;
               position:absolute;
               top:50%;
               right: 0;
               margin-top:-$icon-mes-height/2;
            }
        }
        .slide {
            a {
              color:#3a3a3a;
              display: block;
              min-height: 414px;
              margin: 2px .75em;
              text-decoration: none;
              @media only screen and (min-width: 1200px){
                margin: 2px .5em;
              }
              @media only screen and (min-width: 1400px){
                margin: 2px 1em;
              }
              &::after {
                  display: none;
              }
              &:hover {
                h3 {color:$primary-color};
              }
              &:focus {
                    outline: 3px solid #ffbf47;
                    background-color: transparent;
                    border: 0;
              }
              &:active {
                    background-color: transparent;
                    border: 0;
                }
            }
            p {
                font-size: rem-calc(14);
                &.data {
                    margin-bottom: rem-calc(10);
                    color: $primary-color;
                    span.post_type{
                        display: inline;
                        text-transform: uppercase;
                        font-weight: bold;
                        padding-left: 28px;
                        margin-left: 5px;
                        position: relative;
                        &:after{
                        content: '';
                        display: block;
                        position: absolute;
                        top:0;
                        left:0;
                        }
                        &.entrevista{
                        //background-image: url('http://diaridigital.urv.cat/wp-content/themes/urvactiva/img/ico_entrevistes.png');
                        &:after{
                            @extend .ico_entrevistes;
                        }
                        }
                        &.opinio{
                        padding-left: 23px;
                        //background-image: url('http://diaridigital.urv.cat/wp-content/themes/urvactiva/img/ico_opinions.png');
                        &:after{
                            @extend .ico_opinions;
                        }
                        }
                    }
                }
            }
          }
    }
    &--destacats {
        ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            li {
                padding: 0 1.5rem;
                flex: 0 1 auto;
                @media screen and (min-width: 35em) {
                    flex-basis: 50%;
                }
                @media #{$large-up} {
                    flex-basis: 33.33%;
                }
                > * {
                    margin: 0;
                    + * {
                        margin-top: 1rem;
                    }
                }
                &::before {
                    display: none;
                }
            }
        }
        .slide {
            padding: 0 1.5rem;
            > * {
                margin: 0;
                + * {
                    margin-top: 1rem;
                }
            }
            img {
                width: auto !important;
                margin-left: auto;
                margin-right: auto;
            }
        }
        img:first-of-type {
            border-radius: 50%;
        }
        p img {
            border-radius: 0!important;
        }
        h3 {
            line-height: 1.1;
        }
        .button {
            padding: .5em 1em;
            background-color: #fff;
            color: #707070;
            border: solid 1px #707070;
            border-radius: 6px;
            text-transform: uppercase;
            font-size: .875rem;
            &:hover {
                background-color: #707070;
                color: #fff;
            }
        }
    }
    &--caixes-img {
        ul.caixes {
            &.columns4{
                h2{
                    font-size: rem-calc(16);
                    line-height: 1.21;
                }
                @media #{$medium-up} {
                    @include block-grid(4);
                    h2{
                        font-size: rem-calc(19);
                    }
                }
            }
            &.columns5 {
                h2{
                    font-size: rem-calc(16);
                    line-height: 1.21;
                }
                @media #{$medium-up} {
                    @include block-grid(5);
                    h2{
                        font-size: rem-calc(19);
                    }
                }
            }
        }
        li {
            &::before {
                display: none;
            }
        }
        h2 {
            border-bottom: 0;
        }
        a {
            &:active,
            &:focus {
                background-color: transparent;
                border: 0;
            }
        }
    }
    &--caixes-text {
        .seccio-destacada {
            background-color: #ececec;
            padding: 2rem 0 1rem 0;
            > div {
                @include grid-row;
                ul {
                    margin-top: 0;
                    margin-left: -.5rem;
                    margin-right: -.5rem;
                    display: flex;
                    flex-wrap: wrap;
                    li {
                        padding: .5rem;
                        flex: 0 1 100%;
                        @media screen and (min-width: 40em) {
                            flex-basis: 50%;
                        }
                        @media #{$large-up} {
                            flex-basis: 33.33%;
                        }
                    }
                }
            }
            a,
            div.caixa {
                height: 100%;
                &:active,
                &:focus {
                    background-color: #fff;
                    border: 0;
                }
            }
            .subtitol p {
                font-weight: 100;
            }
        }
        li {
            margin-top: 0;
            margin-bottom: 0;
            &::before {
                display: none;
            }
        }
    }
    &--caixes-text-bg {
        .seccio-destacada {
            background-color: transparent;
            padding: 0;
        }
        li a,
        li div.caixa {
            background-color: #ececec;
        }
    }
    &--caixes-text-img {
        .seccio-destacada {
            background-color: transparent;
            padding: 0;
        }
        li a,
        li div.caixa {
            background: #fff url('https://picsum.photos/400/300') no-repeat;
            background-size: cover;
            &::before {
                content: '';
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0,0,0,.5);
                transition: background-color .3s ease-out;
            }
            div {
                color: #fff;
                z-index: 1;
                text-shadow: 0 2px 7px rgba(0,0,0,.5);
                &.titol,
                &.subtitol {
                    position: relative;
                }
            }
        }
        li a {
            &:hover::before {
                background-color: rgba(0,0,0,.3);
            }
        }
    }
    &--btns {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .btn-module {
            display: inline-block;
            border: 2px solid $secondary-color;
            border-radius: 2rem;
            padding: .5rem 1.5rem;
            font-weight: bold;
            color: $secondary-color !important;
            text-decoration: none;
            margin: .5rem;
            &:hover,
            &:focus {
                color: #fff !important;
                background-color: $secondary-color;
                border-color: inherit;
            }
        }
    }
    &--btns-bg {
        background-color: $secondary-color;
        padding: 2rem 0;
        .btn-module {
            display: inline-block;
            border-color: #fff;
            color: #fff !important;
            &:hover,
            &:focus {
                color: $secondary-color !important;
                background-color: #fff;
            }
        }
    }
    &--btns-img {
        background: #fff url('https://picsum.photos/1900/200') no-repeat;
        background-size: cover;
        padding: 2rem 0;
        position: relative;
        &::before {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0,0,0,.5);
            transition: background-color .3s ease-out;
        }
        .btn-module {
            display: inline-block;
            border-color: #fff;
            color: #fff !important;
            position: relative;
            z-index: 1;
            &:hover,
            &:focus {
                color: #000 !important;
                background-color: #fff;
                border-color: #fff;
            }
        }
    }
}

// Contingut dels mòduls en una sola columna
div:not(.tablet-8.medium-9) > {
    .module--full-bleed,
    .module--btns,
    .module--slider .slider-container,
    .module--destacats .slider-container {
        width: 100vw;
        margin-left: calc(50% - 50vw);
    }
    .module--full-bleed {
        width: 100vw;
        margin-left: calc(50% - 49vw);
    }
    .module--caixes-text .seccio-destacada {
        width: 100vw;
        margin-left: calc(50% - 50vw);
        > div {
            > div {
                @media #{$medium-up} {
                    @include grid-column(10);
                    float: none;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
    }
}



.contingut .module--slider .slider-container>div.row {
    max-width: 75.5rem;
}
.contingut .module--slider .carousel__container .wrapper-slick {
    max-width: 75.5rem;
}
